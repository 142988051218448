<ion-item [lines]="lines" [color]="color" #item>
  <ion-label *ngIf="legend" position="stacked" class="input-label">
    <div class="legend {{ isRefData ? 'text-style6' : 'text-style2' }} text-overflow-ellipsis {{ labelClass }}">
      {{ legend }}
      <ng-content select="[role=after-legend]"></ng-content>
    </div>
    <ng-content select="[role=after-legend]"></ng-content>
  </ion-label>
  <ng-content select="ion-input" ngProjectAs="ion-input"></ng-content>
  <ng-content select="ion-select" ngProjectAs="ion-select"></ng-content>
  <ng-content select="ion-datetime" ngProjectAs="ion-datetime"></ng-content>
  <ng-content select="ion-textarea" ngProjectAs="ion-textarea"></ng-content>
  <ng-content select="[role=end]" slot="end"></ng-content>
  <div class="item-content"><ng-content></ng-content></div>
</ion-item>

import { Component, Input, OnInit } from "@angular/core";
import { Navigation, Router } from "@angular/router";
import { ModalController, NavController } from "@ionic/angular";

/**
 * This component is a solution to the loss of parameters after
 * refreshing a page. It provides automatic redirection on refresh
 * and a navigateBack() function when clicking on the backbutton
 * when there is no previous navigation.
 *
 * Beware, some pages require that the redirection is made quickly,
 * in the constructor. But we can't read the inputs in the constructor,
 * so the component will be useless in that case.
 */
@Component({
  selector: "app-back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  @Input() defaultRedirection: string = "perimeter";
  @Input() redirectOnRefresh: boolean = false;
  @Input() hasPreviousPage: boolean = null;
  @Input() modalMode: boolean = false;

  constructor(private router: Router, private navCtrl: NavController, private modalCtrl: ModalController) {}

  ngOnInit() {
    this.hasPreviousPage = !!this.router.getCurrentNavigation()?.previousNavigation;
    if (!this.hasPreviousPage && this.redirectOnRefresh) {
      this.navCtrl.navigateBack(this.defaultRedirection, { animated: false });
    }
  }

  public backButtonClicked() {
    if (this.modalMode) {
      this.modalCtrl.dismiss();
    } else {
      if (this.hasPreviousPage) {
        this.navCtrl.pop();
      } else {
        this.navCtrl.navigateBack(this.defaultRedirection, { animated: false });
      }
    }
  }
}

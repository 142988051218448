import { Component, Input, OnInit } from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("text") text: string;
}

import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[textareaElastic]",
})
export class TextareaElasticDirective {
  DEFAULT_ROWS = 9;

  constructor(private el: ElementRef) {
    const nativeEl = el.nativeElement as HTMLTextAreaElement;
    nativeEl.style.overflowY = "scroll";
    nativeEl.style.width = "100%";
    nativeEl.style.border = "none";
    nativeEl.rows = this.DEFAULT_ROWS;
  }

  @HostListener("input", ["$event.target"])
  onInput(textarea: HTMLTextAreaElement) {
    textarea.style.height = "0";
    textarea.style.overflowY = "hidden";
    textarea.style.height = textarea.scrollHeight + "px";
  }
}

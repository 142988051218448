import { NgModule } from "@angular/core";

import { DefaultValuePipe } from "./default-value.pipe";

@NgModule({
  imports: [],
  declarations: [DefaultValuePipe],
  exports: [DefaultValuePipe],
})
export class DefaultValueModule {}

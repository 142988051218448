import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as R from "ramda";
import { Observable, from, of } from "rxjs";
import { map, concatMap, catchError, switchMap } from "rxjs/operators";

import { Environment } from "../app.environment";
import { OfflineService } from "./offline.service";
import { PicturesService } from "./pictures.service";
import { Picture } from "../structs/base";

@Injectable()
export class PicturesLoaderService {
  constructor(private http: HttpClient, private offlineApi: OfflineService, private picturesService: PicturesService) {}

  public getFullPictureURL(picture: Picture): Observable<string> {
    return this.getPictureURL(picture, picture.picture);
  }

  public getThumbnailPictureURL(picture: Picture, preloaded = false): Observable<string> {
    return this.getPictureURL(picture, picture.thumbnail, preloaded);
  }

  public preloadThumbnails(pictures: Picture[]): void {
    from(pictures)
      .pipe(concatMap(picture => this.getThumbnailPictureURL(picture)))
      // .pipe(concatMap((url) => this.preloadPictureURL(url)))
      .subscribe();
  }

  private static getURLPath(url: string): string {
    return new URL(url).pathname;
  }

  private getPictureURL(picture: Picture, rawURL: string, preloaded = false): Observable<string> {
    if (rawURL) {
      const fullURL = `${rawURL.startsWith("http") ? "" : Environment.getBackendHost()}${rawURL}`;
      if (preloaded) {
        const path = PicturesLoaderService.getURLPath(fullURL);
        return this.offlineApi.getItem(path, true).pipe(catchError(() => of(fullURL)));
      } else {
        return of(fullURL);
      }
      // Image is not yet synchronized
    } else if (picture.localPath) {
      return of(picture.localPath);
    } else if (picture.browserFile) {
      // The asset picture may have been serialized since we got the browser File.
      // If it did, it is an empty object.
      // In thas case, fetch it from the cache of the pictures service.
      const browserFile = !R.isEmpty(picture.browserFile)
        ? picture.browserFile
        : this.picturesService.getBrowserFile(picture.localId);
      if (browserFile) {
        return new Observable(observer => {
          const reader = new FileReader();
          reader.onloadend = function () {
            observer.next(<string>this.result);
            observer.complete();
          };
          reader.readAsDataURL(browserFile);
        });
      }
    }
    return of(null);
  }

  /**
   * Preloads a picture using the HTTP client and store it in local DB as base64 string.
   *
   * @param url The picture URL to preload.
   */
  private preloadPictureURL(url: string): Observable<void> {
    // Get the image
    return (
      this.http
        .get(url, { responseType: "blob" })
        // Read it as base64
        .pipe(
          switchMap(imageBlob => {
            return new Observable<string>(observer => {
              const fileReader = new FileReader();
              fileReader.onload = event => {
                // @ts-ignore
                observer.next(event.target.result);
                observer.complete();
              };
              fileReader.readAsDataURL(imageBlob);
            });
          })
        )
        // Store it in local DB
        .pipe(
          switchMap(base64Image => {
            // Keep only URL path as key to avoid duplicates on subsequent refreshes
            const path = PicturesLoaderService.getURLPath(url);
            return this.offlineApi.storeItem(path, base64Image);
          })
        )
    );
  }
}

import { CurrencyPipe, registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeHu from "@angular/common/locales/hu";
import localePl from "@angular/common/locales/pl";
import localeRo from "@angular/common/locales/ro";
import localeUk from "@angular/common/locales/uk";
import localeRu from "@angular/common/locales/ru";
import localePt from "@angular/common/locales/pt";
import localeEs from "@angular/common/locales/es";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Platform, IonSelect } from "@ionic/angular";
import numbro from "numbro";
import es from "numbro/languages/es-ES";
import fr from "numbro/languages/fr-FR";
import hu from "numbro/languages/hu-HU";
import pl from "numbro/languages/pl-PL";
import pt from "numbro/languages/pt-PT";
import ro from "numbro/languages/ro-RO";
import ru from "numbro/languages/ru-RU";
import uk from "numbro/languages/uk-UA";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, forkJoin, Observable } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";

@Injectable()
export class I18nService {
  public AVAILABLE_LANGUAGES: string[] = ["en", "fr", "hu", "uk", "pl", "ro", "ru", "pt", "es"];

  private _language: string;
  private readonly language: BehaviorSubject<string> = new BehaviorSubject(null);
  public language$ = this.language.asObservable().pipe(filter(language => !!language));

  private currencyPipe: CurrencyPipe;
  private currency: string = "€";

  constructor(private platform: Platform, private translate: TranslateService) {
    registerLocaleData(localeFr);
    registerLocaleData(localeHu);
    registerLocaleData(localePl);
    registerLocaleData(localeRo);
    registerLocaleData(localeUk);
    registerLocaleData(localeRu);
    registerLocaleData(localePt);
    registerLocaleData(localeEs);

    // Register Numbro languages
    numbro.registerLanguage(es);
    numbro.registerLanguage(fr);
    numbro.registerLanguage(hu);
    numbro.registerLanguage(pl);
    numbro.registerLanguage(pt);
    numbro.registerLanguage(ro);
    numbro.registerLanguage(ru);
    numbro.registerLanguage(uk);

    this.translate.setDefaultLang("en");

    this.setLanguage();
    this.language$
      .pipe(
        tap(language => {
          moment.locale(language);
          this.currencyPipe = new CurrencyPipe(language);
        }),
        switchMap(language => this.translate.use(language))
      )
      .subscribe(() => {
        this.translateIonSelectButtons();
      });
  }

  public getLanguage(): string {
    return this._language;
  }

  public setCurrency(currency: string): void {
    this.currency = currency;
  }

  public getCurrency(): string {
    return this.currency;
  }

  public setMaxValue(value: number, max_digits: number): number {
    const inputNumber = Math.abs(Math.trunc(value));
    const numberToString = inputNumber.toString();
    let maxValue = parseInt(numberToString.substring(0, max_digits));

    return maxValue;
  }

  public formatCurrency(value: number, keep = false): string {
    let digits = "1.0-0";
    let prefix = "";
    if (!keep) {
      if (value < 10) {
        value = Math.round(value * 100) / 100;
      } else {
        value = Math.round(value);
      }

      if (Math.abs(value) >= 1000 * 1000 * 1000) {
        //prefix = 'B';
        prefix = this.translate.instant("Billions 10^9");
        value = value / (1000 * 1000 * 1000);
        // for B€, keep 3 digits
        digits = "1.0-3";
        value = Math.round((1000 * 1000 * 1000 * value) / (1000 * 1000 * 1000));
      } else if (Math.abs(value) >= 1000 * 1000) {
        prefix = "M";
        value = value / (1000 * 1000);
        // for M€, keep 3 digits
        digits = "1.0-1";
        value = Math.round(1000 * value) / 1000;
      } else if (Math.abs(value) >= 1000) {
        prefix = "k";
        value = value / 1000;
        value = Math.round(100 * value) / 100;
      }
    } else {
      prefix = "";
    }

    return this.currencyPipe.transform(value, `${prefix}${this.getCurrency()}`, "symbol", digits);
  }

  /**
   * Hack to globally translate the Cancel/OK buttons of ion-select.
   */
  private translateIonSelectButtons(): void {
    combineLatest([this.translate.get("Ok"), this.translate.get("Cancel")]).subscribe(([okText, cancelText]) => {
      const originalOpen = IonSelect.prototype.open;
      IonSelect.prototype.open = function (ev) {
        this.okText = okText;
        this.cancelText = cancelText;
        return originalOpen.bind(this)(ev);
      };
    });
  }

  private setLanguage(): void {
    // Set language to browser language if available or fallback to default
    const browserLanguage = this.translate.getBrowserLang();
    this._language = this.translate.getBrowserLang();
    if (!this.AVAILABLE_LANGUAGES.includes(browserLanguage)) {
      this._language = this.translate.getDefaultLang();
    }
    this.language.next(this._language);
  }
}

import { map, finalize, tap, toArray, filter, concatMap, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, forkJoin } from "rxjs";

import { Asset, ImportableAssetStatus } from "../structs/assets";
import { ImportableMixin } from "../structs/base";
import { Investment } from "../structs/investments";
import { AuditService } from "./audit.service";
import { ImportStatusService } from "./import-status.service";
import { InvestmentsService } from "./investments.service";
import { OfflineService } from "./offline.service";
import { SearchService } from "./search.service";
import { AlertController, LoadingController, ToastController } from "@ionic/angular";

@Injectable()
export class ImportStatusBatchService {
  constructor(
    private alertCtrl: AlertController,
    private auditApi: AuditService,
    private importStatusService: ImportStatusService,
    private investmentsApi: InvestmentsService,
    private loadingCtrl: LoadingController,
    private offlineApi: OfflineService,
    private searchService: SearchService,
    private toastCtrl: ToastController,
    private translate: TranslateService
  ) {}

  public validateImportBatchAssets(): Observable<Asset[]> {
    return this.validateImportBatch<Asset>(this.getReadyToValidateAssets(), (asset: Asset) =>
      this.auditApi.patchAsset(asset, {
        importStatuses: asset.importStatuses,
      })
    );
  }

  public validateImportBatchInvestments(): Observable<Investment[]> {
    return this.validateImportBatch<Investment>(this.getReadyToValidateInvestments(), (investment: Investment) => {
      if (investment.assetId) {
        return this.offlineApi.getAsset(investment.assetId).pipe(
          concatMap(asset =>
            this.investmentsApi.patchInvestment(asset, investment, {
              import_statuses: investment.importStatuses,
            })
          )
        );
      } else {
        return this.investmentsApi.patchInvestment(null, investment, {
          import_statuses: investment.importStatuses,
        });
      }
    });
  }

  private getReadyToValidateAssets(): Observable<Asset[]> {
    return this.searchService.generateAssetsFilters().pipe(
      concatMap(() => this.searchService.searchAssets()),
      concatMap(elements => elements),
      concatMap(element => this.offlineApi.loadAsset(element.id)),
      filter(asset => this.importStatusService.isReady(asset.importStatuses)),
      toArray()
    );
  }

  private getReadyToValidateInvestments(): Observable<Investment[]> {
    return this.searchService.generateInvestmentsFilters().pipe(
      concatMap(() => this.searchService.searchInvestments()),
      concatMap(investments => investments),
      filter(investment => this.importStatusService.isReady(investment.importStatuses)),
      toArray()
    );
  }

  private validateImportBatch<T extends ImportableMixin<any>>(
    readyObjectsObservable: Observable<T[]>,
    patchFunction: (object: T) => Observable<T>
  ): Observable<T[]> {
    const createLoading = () => this.loadingCtrl.create({ spinner: "crescent" });
    const getObjectsLoading = createLoading();
    const validateObjectsLoading = createLoading();

    // @ts-ignore
    getObjectsLoading.present();

    // @ts-ignore
    return readyObjectsObservable.pipe(
      // Show a toast if nothing to validate
      tap(async objects => {
        // @ts-ignore
        getObjectsLoading.dismiss();
        if (objects.length === 0) {
          this.translate
            .get("No element to validate")
            .pipe(
              map(message => {
                const toast = this.toastCtrl.create({
                  message,
                  duration: 3000,
                  position: "bottom",
                });
                // @ts-ignore
                toast.present();
              })
            )
            .subscribe();
        }
      }),
      filter(objects => objects.length > 0),
      // Show a confirmation dialog
      concatMap(objects => {
        return forkJoin(
          this.translate.get("You will confirm the import of {{nbObjects}} elements. Continue?", {
            nbObjects: objects.length,
          }),
          this.translate.get("Ok"),
          this.translate.get("Cancel")
        ).pipe(
          mergeMap(([title, yesLabel, noLabel]) => {
            return new Observable<T[]>(observer => {
              const alert = this.alertCtrl.create({
                header: title,
                buttons: [
                  {
                    text: noLabel,
                    role: "cancel",
                    handler: () => {
                      observer.complete();
                    },
                  },
                  {
                    text: yesLabel,
                    handler: () => {
                      observer.next(objects);
                      observer.complete();
                    },
                  },
                ],
              });
              // @ts-ignore
              alert.present();
            });
          })
        );
      }),
      // @ts-ignore/
      tap(() => validateObjectsLoading.present()),
      concatMap(objects => objects),
      concatMap(object => {
        object.importStatuses = [ImportableAssetStatus.VALIDATED];
        return patchFunction(object);
      }),
      toArray(),
      // @ts-ignore
      finalize(() => validateObjectsLoading.dismiss())
    );
  }
}

import { Pipe, PipeTransform } from "@angular/core";

import { I18nService } from "../../services/i18n.service";

@Pipe({
  name: "currency",
})
export class CurrencyPipe implements PipeTransform {
  constructor(private i18n: I18nService) {}

  transform(value: number): string {
    if (value !== null) {
      return this.i18n.formatCurrency(value);
    }
    return null;
  }
}

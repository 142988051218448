import { Injectable } from "@angular/core";

import * as moment from "moment";
import { BehaviorSubject, Observable, from, throwError } from "rxjs";

import { TranslateService } from "@ngx-translate/core";
import { Asset, Perimeter, setAsset } from "@structs/assets";
import {
  AuditBuilding,
  AuditResultItem,
  AuditState,
  AuditSynthesis,
  AuditSynthesisChange,
  makeAuditSynthesis,
} from "@structs/audit";
import { Project } from "@structs/projects";
import { firstLetterUpperCase } from "@structs/utils";
import { concatMap, last, map, switchMap } from "rxjs/operators";
import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { PerimetersService } from "./perimeters.service";
import { PicturesLoaderService } from "./pictures-loader.service";
import { ScopeService } from "./scope.service";

@Injectable()
export class SynthesisService {
  public auditSynthesis: BehaviorSubject<AuditSynthesisChange> = null;

  private synthesisPerimeter: Perimeter = null;

  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private picturesLoaderService: PicturesLoaderService,
    private scope: ScopeService,
    private translate: TranslateService
  ) {
    this.auditSynthesis = new BehaviorSubject(null);
  }

  /**
   returns Observable on the current audit synthesis data
   */
  public watchSynthesis(): Observable<AuditSynthesisChange> {
    return this.auditSynthesis.asObservable();
  }

  /**
   * Returns CAPEX audit synthesis for a given perimeter and a given year (may be future year)
   * @param perimeter
   * @param year
   * @returns Observable<AuditSynthesis>
   */
  public getAuditSynthesis(perimeter: Perimeter, year: number): Observable<AuditSynthesis> {
    this.synthesisPerimeter = perimeter;
    if (perimeter) {
      let currentTimestamp: number = Math.round(+moment() / 1000);
      return this.offlineApi.getAuditSynthesisLastRefresh(perimeter).pipe(
        switchMap((lastRefresh: number) => {
          let url = `/audit/api/synthesis/${perimeter.id}/${year}/${lastRefresh}/`;
          // If last refresh is older than 2 minutes, we force a refresh
          return this.backend.get(url).pipe(
            switchMap(jsonData => {
              const auditSynthesis: AuditSynthesis = makeAuditSynthesis(jsonData);
              auditSynthesis.assets = auditSynthesis?.assets?.map(asset => setAsset(asset));
              // Preload thumbnails of new assets and investments
              auditSynthesis.assets.map(asset => {
                // TODO from Luc that was already on the ion3 app : re-work audit-synthesis
                asset.children.map(childAsset => {
                  this.picturesLoaderService.preloadThumbnails(childAsset.pictures);
                });
                this.picturesLoaderService.preloadThumbnails(asset.pictures);
              });
              auditSynthesis.investments.map(investment =>
                this.picturesLoaderService.preloadThumbnails(investment.pictures)
              );

              if (perimeter.pictures !== undefined && perimeter.pictures.length > 0) {
                this.picturesLoaderService.preloadThumbnails(perimeter.pictures);
              }

              // store global investments
              return from(auditSynthesis.buildings)
                .pipe(
                  concatMap((building: AuditBuilding) => {
                    return this.offlineApi.storeGlobalInvestments(building);
                  }),
                  last()
                )
                .pipe(
                  switchMap(() =>
                    this.offlineApi.setAuditSynthesisLastRefresh(perimeter, currentTimestamp).pipe(
                      map(() => {
                        // inform main app for saving it on cache
                        this.auditSynthesis.next(new AuditSynthesisChange(perimeter, year, auditSynthesis));

                        return auditSynthesis;
                      })
                    )
                  )
                );
            })
          );
        })
      );
    } else {
      return throwError("No perimeter");
    }
  }

  /**
   * Checks if an audit exists in the cache, otherwise loads it.
   *
   * @param perimeter
   * @param year
   *
   * @returns An observable emitting `true` if the audit was in cache, `false` otherwise.
   */
  public ensureAuditSynthesis(perimeter: Perimeter, year: number): Observable<boolean> {
    return new Observable(observer => {
      this.offlineApi.getAuditSynthesis(perimeter, year).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        () => {
          this.getAuditSynthesis(perimeter, year).subscribe(
            () => {
              observer.next(false);
              observer.complete();
            },
            err => {
              observer.error(err);
            }
          );
        }
      );
    });
  }

  /**
   * Invalidate AuditSynthesis cache when something has changed
   * @param asset
   * @param notes : list of notes
   * @returns Observable
   */
  public updateAssetNoteInSynthesis(asset: Asset, notes: Array<AuditResultItem>): Observable<Asset> {
    if (notes && notes.length > 0) {
      asset.expectedLifetime = notes[0].expectedLifetime;
    }
    return this.offlineApi.storeAsset(asset);
  }

  /**
   * Update the audit element base on
   * @param asset
   * @returns Observable
   */
  public updateAssetInSynthesis(asset: Asset): Observable<boolean> {
    return this.offlineApi.storeAsset(asset).pipe(map(() => true));
  }

  public updateAssetAccessInSynthesis(asset: Asset): Observable<Asset> {
    return this.offlineApi.storeAsset(asset);
  }

  public removeAssetFromSynthesis(asset: Asset): Observable<boolean> {
    return this.scope.getCurrentMultiPerimeter().pipe(
      switchMap(perimeter =>
        this.offlineApi.getAuditSynthesisMap().pipe(
          switchMap((auditSynthesisMap: any) => {
            let newMap: Map<string, AuditSynthesis> = this._removeAssetFromMap(perimeter, asset, auditSynthesisMap);
            return this.offlineApi.storeAuditSynthesisMap(newMap).pipe(map(() => true));
          })
        )
      )
    );
  }

  /**
   * get the global notes for an asset
   * @param asset
   * @returns Observable<boolean> : success of the operation
   */
  public getAuditNote(asset: Asset): Observable<Array<AuditResultItem>> {
    let url = "/audit/api/notation/" + asset.id + "/";
    return this.backend.get(url).pipe(map(({ notes }) => notes));
  }

  public getAuditStates(): Observable<Array<AuditState>> {
    return this.offlineApi.getConfig("auditStates").pipe(
      map((jsonData: any) => {
        let data = jsonData ? jsonData : [];
        // Overiding the backend translations
        data.forEach(auditState => {
          auditState.name = this.translate.instant(firstLetterUpperCase(auditState.level));
        });
        return data;
      })
    );
  }

  public searchAsset(perimeterId: number, criteria: Array<any>): Observable<Array<number>> {
    return this.backend.post(`/audit/api/search-asset/${perimeterId}/`, criteria);
  }

  public getAssets(): Observable<Asset[]> {
    return this.offlineApi.getAuditSynthesisEx().pipe(map(synthesis => synthesis.assets));
  }

  /**
   * Quickly get the total number of assets
   */
  public getAssetNumber(perimeter: Perimeter, year: number): Observable<number> {
    return this.offlineApi.getAuditSynthesis(perimeter, year).pipe(
      map(auditSynthesis => {
        return auditSynthesis.assets.reduce(
          // taking into account the children asset
          (accumulate, currAsset) => {
            const children = currAsset.children.filter(c => !auditSynthesis.assets.some(a => a.id === c.id));
            return accumulate + children.length + 1;
          },
          0
        );
      })
    );
  }

  // returns an observer of an asset. The subscribe will be called for every asset in the synthesis
  public browseSynthesisAssets(): Observable<Asset> {
    return new Observable(observer => {
      this.offlineApi.getAuditSynthesisEx().subscribe((auditSynthesis: AuditSynthesis) => {
        from(auditSynthesis.assets).subscribe(
          (asset: Asset) => {
            observer.next(asset);
          },
          err => {},
          () => {
            // all assets have been handled. Complete the observer
            observer.complete();
          }
        );
      });
    });
  }

  private _removeAssetFromMap(perimeter: Perimeter, asset: Asset, mapObj: any): Map<string, AuditSynthesis> {
    let newMap: Map<string, AuditSynthesis> = new Map<string, AuditSynthesis>();

    let keys = [];
    let currentYear: number = moment().year();
    for (let i = 0; i < 5; i++) {
      keys.push(perimeter.id + "/" + currentYear + i);
    }
    Object.keys(mapObj).forEach((key: string) => {
      if (key.search("_") === 0) {
        // this can be object property and should be ignored
        return;
      }
      let auditSynthesis = mapObj[key];
      let assetEltIndex = -1;
      // Look for an element corresponding to the asset
      for (let i = 0, l = auditSynthesis.elements.length; i < l && assetEltIndex < 0; i++) {
        let elt = auditSynthesis.elements[i];
        if ((elt.assetId > 0 && elt.assetId === asset.id) || (elt.offlineId > 0 && elt.offlineId === asset.offlineId)) {
          // found! mark it for deletion
          assetEltIndex = i;
        }
      }
      // if found : delete the element
      if (assetEltIndex >= 0) {
        auditSynthesis.elements.splice(assetEltIndex, 1);
      }
      newMap[key] = auditSynthesis;
    });
    return newMap;
  }

  // Returns projects linked to the perimeter and its sub_perimeters
  public getPerimeterProjects(perimeter: Perimeter): Observable<Project[]> {
    const allPerimeterIds: number[] = PerimetersService.getLinkedPerimetersIds(perimeter);
    return this.offlineApi
      .getAuditSynthesisEx()
      .pipe(map(({ projects }) => projects?.filter(project => allPerimeterIds.includes(project.perimeter)) || []));
  }
}

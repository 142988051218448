import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Perimeter, PerimeterPicture } from "src/app/structs/assets";

@Component({
  selector: "pictures-summary-item", // eslint-disable-line @angular-eslint/component-selector
  templateUrl: "./pictures-summary-item.component.html",
  styleUrls: ["./pictures-summary-item.component.scss"],
})
export class PicturesSummaryItemComponent {
  @Input() public legend: string;
  @Input() public pictures: PerimeterPicture[];
  @Input() public perimeter: Perimeter;
  @Input() public syncInProgress: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public click = new EventEmitter<void>();

  constructor() {}

  public onPictureClick(event: Event): void {
    event.stopPropagation();
    this.click.emit();
  }
}

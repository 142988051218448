<ng-container *ngIf="childComponentMode">
  <ion-list lines="full">
    <ion-item-sliding #item *ngFor="let slice of currentSlices">
      <ion-item (click)="editSlice(slice)">
        <ion-label>
          {{ slice.formattedPrice }} {{ "in" | translate }} {{ slice.year }}
          <div>({{ slice.status.name }})</div>
        </ion-label>
        <div slot="" class="text-style-extra-work" *ngIf="slice.isExtraWork && slice.price > 0">
          ({{ "Extra work" | translate }})
        </div>
        <div item-content *ngIf="slice.addPriceReason" class="text-style3">{{ slice.addPriceReason }}</div>
        <div
          slot="end"
          *ngIf="slicesLastHistory && slice.id && slicesLastHistory.slices[slice.id]"
          class="text-style3 last-modification">
          <div *ngIf="slicesLastHistory.slices[slice.id].user">
            {{ slicesLastHistory.slices[slice.id].user | username }}
          </div>
          <div>{{ slicesLastHistory.slices[slice.id].date | date }}</div>
        </div>
      </ion-item>
      <ion-item-options side="end">
        <ion-item-option (click)="deleteSlice(item, slice.id)">
          <ion-icon name="trash-outline"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</ng-container>

<ng-container *ngIf="!childComponentMode">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button>
          <ion-button icon-only (click)="pendingChangesPrompt()">
            <i class="fa-solid fa-circle-chevron-left back-icon"></i>
          </ion-button>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ "Budget" | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list lines="full">
      <ion-item-sliding #item *ngFor="let slice of currentSlices">
        <ion-item (click)="editSlice(slice)">
          <ion-label>
            {{ slice.formattedPrice }} {{ "in" | translate }} {{ slice.year }}
            <div>({{ slice.status.name }})</div>
          </ion-label>
          <div slot="" class="text-style-extra-work" *ngIf="slice.isExtraWork && slice.price > 0">
            ({{ "Extra work" | translate }})
          </div>
          <div item-content *ngIf="slice.addPriceReason" class="text-style3">{{ slice.addPriceReason }}</div>
          <div
            slot="end"
            *ngIf="slicesLastHistory && slice.id && slicesLastHistory.slices[slice.id]"
            class="text-style3 last-modification">
            <div *ngIf="slicesLastHistory.slices[slice.id].user">
              {{ slicesLastHistory.slices[slice.id].user | username }}
            </div>
            <div>{{ slicesLastHistory.slices[slice.id].date | date }}</div>
          </div>
        </ion-item>
        <ion-item-options side="end">
          <ion-item-option (click)="deleteSlice(item, slice.id)">
            <ion-icon name="trash-outline"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </ion-content>
  <ion-fab vertical="center" horizontal="end" slot="fixed" *ngIf="investment.slices.length === 0">
    <ion-fab-button (click)="editSlice()" color="secondary">
      <i class="fal fa-2x fa-plus"></i>
    </ion-fab-button>
  </ion-fab>
  <ion-footer>
    <app-footer-next-button
      *ngIf="pendingChanges && !addMode"
      nextButton
      (next)="validateChanges()"
      [label]="'validate'"
      [enabled]="true"
      color="investment"></app-footer-next-button>
  </ion-footer>
</ng-container>

<ion-list lines="full">
  <ng-container *ngIf="assetEditService.technicalStateQuestion">
    <ion-item [lines]="hasNotation ? 'none' : 'full'">
      <ion-label>
        <div class="legend text-style2 text-overflow-ellipsis">
          {{ "Global technical state" | translate }}
        </div>
      </ion-label>
      <ion-toggle
        [disabled]="assetEditService.readOnly"
        [(ngModel)]="hasNotation"
        (ngModelChange)="onHasNotationChanged()"
        slot="end"
        #hasNotationToggle></ion-toggle>
    </ion-item>

    <input-item *ngIf="hasNotation" class="technical-state-input">
      <technical-state-input
        *ngIf="assetEditService.technicalStateQuestion.question_type === 'sliders'"
        [asset]="assetEditService.asset"
        [readOnly]="assetEditService.readOnly"
        [refresh]="technicalStateInputRefresh"
        (onNoteChanged)="noteUpdated($event)"
        [defaultNote]="defaultNote"></technical-state-input>
      <stars-note
        *ngIf="assetEditService.technicalStateQuestion.question_type === 'stars'"
        [question]="assetEditService.technicalStateQuestion"
        (clicked)="noteUpdated($event)"
        [readOnly]="assetEditService.readOnly"></stars-note>
    </input-item>

    <input-item [legend]="'Reason for Impossibility of evaluation' | translate" [hidden]="hasNotation">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        placeholder="{{ 'Click here (obligatory info)' | translate }}"
        [(ngModel)]="reasonForMissingNotation"
        (ngModelChange)="onMissingNotationReasonChanged()"
        interface="action-sheet"
        #missingNotationSelect>
        <ion-select-option *ngFor="let reason of missingNotationReasons" [value]="reason.value">
          {{ reason.name }}
        </ion-select-option>
      </ion-select>
    </input-item>

    <input-item [legend]="'Comments' | translate" *ngIf="!hasNotation">
      <ion-textarea
        autosize
        type="text"
        placeholder="-"
        [(ngModel)]="assetEditService.asset.notationMissingComment"
        (ngModelChange)="notationMissingCommentChanged()"></ion-textarea>
    </input-item>
  </ng-container>

  <input-item
    [legend]="'Rating reason' | translate"
    *ngIf="!updatingRatingReasons && hasNotation && ratingReasonsForState.length > 0">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      [(ngModel)]="selectedRatingReasonIds"
      (ngModelChange)="ratingReasonUpdated($event)"
      [disabled]="assetEditService.readOnly"
      placeholder="{{ 'Click here (obligatory info)' | translate }}"
      multiple
      #ratingReasonsSelect>
      <ion-select-option *ngFor="let ratingReason of ratingReasonsForState" [value]="ratingReason.id">
        {{ ratingReason.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item [legend]="'Installation year' | translate">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="assetEditService.asset.installationYear"
      (ngModelChange)="installationYearUpdated()"
      [disabled]="assetEditService.readOnly"
      (click)="onSelectClicked()"
      placeholder="-">
      <ion-select-option *ngFor="let installationYear of installationYears" [value]="installationYear.value">
        {{ installationYear.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <!-- CURRENT ASSET AGE -->
  <legend-item [legend]="'Current age of the equipment' | translate">
    {{ currentAgeLabel }}
  </legend-item>

  <input-item
    [legend]="'Planned resale' | translate"
    *ngIf="assetEditService.asset.assetType?.plannedResale && assetEditService.asset.plannedRecycle !== 1">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="assetEditService.asset.plannedResale"
      (ngModelChange)="plannedResaleChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option *ngFor="let choice of booleanChoices" [value]="choice.value">
        {{ choice.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item
    [legend]="'Planned recycle' | translate"
    *ngIf="assetEditService.asset.assetType?.plannedRecycle && assetEditService.asset.plannedResale !== 1">
    <ion-select
      okText="{{ 'OK' | translate }}"
      cancelText="{{ 'Cancel' | translate }}"
      interface="action-sheet"
      [(ngModel)]="assetEditService.asset.plannedRecycle"
      (ngModelChange)="plannedRecycleChanged()"
      [disabled]="assetEditService.readOnly">
      <ion-select-option *ngFor="let choice of booleanChoices" [value]="choice.value">
        {{ choice.name }}
      </ion-select-option>
    </ion-select>
  </input-item>

  <input-item
    [legend]="'Recycle value' | translate"
    *ngIf="assetEditService.asset.assetType?.plannedRecycle && assetEditService.asset.plannedRecycle === 1">
    <ion-input
      type="number"
      min="0"
      [(ngModel)]="assetEditService.asset.recycleValue"
      (keyup)="recycleValueChanged($event)"
      placeholder="-"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <input-item
    [legend]="'Resale value' | translate"
    *ngIf="assetEditService.asset.assetType?.plannedResale && assetEditService.asset.plannedResale === 1">
    <ion-input
      type="number"
      min="0"
      [(ngModel)]="assetEditService.asset.resaleValue"
      (keyup)="resaleValueChanged($event)"
      placeholder="-"
      [disabled]="assetEditService.readOnly"></ion-input>
  </input-item>

  <!-- REMAINING LIFETIME -->
  <div *ngIf="hasNotation">
    <ion-row>
      <ion-col class="ion-no-padding" size="9">
        <input-item [legend]="remainingLifetimeLabel" lines="none">
          <ion-input
            type="number"
            min="0"
            [(ngModel)]="remainingLifetimeDuration"
            (keyup)="inputNumberChecker($event)"
            (ngModelChange)="remainingLifetimeUpdated()"
            placeholder="-"
            [disabled]="assetEditService.readOnly || !assetEditService.asset.installationYear"></ion-input>
        </input-item>
      </ion-col>
      <ion-col class="ion-align-self-center ion-padding-end">
        <ion-note class="item-end ion-text-wrap" role="end">
          <ion-row>
            {{ "Source" | translate }} :
            <span class="source">{{ remainingLifetimeSource }}</span>
          </ion-row>
        </ion-note>
      </ion-col>
    </ion-row>

    <asset-inconsistent-data-suggestion
      *ngIf="suggestedLifetime && !hideShow"
      [suggestedLifetime]="suggestedLifetime"></asset-inconsistent-data-suggestion>

    <!-- SEE MORE DETAILS BUTTON -->
    <ion-row class="grey-divider top-border" (click)="toggleDetails()">
      <ion-col size="2" size-lg="1"></ion-col>
      <ion-col>
        <ion-note color="primary" class="text-underline {{ dangerClass }}">{{ toggleDetailsLabel }}</ion-note>
      </ion-col>
      <ion-col size="2" size-lg="1">
        <div class="danger-class bell" *ngIf="suggestedLifetime && hideShow">
          <i class="fal fa-bell"></i>
          (1)
        </div>
      </ion-col>
    </ion-row>

    <legend-item
      *ngIf="nextAsset$ | async as nextAssetInfos"
      [legend]="'Will be replaced by' | translate"
      class="{{ hideShow }}"
      (click)="nextAssetClicked(nextAssetInfos.id)">
      {{ nextAssetInfos.label }}
      <ion-text color="unknown">[{{ "project" | translate }}]</ion-text>
      {{ "in" | translate }} {{ nextAssetInfos.date }}
    </legend-item>

    <!-- PLANNED INVEST WHICH IMPACTS THE LIFETIME -->
    <legend-item
      [legend]="'Investment(s) with impact on lifetime' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      [color]="'grey-background'">
      {{ investmentsImpactLabel }}
    </legend-item>

    <!-- CURRENT ASSET NOTE -->
    <legend-item
      [legend]="'Asset current note' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      *ngIf="!assetEditService?.addMode"
      [color]="'grey-background'">
      {{ currentNoteLabel }}
    </legend-item>

    <!-- EXPECTED END OF LIFE YEAR -->
    <legend-item
      [legend]="'Expected end of life year' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      *ngIf="!hasImpact"
      [color]="'grey-background'">
      {{ enfOfLifeYearNoImpactForDisplay }}
    </legend-item>

    <!-- END OF LIFE YEAR WITH PLANNED INVEST IMPACT -->
    <legend-item
      [legend]="'End of life year WITH invest. planned' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      *ngIf="hasImpact"
      [color]="'grey-background'">
      {{ endOfLifeYearWithInvestForDisplay }}
    </legend-item>

    <!-- END OF LIFE YEAR WITHOUT PLANNED INVEST IMPACT -->
    <legend-item
      [legend]="'End of life year WITHOUT invest. planned' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      [disabledLegend]="true"
      *ngIf="hasImpact"
      [color]="'grey-background'">
      {{ enfOfLifeYearNoImpactForDisplay }}
    </legend-item>

    <!-- ASSET TYPE THEORICAL LIFETIME -->
    <legend-item
      [legend]="'Theoretical lifetime for reference' | translate"
      [readOnly]="true"
      class="{{ hideShow }}"
      [color]="'grey-background'">
      {{ theoreticalLifetimeForDisplay }}
    </legend-item>

    <div class="space"></div>
  </div>
</ion-list>

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as R from "ramda";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";

import { Perimeter, ReferenceFieldDataFiltering } from "../structs/assets";
import { DataType, FilterType, PerimeterFilter } from "../structs/perimeter-filter";
import { PerimeterReferenceDataFilter } from "../structs/perimeter-reference-data-filter";
import { OfflineService } from "./offline.service";
import { ScopeService } from "./scope.service";
import { PerimeterClusterFilter } from "../structs/perimeter-cluster-filter";

/**
 * Service to generate and apply perimeter filters.
 */
@Injectable()
export class PerimetersFilterService {
  public selectedChange = new BehaviorSubject<boolean>(false);

  private filters: PerimeterFilter[];

  constructor(
    private offlineService: OfflineService,
    private scope: ScopeService,
    private translate: TranslateService
  ) {}

  public generateFilters(perimeters: Perimeter[]): Observable<PerimeterFilter[]> {
    return new Observable(observer => {
      if (!this.filters) {
        combineLatest(
          this.scope.getReferenceFields(),
          this.offlineService.getConfig("users"),
          this.scope.getClusters()
        ).subscribe(([referenceFields, users, clusters]) => {
          this.filters = referenceFields
            .filter(referenceField => referenceField.filtering !== ReferenceFieldDataFiltering.FILTER_NO)
            .map(referenceField => new PerimeterReferenceDataFilter(referenceField, perimeters, users))
            .filter(filter => filter.availableValues.length > 1 || filter.availableValuesRange.length > 1); // Discard filters having one value

          // Adding the cluster filter only if there is more than one value available
          if (clusters.length > 1) {
            const clusterLabel = this.translate.instant("Cluster");
            this.filters.push(new PerimeterClusterFilter(clusters, clusterLabel));
          }
          observer.next(this.filters);
          observer.complete();
        });
      } else {
        observer.next(this.filters);
        observer.complete();
      }
    });
  }

  public onFilterSelected(): void {
    this.selectedChange.next(this.hasFiltersSelected());
  }

  public getFilters(): PerimeterFilter[] {
    return (this.filters && this.filters.filter(filter => filter.hasSelected())) || [];
  }

  public hasFiltersSelected(): boolean {
    return this.filters && this.filters.some(filter => filter.hasSelected());
  }

  public clearFilters(): void {
    this.filters.forEach(filter => filter.clearSelected());
    this.selectedChange.next(false);
  }

  public resetFilters(): void {
    this.filters = null;
  }

  public applyFilters(perimeters: Perimeter[]): Perimeter[] {
    if (!this.filters) {
      return perimeters;
    }

    const predicate = R.allPass(
      this.filters.filter(filter => filter.hasSelected()).map(filter => filter.getPredicate())
    );

    return perimeters.reduce((filteredPerimeters, perimeter) => {
      const filteredSubPerimeters = this.applyFilters(perimeter.sub_perimeters);

      if (filteredSubPerimeters.length > 0 || predicate(perimeter)) {
        perimeter.filtered_sub_perimeters = filteredSubPerimeters;

        return [
          ...filteredPerimeters,
          {
            ...perimeter,
            filtered_sub_perimeters: filteredSubPerimeters,
          },
        ];
      }

      return filteredPerimeters;
    }, []);
  }
}

import { Injectable } from "@angular/core";
import { marker as _ } from "@biesbjerg/ngx-translate-extract-marker";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import * as R from "ramda";
import { Observable, forkJoin, of } from "rxjs";

import { Asset, ImportableAssetStatus } from "../structs/assets";
import { ImportableInvestmentStatus } from "../structs/investments";
import { mergeMap } from "rxjs/operators";

const IMPORTABLE_STATUS_TRANSLATION_MAP = {
  [ImportableAssetStatus.INSTALLATION_YEAR]: _("Installation year"),
  [ImportableAssetStatus.NOTATION]: _("Notation"),
  [ImportableInvestmentStatus.ASSET_LINK]: _("Asset link"),
};

type ImportableStatus = ImportableAssetStatus | ImportableInvestmentStatus;

@Injectable()
export class ImportStatusService {
  constructor(private alertCtrl: AlertController, private translate: TranslateService) {}

  public isValidated(statuses: ImportableStatus[]): boolean {
    return !statuses || statuses.length === 0 || R.contains(ImportableAssetStatus.VALIDATED, statuses);
  }

  public isReady(statuses: ImportableStatus[]): boolean {
    return statuses && R.contains(ImportableAssetStatus.READY, statuses);
  }

  public getBadgeStatus(statuses: ImportableStatus[]): "validated" | "ready" | "pending" {
    if (this.isValidated(statuses)) {
      return "validated";
    } else if (this.isReady(statuses)) {
      return "ready";
    } else {
      return "pending";
    }
  }

  public getBadgeStatusForCMMSSync(asset: Asset): "synchronized" | "conflict" | "none" {
    if (asset.importStatuses && asset.importStatuses.length > 0) {
      if (!asset.levelFromExternalBase) {
        return "synchronized";
      } else {
        if (asset.levelFromExternalBase === asset.level) {
          return "synchronized";
        } else {
          return "conflict";
        }
      }
    } else {
      return "none";
    }
  }

  public getDisplayStatus(statuses: ImportableStatus[]): Observable<string> {
    if (this.isValidated(statuses)) {
      return this.translate.get("Validated");
    } else if (this.isReady(statuses)) {
      return this.translate.get("Ready for validation");
    } else {
      return forkJoin(statuses.map(status => this.translate.get(IMPORTABLE_STATUS_TRANSLATION_MAP[status]))).pipe(
        mergeMap(translatedStatuses => {
          return this.translate.get("Pending confirmation: {{statuses}}", {
            statuses: translatedStatuses.join(", ").toLowerCase(),
          });
        })
      );
    }
  }

  public async showConfirmImportAlert(statuses: ImportableStatus[]): Promise<ImportableStatus[]> {
    // Already validated, nothing to do
    if (this.isValidated(statuses)) {
      return null;
    }

    // Ready for validation: ask confirmation and return validated status
    if (this.isReady(statuses)) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant("Confirm the import?"),
        buttons: [
          {
            text: this.translate.instant("Cancel"),
            role: "cancel",
            handler: () => {
              return null;
            },
          },
          {
            text: this.translate.instant("Ok"),
            handler: () => {
              return [ImportableAssetStatus.VALIDATED];
            },
          },
        ],
      });
      await alert.present();
    }

    // Not ready: tell the user what to do
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("Can't confirm the import"),
      message: this.translate.instant("You have to validate the required data to confirm the import."),
      buttons: [
        {
          text: this.translate.instant("Ok"),
          handler: () => {
            return null;
          },
        },
      ],
    });
    await alert.present();
  }

  public removeImportStatus(statusToRemove: ImportableStatus, statuses: ImportableStatus[]): ImportableStatus[] {
    if (!statuses) {
      return statuses;
    }

    const updatedStatuses = statuses.filter(status => status !== statusToRemove);

    if (updatedStatuses.length === 0) {
      return [ImportableAssetStatus.READY];
    }

    return updatedStatuses;
  }
}

<ion-grid>
  <ion-list lines="full" no-padding *ngIf="question">
    <div class="question-header">
      {{ question.text }}
    </div>
    <div class="color-unknown" *ngIf="question.help_text_subject">
      {{ question.help_text_subject }}
    </div>
    <div class="color-unknown" *ngIf="question.help_text">
      {{ question.help_text }}
    </div>

    <div class="stars-note" [ngClass]="'note-' + note">
      <div class="star" *ngFor="let choice of choices" (click)="selectNote($event, choice)">
        <div *ngIf="progressiveMode">
          <i [ngClass]="faIconFull" *ngIf="note && choice <= note"></i>
          <!-- full -->
          <i [ngClass]="faIconEmpty" *ngIf="!note || choice > note"></i>
          <!-- empty -->
        </div>
        <div *ngIf="!progressiveMode">
          <i [ngClass]="faIconFull" *ngIf="note && choice === note"></i>
          <!-- full -->
          <i [ngClass]="faIconEmpty" *ngIf="!note || choice !== note"></i>
          <!-- empty -->
        </div>
      </div>
    </div>
    <div class="value-labels">
      <div class="low-value-label">{{ question.low_value_label }}</div>
      <div class="high-value-label">{{ question.high_value_label }}</div>
    </div>
  </ion-list>
</ion-grid>

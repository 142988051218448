<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "Tags" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-investment-tags
    [investment]="investment"
    [addMode]="addMode"
    (changesHappened)="changes()"></app-investment-tags>
</ion-content>

import { Injectable } from "@angular/core";
import { Observable, combineLatest } from "rxjs";
import { BackendService } from "./backend.service";
import { HistoryObj, makeHistoryObj, getDistinctObjectIdsFromChangeItems } from "../structs/history";
import { AppUser } from "../structs/base";
import { OfflineService } from "./offline.service";
import { map } from "rxjs/operators";

export interface SliceModification {
  user: AppUser;
  date: Date;
}

export interface SlicesLastHistory {
  slices: { [key: number]: SliceModification };
  spendingSlices: { [key: number]: SliceModification };
}

/**
 * Get and format history of an investment
 */
@Injectable()
export class InvestmentHistoryService {
  constructor(private backend: BackendService, private offlineService: OfflineService) {}

  /**
   * Get the history of the investment passed in parameter from backend
   *
   * @param investmentId
   */
  public getHistoryForInvestment(investmentId: number): Observable<HistoryObj> {
    return new Observable(observer => {
      this.backend.get(`/investments/api/investments/${investmentId}/history/`).subscribe(
        jsonData => {
          observer.next(makeHistoryObj(jsonData));
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Get the information (user-date) about the last modification
   * that impacted the slices linked to the investment
   *
   * @param investmentId the investment's id
   */
  public getSlicesLastHistory(investmentId: number): Observable<SlicesLastHistory> {
    return combineLatest(this.getHistoryForInvestment(investmentId), this.offlineService.getConfig("users")).pipe(
      map(([history, users]) => {
        return {
          slices: this.recoverSlicesModificationFromHistory("investmentslice", history, users),
          spendingSlices: this.recoverSlicesModificationFromHistory("investmentspendingslice", history, users),
        };
      })
    );
  }

  /**
   * Recover the information about the last modification for the slices using the investment's history
   *
   * @param hist the investment's history
   * @param users the list of users
   */
  private recoverSlicesModificationFromHistory(objectType: string, hist: HistoryObj, users: AppUser[]) {
    const slicesModificationMap: { [key: number]: SliceModification } = {};

    // We sort the history item by date
    const historyItems = hist.historyItems.sort((item1, item2) => item2.date.getTime() - item1.date.getTime());

    for (const historyItem of historyItems) {
      const sliceChanges = historyItem.changes.filter(
        chan => chan.objectType === objectType && !slicesModificationMap[chan.objectId]
      );
      let distinctIds: number[] = [];
      if (sliceChanges.length > 0) {
        distinctIds = getDistinctObjectIdsFromChangeItems(sliceChanges);
      }

      for (const id of distinctIds) {
        slicesModificationMap[id] = {
          user: users.find(user => user.get_user_id === historyItem.user),
          date: historyItem.date,
        };
      }
    }

    return slicesModificationMap;
  }
}

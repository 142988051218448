<ion-list lines="full">
  <ion-item>
    <ion-label>{{ "Tags" | translate }}</ion-label>
    <ion-toggle [(ngModel)]="tagsToggle" (ionChange)="changes()"></ion-toggle>
  </ion-item>
  <ng-container
    *ngIf="{
      workflowTags: workflowTags$ | async,
      otherTags: otherTags$ | async
    } as obs">
    <div *ngIf="tagsToggle">
      <!-- Workflow tags -->
      <ion-row
        class="grey-divider top-border"
        (click)="showWorkflowTags = !showWorkflowTags"
        *ngIf="obs.workflowTags?.length"
        [ngClass]="!showWorkflowTags ? 'space-bottom' : null">
        <ion-col>
          <span *ngIf="showWorkflowTags">(-)</span>
          <span *ngIf="!showWorkflowTags">(+)</span>
          <ion-note color="primary" class="text-underline">
            {{ "Workflow tags" | translate }}
          </ion-note>
        </ion-col>
      </ion-row>
      <ion-item *ngFor="let tag of obs.workflowTags" [hidden]="!showWorkflowTags">
        <ion-checkbox
          slot="start"
          [checked]="isChecked(tag.id)"
          (ionChange)="checkTag(tag)"
          [disabled]="tag.disabled"
          mode="md"></ion-checkbox>
        <ion-label>{{ tag.name }}</ion-label>
      </ion-item>
      <!-- Other tags -->
      <ion-row
        class="grey-divider top-border"
        (click)="showOtherTags = !showOtherTags"
        *ngIf="obs.otherTags?.length && obs.workflowTags?.length">
        <ion-col>
          <span *ngIf="showOtherTags">(-)</span>
          <span *ngIf="!showOtherTags">(+)</span>
          <ion-note color="primary" class="text-underline">{{ "Other tags" | translate }}</ion-note>
        </ion-col>
      </ion-row>
      <ion-item *ngFor="let tag of obs.otherTags" [hidden]="!showOtherTags">
        <ion-checkbox
          slot="start"
          [checked]="isChecked(tag.id)"
          (ionChange)="checkTag(tag)"
          [disabled]="tag.disabled"
          mode="md"></ion-checkbox>
        <ion-label>{{ tag.name }}</ion-label>
      </ion-item>
    </div>
  </ng-container>
</ion-list>

export class User {
  constructor(
    public id: number,
    public get_user_id: number,
    public email: string,
    public apiKey: string,
    public uuid: string,
    public firstName: string,
    public lastName: string
  ) {}

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  public getDisplayName(): string {
    const fullName = this.getFullName();
    return !!fullName ? fullName : this.email;
  }
}

<ion-content>
  <ion-searchbar
    [(ngModel)]="searchText"
    (ionInput)="onSearchChanged()"
    (ionCancel)="onSearchCancelled()"
    placeholder="{{ 'Search' | translate }}"></ion-searchbar>

  <ion-list lines="full">
    <asset-item
      *ngFor="let asset of filteredParents"
      [asset]="asset"
      [highlighted]="selectedParent === asset"
      [childrenMode]="false"
      (assetClick)="selectParent(asset, false)"
      (cloneAssetClicked)="duplicateAsset(asset)"></asset-item>
  </ion-list>
</ion-content>

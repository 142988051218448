import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, combineLatest, of } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import {
  AssetEnergyConsumption,
  AssetEnergyConsumptionPayload,
  Initiative,
  InitiativeStatus,
  MultiPerimeterEnergyTrajectory,
  PerimeterEnergyTrajectory,
} from "../structs/initiative";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { SynchronizationService } from "./synchronization.service";
import { ChangeAction, makeChange } from "@structs/synchronization";
import { ConfigService } from "./config.service";
import { InitiativeInvestment, Scenario } from "@structs";
import { FormBuilder } from "@angular/forms";

type TSiteId = number;

type TLocalStore<T> = [BehaviorSubject<T[]>, Observable<T[]>];

// The backend doesn't allow these fields to be null
export const fieldsThatCantBeNull = [
  "cee_bonus",
  "theoretical_greenhouse_gas_savings_full_year",
  "theoretical_savings_full_year",
  "yearly_cost_savings",
];

@Injectable()
export class InitiativeService {
  private readonly perimetersEnergyTrajectories = new BehaviorSubject<MultiPerimeterEnergyTrajectory[]>([]);
  perimetersEnergyTrajectories$ = this.perimetersEnergyTrajectories.asObservable();

  /**
   *
   */
  private initiativeStore = new Map<TSiteId, TLocalStore<Initiative>>();

  constructor(
    private offlineService: OfflineService,
    private syncService: SynchronizationService,
    private backendService: BackendService,
    private configService: ConfigService,
    private fb: FormBuilder
  ) {
    this.offlineService
      .getPerimeterEnergyTrajectories()
      .pipe(tap(trajectories => this.perimetersEnergyTrajectories.next(trajectories)))
      .subscribe();
  }

  makeForm() {
    return this.fb.group({});
  }

  updateAssetConsumption(
    multiPerimeterId: number,
    monoPerimeterId: number,
    trajectory: number,
    perimeterConsumptionId: number,
    data: AssetEnergyConsumptionPayload
  ): Observable<AssetEnergyConsumptionPayload> {
    const url = `/initiatives/api/asset-energy-consumption/${perimeterConsumptionId}/`;
    const change = makeChange(ChangeAction.putAssetEnergyConsumption, url, "post", data);
    return this.syncService.addChange(change).pipe(
      switchMap(() =>
        this.updateAssetConsumptionLocal(multiPerimeterId, monoPerimeterId, trajectory, perimeterConsumptionId, {
          ...data,
          perimeter_energy_consumption_id: perimeterConsumptionId,
        })
      ),
      switchMap(() => this.syncService.signalOfflineChanges()),
      map(() => data)
    );
  }

  /**
   * handle nested logic to update (offline) one asset energy consumption
   * Read this before make the change:
   * https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#updating-an-item-in-an-array
   */
  private updateAssetConsumptionLocal(
    multiPerimeterId: number,
    monoPerimeterId: number,
    trajectory: number,
    perimeterConsumptionId: number,
    data: AssetEnergyConsumption
  ): Observable<boolean> {
    const updatedEnergyTrajectory = this.perimetersEnergyTrajectories.value.map(multiPerimeter => {
      function addOrUpdateAssetConsumption(
        assetConsumptions: AssetEnergyConsumption[],
        assetConsumption: AssetEnergyConsumption
      ) {
        const matchedConsumption = assetConsumptions.findIndex(consumption => {
          return (
            (consumption.asset_id && consumption.asset_id === assetConsumption.asset_id) ||
            (consumption.assetOfflineId && consumption.assetOfflineId === assetConsumption.assetOfflineId)
          );
        });
        if (matchedConsumption > -1) {
          assetConsumptions[matchedConsumption] = assetConsumption;
          return assetConsumptions;
        }
        return [assetConsumption, ...assetConsumptions];
      }

      if (multiPerimeter.multi_perimeter_id !== multiPerimeterId) {
        return multiPerimeter;
      }

      return {
        ...multiPerimeter,
        perimeters: multiPerimeter.perimeters.map(monoPerimeter => {
          if (monoPerimeter.id !== monoPerimeterId) return monoPerimeter;

          return {
            ...monoPerimeter,
            trajectories: monoPerimeter.trajectories.map(perimeterEnergyTrajectory => {
              if (perimeterEnergyTrajectory.id !== trajectory) return perimeterEnergyTrajectory;
              return {
                ...perimeterEnergyTrajectory,
                perimeter_consumptions: perimeterEnergyTrajectory.perimeter_consumptions.map(perimeterConsumption => {
                  if (perimeterConsumption.id !== perimeterConsumptionId) return perimeterConsumption;
                  const asset_consumptions = addOrUpdateAssetConsumption(perimeterConsumption.asset_consumptions, data);
                  return {
                    ...perimeterConsumption,
                    asset_consumptions,
                  };
                }),
              };
            }),
          };
        }),
      };
    });
    return this.offlineService
      .setPerimeterEnergyTrajectories(updatedEnergyTrajectory)
      .pipe(tap(() => this.perimetersEnergyTrajectories.next(updatedEnergyTrajectory)));
  }

  energyTrajectoryForMultiPerimeters$(perimeterId: number): Observable<MultiPerimeterEnergyTrajectory> {
    return this.perimetersEnergyTrajectories$.pipe(
      filter(pets => !!pets),
      map(pets => pets.find(pet => pet.multi_perimeter_id === perimeterId))
    );
  }

  energyTrajectoryForMonoPerimeter$(
    multiPerimeterId: number,
    monoPerimeterId: number
  ): Observable<PerimeterEnergyTrajectory[]> {
    return this.energyTrajectoryForMultiPerimeters$(multiPerimeterId).pipe(
      filter(multiPerim => !!multiPerim),
      filter(multiPerime => !!multiPerime.perimeters),
      map(ETMulti => ETMulti.perimeters.filter(perim => perim.id === monoPerimeterId)),
      filter(ETMonoList => !!ETMonoList),
      map(ETMonoList => ETMonoList.reduce((acc, val) => acc.concat(val.trajectories), []))
    );
  }

  /**
   * Safely fetch the newest version of PerimetersEnergyTrajectory[]
   * and save it locally.
   * It is ensured to have empty the PendingChanges
   */
  fetchPerimetersEnergyTrajectoryAndSaveLocally(): Observable<boolean> {
    return this.syncService.pushOfflineChanges().pipe(
      switchMap(allChangesProcessed => {
        if (!allChangesProcessed) {
          return of(false);
        }
        return this.fetchEnergyTrajectoryForAllPerimeters().pipe(
          tap(energyTrajectory => this.perimetersEnergyTrajectories.next(energyTrajectory)),
          switchMap(energyTrajectory => this.offlineService.setPerimeterEnergyTrajectories(energyTrajectory))
        );
      })
    );
  }

  private fetchEnergyTrajectoryForAllPerimeters(): Observable<MultiPerimeterEnergyTrajectory[]> {
    const url = "/initiatives/api/energy-consumption/";
    return this.backendService.get<MultiPerimeterEnergyTrajectory[]>(url);
  }

  /**
   * Go to server and fetch the latest version of the Initiative for the given site
   * @param siteId
   */
  public fetchSiteInitiatives(siteId: number): Observable<Initiative[]> {
    let fields = [
      "id",
      "status",
      "savings_start_at",
      "label",
      "investment",
      "theoretical_savings_full_year",
      "theoretical_greenhouse_gas_savings_full_year",
      "theoretical_savings_first_year",
      "theoretical_greenhouse_gas_savings_first_year",
      "emission_category",
      "energy_type",
      "assets",
      "initiative_type",
      "gains",
    ].join(",");
    const url = `/structures/api/sites/${siteId}/initiatives-list/?fields=${fields},`;
    return this.configService.showInitiatives$.pipe(
      filter(showInitiatives => showInitiatives),
      switchMap(() => this.backendService.get<Initiative[]>(url)),
      tap(initiatives => {
        const subject = this.initiativeStore.get(siteId);
        if (!subject) {
          const setter = new BehaviorSubject<Initiative[]>(initiatives);
          const getter = setter.asObservable();
          this.initiativeStore.set(siteId, [setter, getter]);
        } else {
          const [setter, _] = subject;
          setter.next(initiatives);
        }
      })
    );
  }

  public getInitiativeOnline(initiativeId: number): Observable<Initiative> {
    const url = `/initiatives/api/initiatives/${initiativeId}/`;
    return this.configService.showInitiatives$.pipe(
      filter(showInitiatives => showInitiatives),
      switchMap(() => this.backendService.get(url))
    );
  }

  private _refreshSiteInitiatives$ = new BehaviorSubject<void>(undefined);
  public refreshSiteInitiatives(): void {
    this._refreshSiteInitiatives$.next(undefined);
  }
  public getSiteInitiatives(siteId: number): Observable<Initiative[]> {
    return this._refreshSiteInitiatives$.pipe(switchMap(() => this.fetchSiteInitiatives(siteId)));
  }

  // Get the initiatives that are linked to this one (mutual investment)
  public getLinkedInitiatives(siteId: number, currentInitiative: Initiative) {
    const [_, getter] = this.getLocalStore(siteId);
    return getter.pipe(
      map(initiatives =>
        initiatives.filter(
          initiative =>
            initiative.id !== currentInitiative.id && currentInitiative.investment?.id === initiative.investment?.id
        )
      )
    );
  }

  public getInitiatives(siteId: number): Observable<Initiative[]> {
    const [_, getter] = this.getLocalStore(siteId);
    return getter;
  }

  private getLocalStore(siteId: number): TLocalStore<Initiative> {
    const localStore = this.initiativeStore.get(siteId);
    if (localStore) {
      return localStore;
    }
    console.warn("Initiatives for site hasn't been fetched. Go to fetch it first!");
    const setter = new BehaviorSubject<Initiative[]>([]);
    const getter = setter.asObservable();
    return [setter, getter];
  }

  public getInitiative(siteId: number, initiativeId: number): Observable<Initiative> {
    const [_, getter] = this.getLocalStore(siteId);
    return getter.pipe(map(initiatives => initiatives.find(initiative => initiative.id === initiativeId)));
  }

  public getAssetInitiatives(siteId: number, assetId: number): Observable<Initiative[]> {
    return this.getSiteInitiatives(siteId).pipe(
      map(initiatives => initiatives.filter(initiative => initiative.assets.find(asset => asset.id === assetId)))
    );
  }

  public getInvestInitiatives(siteId: number, investmentId: number): Observable<Initiative[]> {
    return this.getSiteInitiatives(siteId).pipe(
      map(initiatives => initiatives.filter(initiative => initiative.investment?.id === investmentId))
    );
  }

  // We have to show "-" when fields are null, but for some fields, the backend
  // won't allow it, so we switch the value for 0 before sending the data.
  private replaceNullFieldsWithZero(formValues: Object): Object {
    let replacedValues = {};
    for (const [fieldName, value] of Object.entries(formValues)) {
      if (fieldsThatCantBeNull.includes(fieldName) && value === null) {
        replacedValues[fieldName] = 0;
      } else {
        replacedValues[fieldName] = formValues[fieldName];
      }
    }
    return replacedValues;
  }

  public patchInitiative(initiativeId: number, changedFields: any) {
    const url = `/initiatives/api/initiatives/${initiativeId}/`;
    if ("savings_start_at" in changedFields) {
      changedFields = {
        ...changedFields,
        savings_start_at: new Date(changedFields["savings_start_at"]).toISOString().split("T")[0],
      };
    }
    const formattedFields = this.replaceNullFieldsWithZero(changedFields);
    return new Observable(observer => {
      this.backendService.patch(url, formattedFields).subscribe(
        result => {
          observer.next(result);
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );
    });
  }

  public deleteInitiative(initiativeId: number) {
    const url = `/initiatives/api/initiatives/${initiativeId}/`;
    return this.backendService.postDelete(url);
  }

  public createInitiative(initiative: Initiative) {
    const url = `/initiatives/api/initiatives/`;
    const payload = {
      ...initiative,
      emission_category: initiative.emission_category ? initiative.emission_category.id : null,
      // perimeter: (<any>initiative.perimeter).id,
      savings_start_at: new Date(initiative.savings_start_at).toISOString().split("T")[0],
    };
    const formattedFields = this.replaceNullFieldsWithZero(payload);
    return this.backendService.post(url, formattedFields);
  }

  public getInitiativeInvestmentSlices(investment: InitiativeInvestment) {
    return investment.slices.filter(slice => {
      const sliceStatusId = typeof slice.status === "number" ? slice.status : slice.status.id;
      return sliceStatusId === investment.status;
    });
  }
}

import * as moment from "moment";

export interface PurchaseUser {
  id: number;
  site: number;
}

export class PurchaseFieldChoice {
  constructor(
    public id: number,
    public code: string,
    public label: string,
    public parent: number,
    public parents: number[],
    public investmentParent: string,
    public onlyForSite: number
  ) {}

  public getLabel(showFullLabel?: boolean): string {
    if (showFullLabel) {
      return this.code + " " + this.label;
    } else {
      return this.label || this.code;
    }
  }
}

enum FieldFormat {
  STRING = 0,
  NUMERIC = 1,
}

export class PurchaseField {
  constructor(
    public id: number,
    public code: string,
    public label: string,
    public isRequired: boolean,
    public valueFormat: FieldFormat,
    public parent: number,
    public investmentParentField: string,
    public hiddenIfNoChoices: boolean,
    public filterWidget: boolean,
    public naiParent: boolean,
    public showFullLabel: boolean,
    public choices: PurchaseFieldChoice[]
  ) {}

  public getLabel(): string {
    return this.label || this.code;
  }

  public isNumeric(): boolean {
    return this.valueFormat === FieldFormat.NUMERIC;
  }
}

export function makePurchaseField(jsonElt: any): PurchaseField {
  const choices = jsonElt.choices || [];
  return new PurchaseField(
    jsonElt.id || 0,
    jsonElt.code || "",
    jsonElt.label || "",
    !!jsonElt.is_required,
    jsonElt.value_format,
    jsonElt.parent || 0,
    jsonElt.investment_parent_field || "",
    !!jsonElt.hidden_if_no_choice,
    !!jsonElt.filter_widget,
    !!jsonElt.nai_parent,
    !!jsonElt.show_full_label,
    choices.map((choiceElt: any) => {
      return new PurchaseFieldChoice(
        choiceElt.id,
        choiceElt.code,
        choiceElt.label,
        choiceElt.parent || 0,
        choiceElt.parents ? choiceElt.parents : [],
        choiceElt.investment_parent || "",
        choiceElt.only_for_site || 0
      );
    })
  );
}

export class PurchaseRequestFieldValue {
  constructor(public field: number, public value: any) {}
}

export class PurchaseRequestAuthor {
  constructor(public id: number, public firstName: string, public lastName: string, public email: string) {}

  public fullName(): string {
    if (this.firstName || this.lastName) {
      return this.firstName + " " + this.lastName;
    } else {
      return this.email;
    }
  }
}

export class PurchaseRequest {
  constructor(
    public id: number,
    public title: string,
    public description: string,
    public createdBy: PurchaseRequestAuthor | null,
    public createdOn: Date,
    public purchaseOn: Date | string,
    public price: number,
    public quantity: number,
    public unit: string,
    public comments: string,
    public autoApprove: boolean,
    public acked: boolean,
    public confirmed: boolean,
    public fieldValues: PurchaseRequestFieldValue[],
    public autoSend: boolean
  ) {}

  public createdOnStr(): string {
    return moment(this.createdOn).format("ll");
  }

  public getFieldChoiceValue(field: PurchaseField): PurchaseFieldChoice | null {
    for (const fieldValue of this.fieldValues) {
      if (fieldValue.field === field.id) {
        for (const choice of field.choices) {
          if (choice.id === +fieldValue.value) {
            return choice;
          }
        }
      }
    }
    return null;
  }

  public getFieldValue(field: PurchaseField): string {
    for (const fieldValue of this.fieldValues) {
      if (fieldValue.field === field.id) {
        return fieldValue.value;
      }
    }
    return "";
  }

  private getFieldIndex(field: PurchaseField): number {
    let found = -1;
    for (let index = 0; index < this.fieldValues.length; index++) {
      const fieldValue = this.fieldValues[index];
      if (fieldValue.field === field.id) {
        found = index;
        break;
      }
    }
    return found;
  }

  public setFieldChoiceValue(field: PurchaseField, choice: PurchaseFieldChoice | null) {
    if (field.choices.length) {
      let found = this.getFieldIndex(field);
      if (found >= 0) {
        this.fieldValues.splice(found, 1);
      }
      this.fieldValues.push(new PurchaseRequestFieldValue(field.id, choice.id));
      this.fieldValues = [].concat(this.fieldValues);
    }
  }

  public setFieldValue(field: PurchaseField, value: string) {
    let found = this.getFieldIndex(field);
    if (found >= 0) {
      this.fieldValues.splice(found, 1);
    }
    this.fieldValues.push(new PurchaseRequestFieldValue(field.id, value));
    this.fieldValues = [].concat(this.fieldValues);
  }
}

export function makePurchaseRequest(jsonElt: any): PurchaseRequest {
  const fieldValues = jsonElt.field_values || [];
  let author = null;
  if (jsonElt.created_by) {
    author = new PurchaseRequestAuthor(
      jsonElt.created_by.id,
      jsonElt.created_by.first_name,
      jsonElt.created_by.last_name,
      jsonElt.created_by.email
    );
  }
  let purchaseDate = jsonElt.purchase_on;
  if (!purchaseDate) {
    purchaseDate = initPurchaseDate();
  }
  return new PurchaseRequest(
    jsonElt.id || 0,
    jsonElt.title || "",
    jsonElt.description || "",
    author,
    jsonElt.created_on || null,
    purchaseDate,
    jsonElt.price || 0,
    jsonElt.quantity || 0,
    jsonElt.unit || "",
    jsonElt.comments || "",
    !!jsonElt.auto_approve,
    !!jsonElt.acked,
    !!jsonElt.confirmed,
    fieldValues.map(elt => {
      return new PurchaseRequestFieldValue(elt.field || 0, elt.string_value || "");
    }),
    jsonElt.auto_send
  );
}

export function initPurchaseDate() {
  // Default date is today + 3 months
  const today = new Date();
  let defaultDay = today.getDate();
  let defaultMonth = today.getMonth();
  let defaultYear = today.getFullYear();
  for (let i = 3; i >= 0; i--) {
    if (defaultMonth === 12) {
      defaultMonth = 0;
      defaultYear++;
    }
    defaultMonth++;
  }
  const defaultDate = "" + defaultYear + "-" + defaultMonth + "-" + defaultDay;
  return defaultDate;
}

import { Injectable } from "@angular/core";
import { fileInfo, Picture } from "@structs";
import { File as CFile } from "@awesome-cordova-plugins/file/ngx";
import { defer, Observable } from "rxjs/";
import { HttpClient } from "@angular/common/http";
import * as R from "ramda";
import { DataURL } from "@structs/types";
import { Data } from "@angular/router";

const DEFAULT_THUMBNAIL_SIZE = {
  width: 75,
  height: 100,
};

@Injectable({
  providedIn: "root",
})
export class ImagesService {
  constructor(private file: CFile, private http: HttpClient) {}

  generateThumbnail(picture): Observable<DataURL> {
    return defer(() => <Promise<DataURL>>this.resizePicture(picture, DEFAULT_THUMBNAIL_SIZE));
  }

  /**
   * Generate thumbnail for a picture
   * @param picture Picture
   * @param boundBox { width: number, height: number }
   * @param outputFormat "blob" | "base64" use base64 to directly render to image, use blob to get binary object
   */
  async resizePicture(
    picture: Picture,
    boundBox: { width: number; height: number },
    outputFormat: "blob" | "base64" = "base64"
  ) {
    const pictureContent = await this._fileContentFromPicture(picture);
    return this._resizeFromFileContent(pictureContent, boundBox, outputFormat);
  }

  private async _fileContentFromPicture(picture: Picture): Promise<File | Blob> {
    if (!R.isEmpty(picture.browserFile)) return Promise.resolve(picture.browserFile);
    if (!R.isEmpty(picture.localPath)) {
      const { directory, filename } = fileInfo(picture.localPath);
      const data = await this.file.readAsArrayBuffer(directory, filename);
      return new Blob([data]);
    }
  }

  /**
   * Thanks to: https://stackoverflow.com/questions/16500848/how-to-generate-a-thumbnail-image-after-adding-an-image-inside-an-input-type-fi
   * @param file
   * @param boundBox
   * @param outputFormat "base64" | "blob"
   * @private
   */
  async _resizeFromFileContent(
    file: File | Blob,
    boundBox: { width: number; height: number } = DEFAULT_THUMBNAIL_SIZE,
    outputFormat: "base64" | "blob" = "base64"
  ): Promise<Blob | DataURL> {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Context not available");

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onerror = () => {
        throw new Error("Image not available");
      };
      img.onload = () => {
        const scaleRatio = Math.min(...Object.values(boundBox)) / Math.max(img.width, img.height);
        const width = img.width * scaleRatio;
        const height = img.height * scaleRatio;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        if (outputFormat === "blob") {
          canvas.toBlob(blob => resolve(blob));
        } else {
          resolve(<DataURL>canvas.toDataURL());
        }
      };
      img.src = URL.createObjectURL(file);
    });
  }

  private static dataURLToBlob(dataurl: string): Blob {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
}

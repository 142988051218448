import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Perimeter } from "../../../structs/assets";
import { ModalController, NavController } from "@ionic/angular";
import { Router } from "@angular/router";
import { PicturesViewerComponent } from "../../pictures-viewer/pictures-viewer.component";
import { Picture } from "@structs";
import { ScopeService } from "@services/scope.service";
import { PerimetersService } from "@services/perimeters.service";
import { Subscription } from "rxjs";

export interface PerimeterElement {
  perimeter: Perimeter;
  perimeterTypeName: string;
  hasLevelChildren: boolean;
}

@Component({
  selector: "app-monoperimeterlist",
  templateUrl: "./monoperimeterlist.component.html",
  styleUrls: ["./monoperimeterlist.component.scss"],
})
// Act as MonoPerimetersListPage in Ionic3
export class MonoPerimeterListComponent implements OnInit, OnDestroy {
  public mainPerimeter: Perimeter = null;
  public searchText: string = "";
  public searchedPerimeters: PerimeterElement[] = [];

  private perimeterTypesNamesMap: Map<number, string> = new Map<number, string>();
  public pictureEmpty: Picture = new Picture(-1, null, null, null, null, null, null);

  public collapsedMultiPerimeterIds: number[] = [];
  @ViewChild("monoPerimeterList") private monoPerimeterList;
  private subscriptions: Subscription[] = [];
  @Input() public modalMode: boolean = false;
  @Input() public selectedMonoPerimeterId: number | string = null;

  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private scope: ScopeService,
    private router: Router,
    private perimetersService: PerimetersService
  ) {}

  public ngOnInit() {}

  public ionViewDidEnter(): void {
    // Refresh perimeter
    this.getMainPerimeter();
  }

  public monoPerimeterClicked(monoPerimeter: Perimeter) {
    if (this.modalMode) {
      this.modalCtrl.dismiss({ monoPerimeter });
    } else {
      this.showPerimeter(monoPerimeter);
    }
  }

  async showPerimeter(monoPerimeter: Perimeter): Promise<void> {
    await this.router.navigate([
      "perimeter",
      "mono-perimeters",
      monoPerimeter.id > 0 ? monoPerimeter.id : monoPerimeter.localId,
    ]);
  }

  public getPerimeterTypeName(monoPerimeter): string {
    let perimeterTypeName = this.perimeterTypesNamesMap.get(monoPerimeter.perimeterType) || "";
    return perimeterTypeName;
  }

  public addPerimeter(): void {
    this.router.navigate(["mono-perimeter-add"]);
  }

  public searchPerimeters(): void {
    this.monoPerimeterList.searchPerimeters();
  }

  public showEnergyConsumptionPage() {
    this.router.navigate(["energy-consumption", this.mainPerimeter.id]);
  }

  public async pictureClicked(perimeter) {
    let options = {
      perimeter,
      pictureOnly: true,
      type: "perimeter",
    };
    let assetsModal = await this.modalCtrl.create({
      component: PicturesViewerComponent,
      componentProps: {
        options,
      },
    });
    await assetsModal.present();
  }

  // go to multi perimeter page
  public goToSitePage() {
    this.navCtrl.navigateBack(["perimeter"]);
  }

  public toggleMultiPerimeterChildren(perimeter: Perimeter): void {
    if (perimeter && !perimeter.level_parent) {
      const isCollapsed = this.collapsedMultiPerimeterIds.includes(perimeter.id);
      if (isCollapsed) {
        this.collapsedMultiPerimeterIds = this.collapsedMultiPerimeterIds.filter(id => id !== perimeter.id);
      } else {
        this.collapsedMultiPerimeterIds.push(perimeter.id);
      }
    }
  }

  public isCollapsed(perimeterId: number): boolean {
    return this.collapsedMultiPerimeterIds.includes(perimeterId);
  }

  private getMainPerimeter() {
    this.scope.getCurrentMultiPerimeter().subscribe(
      (perimeter: Perimeter) => {
        if (perimeter) {
          this.mainPerimeter = perimeter;
        } else {
          window.location.href = "/";
        }
      },
      () => {
        window.location.href = "/";
      }
    );
  }

  public deletePerimeter(monoPerimeter: Perimeter): void {
    const childrenPerimeters = this.mainPerimeter.sub_perimeters.filter(subPerimeter => {
      return subPerimeter.level_parent === monoPerimeter.id;
    });
    this.subscriptions.push(
      this.perimetersService.deletePerimeter(this.mainPerimeter, monoPerimeter, childrenPerimeters).subscribe(done => {
        this.getMainPerimeter();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe);
  }

  public backButtonClicked() {
    this.modalCtrl.dismiss();
  }
}

import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AuditQuestion, NoteEvent } from "../../../structs/audit";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "range-note",
  templateUrl: "./range-note.component.html",
})
export class RangeNoteComponent implements OnInit {
  public level_to_display: {};

  @Input() rangeValue: number = null;
  @Input() question: AuditQuestion = null;
  @Input() readOnly: boolean = false;

  @Output() clicked: EventEmitter<NoteEvent> = new EventEmitter<NoteEvent>();

  constructor(protected translate: TranslateService) {}

  public ngOnInit(): void {
    if (this.question.items.length) {
      this.rangeValue = this.question.items[0].current_note;
    } else {
      console.log("APP_CONFIGURATION_ERROR", {
        kpi: this.question,
        error: "KPI has no items",
      });
    }

    let level_dead = "";
    let level_critical = "";
    let level_warning = "";
    let level_acceptable = "";
    let level_good = "";
    let level_new = "";

    this.translate.get("Dead").subscribe(title => {
      level_dead = title;
    });
    this.translate.get("Critical").subscribe(title => {
      level_critical = title;
    });
    this.translate.get("Deteriorated").subscribe(title => {
      level_warning = title;
    });
    this.translate.get("Acceptable").subscribe(title => {
      level_acceptable = title;
    });
    this.translate.get("Good condition").subscribe(title => {
      level_good = title;
    });
    this.translate.get("New").subscribe(title => {
      level_new = title;
    });

    this.level_to_display = {};
    this.level_to_display[1] = level_dead;
    this.level_to_display[2] = level_critical;
    this.level_to_display[3] = level_warning;
    this.level_to_display[4] = level_acceptable;
    this.level_to_display[5] = level_good;
    this.level_to_display[6] = level_new;
  }

  public noteChanged(): void {
    this.clicked.emit({
      itemId: this.question.items[0].id,
      note: this.rangeValue,
    });
  }

  public showHelpText(event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}

import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { from, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { AppUser } from "../structs/base";
import { KPISubscription } from "../structs/subscription";
import { UsersService } from "./users.service";

@Injectable()
export class KPISubscriptionService {
  constructor(private authService: AuthService, private usersService: UsersService) {}

  // Shortcuts function to find if a person can access a subscription
  canAccess$(kpiSubscription: keyof KPISubscription): Observable<boolean> {
    return this.getCurrentAppUserObject$().pipe(map(user => user[kpiSubscription]));
  }

  get allAccesses$(): Observable<KPISubscription> {
    return this.getCurrentAppUserObject$().pipe(
      map(appUser => {
        return {
          can_access_insight: appUser.can_access_insight,
          can_access_decision: appUser.can_access_decision,
          can_access_initiatives: appUser.can_access_initiatives,
          can_access_reconciliation: appUser.can_access_reconciliation,
          can_access_management: appUser.can_access_management,
          can_access_energy: appUser.can_access_energy,
        };
      })
    );
  }

  /* Get AppUser information from current User
   * @notice: This method creates query to the local DB
   */
  private getCurrentAppUserObject$(): Observable<AppUser> {
    return from(this.authService.getCurrentUser()).pipe(
      filter(user => !!user),
      switchMap(user => this.usersService.getUserFromId(user.get_user_id)),
      filter(user => !!user)
    );
  }
}

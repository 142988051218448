import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, LoadingController, ModalController, NavController, NavParams } from "@ionic/angular";
import { forkJoin } from "rxjs";

import { AssetEditService } from "../../services/asset-edit.service";
import { Asset, AssetPicture, PerimeterPicture, Perimeter } from "../../structs/assets";
import { Picture } from "../../structs/base";
import { Investment, InvestmentPicture } from "../../structs/investments";
import { InvestmentPicturesService } from "../../services/investment-pictures.service";
import { PerimeterPicturesService } from "../../services/perimeter-pictures.service";
import { PerimetersService } from "../../services/perimeters.service";
import { ScopeService } from "../../services/scope.service";
import { MONO_PERIMETER_OBJECT, SuccessToastService } from "../../services/success-toast.service";
import { OPEN_PICTURE_FROM } from "../../structs/picture";
import { AuditService } from "../../services/audit.service";
import { AuditQuestion } from "../../structs/audit";
import { OfflineService } from "../../services/offline.service";
import { ErrorsService } from "../../services/errors.service";

@Component({
  selector: "app-picture-detail",
  templateUrl: "./picture-detail.component.html",
  styleUrls: ["./picture-detail.component.scss"],
})
export class PictureDetailComponent implements OnInit {
  public asset: Asset;
  public investment: Investment;
  public perimeter: Perimeter = this.params.get("perimeter");
  public picture: Picture = this.params.get("picture");
  public readOnly: boolean = true;
  public title: string = "";
  public origin: OPEN_PICTURE_FROM = null;
  private question: AuditQuestion = null;
  private setDefaultImageText: string;
  private removeDefaultImageText: string;
  public inProgress = false;
  // TODO
  public isDefaultCoverImage: boolean = false;

  constructor(
    private alertCtrl: AlertController,
    private assetEditService: AssetEditService,
    private investmentPicturesService: InvestmentPicturesService,
    private params: NavParams,
    private translate: TranslateService,
    private perimeterPicturesService: PerimeterPicturesService,
    private perimetersService: PerimetersService,
    private scope: ScopeService,
    private successToast: SuccessToastService,
    private auditService: AuditService,
    private offlineApi: OfflineService,
    private errorsApi: ErrorsService,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController
  ) {}

  public ngOnInit(): void {
    this.asset = this.params.get("asset");
    this.readOnly = this.params.get("readOnly");
    this.perimeter = this.params.get("perimeter");
    this.origin = this.params.get("origin");
    this.question = this.params.get("question") || null;
    this.investment = this.params.get("investment");
    forkJoin([
      this.translate.get("This image is now the default image"),
      this.translate.get("This image is no longer the default image"),
    ]).subscribe(([setDefaultImageText, removeDefaultImageText]) => {
      this.setDefaultImageText = setDefaultImageText;
      this.removeDefaultImageText = removeDefaultImageText;
    });
  }

  public ionViewDidEnter(): void {
    if (this.perimeter && this.picture) {
      this.isDefaultCoverImage =
        (this.picture.id > 0 && this.picture.id === this.perimeter.cover_image) ||
        (this.picture.localId && this.picture.localId === this.perimeter.cover_image);
    }
  }

  public ionViewWillLeave(): void {}

  public deletePicture(): void {
    if (this.readOnly) {
      return;
    }

    forkJoin(
      this.translate.get("Confirmation"),
      this.translate.get("Do you want to delete the current picture?"),
      this.translate.get("Ok"),
      this.translate.get("Cancel")
    ).subscribe(async ([confirmTitle, confirmMessage, okButton, cancelButton]) => {
      const confirm = await this.alertCtrl.create({
        header: confirmTitle,
        message: confirmMessage,
        backdropDismiss: false,
        buttons: [
          {
            text: cancelButton,
            role: "cancel",
          },
          {
            text: okButton,
            handler: async () => {
              if (this.investment) {
                const invPicture: InvestmentPicture = {
                  ...this.picture,
                  investment: this.investment.id,
                };
                this.investmentPicturesService
                  .deleteInvestmentPicture(invPicture, this.investment, this.asset)
                  .subscribe(() => {
                    this.modalCtrl.dismiss();
                  });
              } else if (this.perimeter) {
                const perimeterPicture: PerimeterPicture = {
                  ...this.picture,
                  perimeter: this.perimeter.id,
                };
                this.inProgress = true;
                const loading = await this.loadingCtrl.create({
                  spinner: "dots",
                  message: "",
                });
                loading.present();
                this.perimeterPicturesService.deletePerimeterPicture(perimeterPicture, this.perimeter).subscribe(
                  () => {
                    this.modalCtrl.dismiss();
                    this.inProgress = false;
                    loading.dismiss();
                  },
                  err => {
                    this.errorsApi.signalError(err);
                    this.inProgress = false;
                    loading.dismiss();
                  }
                );
              } else if (this.origin == "AUDIT_EXPERT") {
                // Add an API call to the sync Queue for deleting this picture
                this.auditService.deleteAuditQuestionPicture(this.picture).subscribe(() => {
                  // Update the local asset with new list of pictures for this KPI without the deleted picture
                  const kpiId = this.question.items[0].id;
                  let indexOf = -1;
                  const kpiPictures = this.asset.notesPictures[kpiId];
                  for (let index = 0; index < kpiPictures.length; index++) {
                    const pic = kpiPictures[index];
                    if (
                      (pic.id > 0 && pic.id === this.picture.id) ||
                      (pic.localId && pic.localId === this.picture.localId)
                    ) {
                      indexOf = index;
                      break;
                    }
                  }
                  if (indexOf >= 0) {
                    kpiPictures.splice(indexOf, 1);
                  } else {
                    console.log("--> kpiPicture not found", this.picture, kpiPictures);
                  }
                  this.asset.notesPictures[kpiId] = kpiPictures;
                  // The picture has gone : store the new asset
                  this.offlineApi.storeAsset(this.asset).subscribe(
                    asset => {},
                    () => {},
                    () => {
                      this.modalCtrl.dismiss();
                    }
                  );
                });
              } else {
                const assetPicture: AssetPicture = {
                  ...this.picture,
                  asset: this.asset.id,
                };
                this.assetEditService.deletePicture(assetPicture);
                this.modalCtrl.dismiss();
              }
            },
          },
        ],
      });
      await confirm.present();
    });
  }

  makePerimeterCover(obj?: { pictureId: number; pictureLocalId: string }): void {
    const { pictureId, pictureLocalId } = obj || {
      pictureId: null,
      pictureLocalId: null,
    };
    this.perimetersService
      .savePerimeterCoverImage({
        perimeter: this.perimeter,
        pictureId,
        pictureLocalId,
      })
      .subscribe(() => {
        if (pictureId || pictureLocalId) {
          this.isDefaultCoverImage = true;
          return this.successToast.showSuccessToast(MONO_PERIMETER_OBJECT, this.setDefaultImageText);
        }
        this.isDefaultCoverImage = false;
        return this.successToast.showSuccessToast(MONO_PERIMETER_OBJECT, this.removeDefaultImageText);
      });
  }

  public backButtonClicked() {
    this.modalCtrl.dismiss();
  }
}

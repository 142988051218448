<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <app-back-button *ngIf="!modalMode"></app-back-button>
      <ion-button *ngIf="modalMode" (click)="backButtonClicked()">
        <i class="fa-solid fa-circle-chevron-left fa-back"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ "Perimeters" | translate }}</ion-title>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar
      [(ngModel)]="searchText"
      (ionInput)="searchPerimeters()"
      placeholder="{{ 'Filter perimeters' | translate }}"
      (ionClear)="searchPerimeters()"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-mono-perimeter-list-segment
    #monoPerimeterList
    [mainPerimeter]="mainPerimeter"
    [searchText]="searchText"
    (monoPerimeterClicked)="monoPerimeterClicked($event)"
    (pictureClickedEv)="pictureClicked($event)"
    (deleteMonoPerimeterEv)="deletePerimeter($event)"
    [selectedMonoPerimeterId]="selectedMonoPerimeterId"></app-mono-perimeter-list-segment>

  <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="!mainPerimeter?.read_only && !this.modalMode">
    <ion-fab-button color="mono-perimeter" (click)="addPerimeter()">
      <ion-text color="light">
        <i class="fal fa-2x fa-plus"></i>
      </ion-text>
    </ion-fab-button>
  </ion-fab>
</ion-content>

import { from as observableFrom, Observable, combineLatest } from "rxjs";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { Asset } from "../structs/assets";
import { AuditSynthesis } from "../structs/audit";
import { SCAN_BAR_CODE, SCAN_QR_CODE } from "../structs/base";
import { ErrorsService } from "./errors.service";
import { OfflineService } from "./offline.service";
import { ScopeService } from "./scope.service";

@Injectable()
export class ScanAndSearchService {
  private loading: boolean = false;

  constructor(
    private errors: ErrorsService,
    private translate: TranslateService,
    private offlineApi: OfflineService,
    private scope: ScopeService
  ) {}

  public searchAsset(codeType: number, code: string): Observable<Asset[]> {
    return new Observable(observer => {
      if (code) {
        combineLatest(this.scope.getCurrentMultiPerimeter(), this.scope.getSynthesisYear()).subscribe(
          ([perimeter, year]) => {
            this.offlineApi.getAuditSynthesis(perimeter, year).subscribe(
              (auditSynthesis: AuditSynthesis) => {
                let matchingAssets = [];
                observableFrom(auditSynthesis.assets).subscribe(
                  (asset: Asset) => {
                    if (asset) {
                      if (codeType === SCAN_QR_CODE && asset.qrcode === code) {
                        matchingAssets.push(asset);
                      }
                      if (codeType === SCAN_BAR_CODE && asset.barcode === code) {
                        matchingAssets.push(asset);
                      }
                    }
                  },
                  () => {
                    // keep silent
                  },
                  () => {
                    observer.next(matchingAssets);
                    observer.complete();
                  }
                );
              },
              err => {
                observer.error(err);
                observer.complete();
              }
            );
          }
        );
      } else {
        observer.next([]);
        observer.complete();
      }
    });
  }
}

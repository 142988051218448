import { Injectable } from "@angular/core";
import * as moment from "moment";
import { models } from "@kpi-intelligence/asset-lifecycle";

import { AssetsService } from "./assets.service";

export interface SuggestedLifetime {
  maxSuggestedLifetime: number;
  minSuggestedLifetime: number;
}

@Injectable()
export class ConsistencyTestService {
  private model: models.BaseModel;
  private marginConsistencyTest: number = 0.15;
  private notationAlphaCoefficient: number = 4;

  constructor(private assetService: AssetsService) {
    this.assetService.getNotationAlphaCoefficient().subscribe(notationAlphaCoefficient => {
      if (notationAlphaCoefficient) {
        this.notationAlphaCoefficient = notationAlphaCoefficient;
      }
      this.model = new models.StandardModel(this.notationAlphaCoefficient);
    });

    this.assetService.getAppSettings().subscribe(settings => {
      if (settings.margin_consistency_technical_state_lifetime) {
        this.marginConsistencyTest = Number(settings.margin_consistency_technical_state_lifetime);
      }
    });
  }

  public getCurrentTheoreticalNote(
    currentYear: number,
    auditYear: number,
    auditNote: number,
    installationYear: number,
    lifetime: number
  ): number {
    const note = this.model.futureNote(currentYear, auditYear, auditNote, installationYear, lifetime);
    return note;
  }

  /**
   * Verify if the couple technical state - lifetime is consistent
   * Display an alert if the data are inconsistents
   * Return suggested max-min remaining lifetime if it's inconsistent null either
   *
   * @param year the audit year
   * @param installationYear the asset installation year
   * @param note the new note
   * @param lifetime the asset lifetime
   * @param inputLifetime the new lifetime
   * @param age :
   */
  public areTechnicalStateAndLifetimeConsistent(
    year: number,
    installationYear: number,
    note: number,
    lifetime: number,
    inputLifetime: number,
    age: number = 0,
    alphaCoefficient?: number
  ): SuggestedLifetime {
    if (alphaCoefficient) {
      this.model = new models.StandardModel(alphaCoefficient);
    }

    const estimatedLifetime = this.model.estimatedLifetime(
      year,
      note,
      installationYear,
      installationYear,
      100,
      lifetime,
      0
    );

    // If the lifetime is NaN (equivalent to infinite value in the model) we stop the consistency test
    if (estimatedLifetime != NaN) {
      const maxEstimatedLifetime = Math.round((1 + this.marginConsistencyTest) * estimatedLifetime);
      const minEstimatedLifetime = Math.round((1 - this.marginConsistencyTest) * estimatedLifetime);
      const isConsistent = maxEstimatedLifetime >= inputLifetime && minEstimatedLifetime <= inputLifetime;
      if (!isConsistent) {
        const maxSuggestedLifetime = maxEstimatedLifetime - age;
        const minSuggestedLifetime = Math.max(0, minEstimatedLifetime - age);
        return {
          maxSuggestedLifetime,
          minSuggestedLifetime,
        };
      }
    }
    return null;
  }

  public getEstimatedRemainingLifetime(
    year: number,
    installationYear: number,
    note: number,
    lifetime: number,
    alphaCoefficient?: number
  ): number {
    if (alphaCoefficient) {
      this.model = new models.StandardModel(alphaCoefficient);
    }
    const value = this.model.estimatedLifetime(year, note, installationYear, installationYear, 100, lifetime, 0);
    const assetAge = year - installationYear;
    return Math.round(value - assetAge);
  }
}

/**
 * Definition of a object containing a change for a field
 */
export class ChangeItem {
  constructor(
    public fieldName: string = "",
    public oldValue: any = null,
    public newValue: any = null,
    public objectId: number = null,
    public objectType: string = null
  ) {}
}

/**
 * Definition of an object regrouping the changes made by a user at a defined date
 */
export class HistoryItem {
  constructor(public user: number = null, public date: Date = null, public changes: ChangeItem[] = []) {}
}

/**
 * Definition of the history of an object
 */
export class HistoryObj {
  constructor(public historyItems: HistoryItem[] = []) {}
}

/**
 * Serialize json in ChangeItem
 *
 * @param jsonChange
 */
export function makeChangeItem(jsonChange: any): ChangeItem {
  let changeItem: ChangeItem = null;
  if (jsonChange) {
    changeItem = new ChangeItem(
      jsonChange.field_name,
      jsonChange.old,
      jsonChange.new,
      jsonChange.object_id,
      jsonChange.object_type
    );
  }
  return changeItem;
}

/**
 * Serialize json in HistoryItem
 *
 * @param jsonHistoryItem
 */
export function makeHistoryItem(jsonHistoryItem: any): HistoryItem {
  let historyItem: HistoryItem = null;
  if (jsonHistoryItem) {
    const changes: ChangeItem[] = [];
    for (const change of jsonHistoryItem.changes) {
      changes.push(makeChangeItem(change));
    }
    historyItem = new HistoryItem(jsonHistoryItem.user, new Date(jsonHistoryItem.date), changes);
  }
  return historyItem;
}

/**
 * Serialize json in HistoryObj
 *
 * @param jsonHistoryObj
 */
export function makeHistoryObj(jsonHistoryObj: any): HistoryObj {
  let historyObj: HistoryObj = null;
  if (jsonHistoryObj) {
    const historyItems: HistoryItem[] = [];
    for (const historyItem of jsonHistoryObj.history_items) {
      historyItems.push(makeHistoryItem(historyItem));
    }
    historyObj = new HistoryObj(historyItems);
  }
  return historyObj;
}

/**
 * From an array of changes extract distinct object ids
 *
 * @param changes the array of ChangeItems
 */
export function getDistinctObjectIdsFromChangeItems(changes: ChangeItem[]): number[] {
  const distinctIds: number[] = [];
  const flags: { [key: number]: boolean } = {};
  for (const change of changes) {
    if (flags[change.objectId]) continue;
    flags[change.objectId] = true;
    distinctIds.push(change.objectId);
  }
  return distinctIds;
}

import { tap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { deburr } from "lodash";
import lunr from "lunr";
import lunrStemmer from "lunr-languages/lunr.stemmer.support";
import { Observable, of } from "rxjs";

import { I18nService } from "./i18n.service";
import { OfflineService } from "./offline.service";

lunrStemmer(lunr);

/**
 * Deburrs a Token, i.e. remove all diacritics (`é -> e`, `ç -> c`...).
 *
 * @param token The token to deburr.
 *
 * @returns The deburred token.
 */
const deburrToken = (token: lunr.Token): lunr.Token => {
  return token.update(s => deburr(s));
};

lunr.Pipeline.registerFunction(deburrToken, "deburrToken");

/**
 * Custom Lunr plugin to apply `deburrToken` function
 * during the index and search pipeline.
`*/
const deburrPlugin = (language?: string) => (builder: lunr.Builder) => {
  const stemmer = language ? lunr[language].stemmer : lunr.stemmer;
  builder.pipeline.before(stemmer, deburrToken);
  builder.searchPipeline.before(stemmer, deburrToken);
};

@Injectable()
export class FullTextSearchService {
  private indices: { [id: string]: lunr.Index } = {};

  constructor(private i18nService: I18nService, private offlineApi: OfflineService) {}

  /**
   * Configures a lunr index with our plugins.
   *
   * @param builder The lunr builder context.
   */
  public configureLunrIndex(builder: lunr.Builder): void {
    const language = this.i18nService.getLanguage();

    const languagePlugin = lunr[language];
    if (languagePlugin) {
      builder.use(languagePlugin);
      builder.use(deburrPlugin(language));
    } else {
      builder.use(deburrPlugin());
    }
  }

  /**
   * Loads a saved index from storage.
   *
   * @param key The key of the index.
   *
   * @returns An `Observable` resolving to a Lunr index.
   */
  public loadIndex(key: string): Observable<lunr.Index> {
    const loadedIndex = this.indices[key];
    if (loadedIndex) {
      return of(loadedIndex);
    }

    return this.offlineApi.getItem(key, true).pipe(
      map(serializedIndex => lunr.Index.load(serializedIndex)),
      tap(index => (this.indices[key] = index))
    );
  }

  /**
   * Performs a search on a specific index.
   *
   * @param indexKey The key of the index.
   * @param query The user query.
   *
   * @returns A list of matching object ids ordered by relevance.
   */
  public search(indexKey: string, query: string): Observable<number[]> {
    return this.loadIndex(indexKey).pipe(
      map(index => index.search(query)),
      map(results => results.map(result => Number(result.ref)))
    );
  }

  /**
   * Saves a built index into the storage.
   *
   * @param key The key of the index.
   * @param index The index to save.
   *
   * @returns A `void` Observable.
   */
  public saveIndex(key: string, index: lunr.Index): Observable<void> {
    this.indices[key] = index;

    return this.offlineApi.storeItem(key, index.toJSON());
  }
}

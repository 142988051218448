import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { OfflineService } from "@services";
import { InvestmentsService } from "@services/investments.service";
import { ScopeService } from "@services/scope.service";
import { Asset, Investment, Perimeter, setLocalStorageItem } from "@structs";
import { combineLatest, Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-price-sheets-list",
  templateUrl: "./price-sheets-list.page.html",
  styleUrls: ["./price-sheets-list.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PriceSheetsListPage implements OnInit, OnDestroy {
  @Input() public investment: Investment;
  @Input() public assetTypeId: number;
  @Input() public assetId: number;
  @Input() modalMode: boolean = false;
  private subscriptions: Subscription[] = [];
  public loading: boolean = true;
  public asset: Asset;
  @Input() public addMode: boolean = false;
  private navExtrasState = null;
  public fromAsset: boolean;

  constructor(
    private investmentsApi: InvestmentsService,
    private route: ActivatedRoute,
    private router: Router,
    private scope: ScopeService,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    private offlineApi: OfflineService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.route.queryParams.subscribe(() => {
        // We have to save the navigationExtras.state right away, because
        // this.router.getCurrentNavigation() is null if we try to put it in
        // the combineLatest
        this.navExtrasState = this.router.getCurrentNavigation()?.extras.state;
      }),
      combineLatest([this.scope.getCurrentMultiPerimeter(), this.route.params]).subscribe(
        ([multiPerimeter, params]) => {
          if (params["assetTypeId"]) {
            this.assetTypeId = parseInt(params["assetTypeId"]);
            if (!params["investmentId"] && params["assetId"]) {
              this.assetId = parseInt(params["assetId"]);
              this.fromAsset = true;
              this.loadAssetWithoutInvestment(this.assetId);
            }
          }
          if (!this.modalMode) {
            this.addMode = !!params["addMode"];
          }

          if (params["investmentId"] && !this.addMode) {
            this.getInvestmentById(params["investmentId"], multiPerimeter).subscribe(investment => {
              this.investment = investment;
              this.fromAsset = false;
              if (this.investment.assetId) {
                this.loadAsset();
              }
              this.loading = false;
            });
          } else {
            // we're creating a new investment
            if (this.navExtrasState) {
              this.investment = this.navExtrasState.investment;
              this.asset = this.navExtrasState.asset;
            }
            this.loading = false;
          }
        },
        err => {
          this.loading = false;
          console.error(err);
        }
      )
    );
  }

  private getInvestmentById(investId: string, multiPerimeter: Perimeter): Observable<Investment> {
    return this.investmentsApi.getPerimeterInvestments(multiPerimeter, true).pipe(
      map(investments => {
        if (investId === "0") {
          return null;
        }
        const investment = investments.find(investment => {
          if (investId.toString().includes(".")) {
            // Offline invest, we have a local id
            return investment.localId === investId;
          } else {
            return investment.id.toString() === investId;
          }
        });
        this.investment = investment;
        return investment;
      })
    );
  }

  private loadAsset() {
    this.offlineApi.loadAsset(this.investment.assetId).subscribe(
      asset => {
        this.asset = asset;
      },
      err => {
        console.warn("*loadAsset", this.asset);
      }
    );
  }

  private loadAssetWithoutInvestment(assetId: number) {
    this.offlineApi.loadAsset(assetId).subscribe(
      asset => {
        this.asset = asset;
      },
      err => {
        console.warn("*loadAsset", this.asset);
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // Should be developped in #6018
  /* async addPriceSheet() {
    const noRightsAlert = await this.alertCtrl.create({
      header: this.translate.instant("Add a price sheet"),
      message: this.translate.instant("You do not currently have the rights"),
      buttons: ["Ok"],
    });
    await noRightsAlert.present();
  } */
}

import { Component, OnInit, EventEmitter, Input, Output, NgZone, OnDestroy } from "@angular/core";

import { Observable, Observer, Subscription } from "rxjs";

import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-footer-next-button",
  templateUrl: "./next-button.component.html",
  styleUrls: ["./next-button.component.scss"],
})
export class NextButtonComponent implements OnInit, OnDestroy {
  public cancelLabel: string = "";
  public pauseLabel: string = "";
  public displayedNextLabel: string = "";
  public isAddAssetLabel: boolean = false;

  eventCompleted: Observable<boolean> = null;
  eventCompletedObserver: Observer<boolean> = null;
  eventCompletedSubscription: Subscription = null;

  private nextLabel: string = "";
  private saveLabel: string = "";
  private okLabel: string = "";
  private validateLabel: string = "";
  private addAssetLabel: string = "";

  @Input() color: string = "asset";
  @Input() label: string = "";
  @Input() enabled: boolean = false;
  @Input() inProgress: boolean = false;
  @Input() hasCancel: boolean = false;
  @Input() hasPause: boolean = false;
  @Input() cancelEnabled: boolean = false;
  @Input() labelChangedEvent: EventEmitter<any> = null;

  @Output() next: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() pause: EventEmitter<any> = new EventEmitter<any>();
  @Output() disabledClicked: EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService, private zone: NgZone) {
    this.translate
      .get(["Next", "Save", "OK", "Validate", "Cancel", "Pause", "Add the asset"])
      .subscribe(translations => {
        this.nextLabel = translations["Next"];
        this.saveLabel = translations["Save"];
        this.okLabel = translations["OK"];
        this.validateLabel = translations["Validate"];
        this.cancelLabel = translations["Cancel"];
        this.pauseLabel = translations["Pause"];
        this.addAssetLabel = translations["Add the asset"];
      });
  }

  ngOnInit() {
    this.eventCompleted = Observable.create(eventCompletedObserver => {
      this.eventCompletedObserver = eventCompletedObserver;
    });
    this.eventCompletedSubscription = this.eventCompleted.subscribe(() => {
      this.inProgress = false;
      this.displayedNextLabel = this.getLabel();
    });
    this.displayedNextLabel = this.getLabel();
    if (this.labelChangedEvent) {
      this.labelChangedEvent.subscribe(value => {
        this.label = value;
        this.displayedNextLabel = this.getLabel();
      });
    }
  }

  ngOnDestroy() {
    this.eventCompletedSubscription.unsubscribe();
  }

  public clickNext(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.enabled && !this.inProgress) {
      this.inProgress = true;
      this.next.emit(this.eventCompletedObserver);
      setTimeout(() => {
        // Little hack because the next button can stay disabled forever due to this variable
        this.inProgress = false;
      }, 600);
    }
  }

  public clickCancel(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.inProgress) {
      this.inProgress = true;
      this.cancel.emit(this.eventCompletedObserver);
    }
  }

  public clickPause(event): void {
    event.preventDefault();
    event.stopPropagation();
    if (!this.inProgress) {
      this.pause.emit(this.eventCompletedObserver);
    }
  }

  // We need to get the click even if the button is disabled
  footerClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.enabled) {
      this.disabledClicked.emit();
    }
  }

  private getLabel(): string {
    switch (this.label) {
      case "save":
        return this.saveLabel;
      case "ok":
        return this.okLabel;
      case "validate":
        return this.validateLabel;
      case "addAsset":
        this.isAddAssetLabel = true;
        return this.addAssetLabel;
      case "modifyPurchaseRequest":
        return this.translate.instant("Modify Purchase Request");
      case "createPurchaseRequest":
        return this.translate.instant("Create Purchase Request");
      case "addAction":
        return this.translate.instant("Add the action");
      case "add":
        return this.translate.instant("Add");
      case "next":
        return this.nextLabel;
      default:
        return this.label;
    }
  }
}

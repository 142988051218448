import { InvestmentStatusTransition } from "./investments";
import { KPISubscription } from "./subscription";

export interface NamedValue {
  name: string;
  value: number;
}

export class NameAndValue {
  constructor(public value: number, public name: string) {}
}

export class Month {
  constructor(public value: number, public name: string) {}
}

export class YesNo {
  constructor(public value: number, public name: string) {}
}

export class Amount {
  constructor(public value: number, public name: string) {}
}

export class Picture {
  constructor(
    public id: number,
    public picture: string,
    public thumbnail: string,
    public user: number,
    public datetime: string,
    public localId: string,
    public author: string,
    public localPath?: string,
    public browserFile?: File
  ) {}
}

export class AppUser implements KPISubscription {
  constructor(
    /** Id. of the TeamMember. */
    public id: number,
    public name: string,
    /** Id. of the User. */
    public get_user_id?: number,
    public email?: string,
    public first_name?: string,
    public last_name?: string,
    public uuid?: string,
    public corporate?: number,
    public corporate_name?: string,
    public can_access_insight?: boolean,
    public can_access_decision?: boolean,
    // @deprecated
    public can_access_initiatives?: boolean,
    public can_access_reconciliation?: boolean,
    public can_access_management?: boolean,
    public can_access_administration?: boolean,
    public can_access_energy?: boolean
  ) {}
}

export class MembersGroup {
  constructor(
    public id: number,
    public name: string,
    /** IDs. of the team members in the group */
    public members: number[],
    /** List of allowed investment status transitions for this group */
    public allowedInvestmentStatusTransitions: InvestmentStatusTransition[]
  ) {}
}

export function makeMembersGroup(jsonData: any): MembersGroup {
  let allowedInvestmentStatusTransitions: InvestmentStatusTransition[] = [];

  if (jsonData.allowed_investment_status_transitions) {
    allowedInvestmentStatusTransitions = jsonData.allowed_investment_status_transitions.map(elt => {
      return {
        fromStatus: elt.from_status,
        toStatus: elt.to_status,
      };
    });
  }

  return new MembersGroup(jsonData.id, jsonData.name, jsonData.members, allowedInvestmentStatusTransitions);
}

export class AppSettings {
  constructor(
    public enable_asset_validation_rules: boolean,
    public enable_ownership_types: boolean,
    public allow_on_hold_investments: boolean,
    public margin_consistency_technical_state_lifetime: number,
    public enable_asset_affiliation: boolean,
    public enable_automatic_parentship: boolean
  ) {}
}

export class AppRelease {
  constructor(
    public id: number,
    public version: string,
    public platform: string,
    public url: string,
    public notes: string,
    // will be set when the version is checked
    public isMajorUpgrade: boolean = false,
    public isMinorUpgrade: boolean = false
  ) {}
}

export function makePicture(jsonData: any): Picture {
  return new Picture(
    jsonData.id,
    jsonData.picture,
    jsonData.thumbnail,
    jsonData.user,
    jsonData.datetime,
    jsonData.local_id,
    jsonData.author
  );
}

export interface ImportableMixin<T> {
  importedAt?: Date;
  importSource?: string;
  importReference?: string;
  importStatuses?: T[];
}

export const SCAN_QR_CODE: number = 1;
export const SCAN_BAR_CODE: number = 2;

export function getBarcodeScannerOptions(codeType: number, prompt: string): any {
  return {
    preferFrontCamera: false,
    showFlipCameraButton: true,
    showTorchButton: true,
    torchOn: false,
    prompt: prompt,
    resultDisplayDuration: 500,
    formats: codeType === SCAN_QR_CODE ? "QR_CODE" : "CODE_39,EAN_13",
    orientation: "portrait",
  };
}

<ion-list *ngIf="mainPerimeter" lines="full">
  <ng-container *ngFor="let perimeterElt of searchedPerimeters">
    <ion-item-sliding
      #item
      *ngIf="!perimeterElt.perimeter.level_parent || isCollapsed(perimeterElt.perimeter.level_parent)">
      <ion-item color="{{ isSelected(perimeterElt.perimeter) ? 'selected-blue' : null }}">
        <ion-label class="perimeter">
          <!-- Thumbnail picture -->
          <div
            class="picture"
            *ngIf="perimeterElt.perimeter.level_parent"
            (click)="pictureClicked($event, perimeterElt.perimeter)">
            <app-picture
              [picture]="
                perimeterElt.perimeter.pictures.length > 0 ? perimeterElt.perimeter.pictures[0] : pictureEmpty
              "></app-picture>
          </div>

          <div
            [ngClass]="{
              'chevron-button-container': !perimeterElt.perimeter.level_parent
            }">
            <ion-buttons *ngIf="!perimeterElt.perimeter.level_parent && perimeterElt.hasLevelChildren">
              <ion-button class="chevron-button" (click)="toggleMultiPerimeterChildren(perimeterElt.perimeter)">
                <i class="fal fa-chevron-right" *ngIf="!isCollapsed(perimeterElt.perimeter.id)"></i>
                <i class="fal fa-chevron-down" *ngIf="isCollapsed(perimeterElt.perimeter.id)"></i>
              </ion-button>
            </ion-buttons>
          </div>

          <div class="info" (click)="selectPerimeter(perimeterElt.perimeter)">
            <div class="text-overflow-ellipsis cropped-label">
              <span class="text-style5">
                {{ perimeterElt.perimeter.name }}
              </span>
            </div>
            <div class="text-style1 text-overflow-ellipsis">
              {{ perimeterElt.perimeterTypeName }}
            </div>
          </div>
        </ion-label>
      </ion-item>
      <ion-item-options>
        <ion-item-option
          *ngIf="!perimeterElt.perimeter.level_parent"
          (click)="addMonoPerimeter(item, perimeterElt.perimeter)"
          color="mono-perimeter"
          class="add-perimeter">
          <i class="fal fa-plus"></i>
        </ion-item-option>
        <ion-item-option color="primary" (click)="showMonoPerimeter(item, perimeterElt.perimeter)">
          <i class="fal fa-file"></i>
        </ion-item-option>
        <ion-item-option (click)="deletePerimeter(item, perimeterElt)" color="delete">
          <i class="fal fa-trash-alt"></i>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ng-container>
</ion-list>

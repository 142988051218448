import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs/operators";
import { PicturesLoaderService } from "src/app/services/pictures-loader.service";
import { Picture } from "src/app/structs/base";

@Component({
  selector: "app-picture",
  templateUrl: "./app-picture.component.html",
  styleUrls: ["./app-picture.component.scss"],
})
export class AppPictureComponent implements OnInit, OnChanges {
  @Input() public picture: Picture;
  @Input() public picturesOn: boolean = true;
  // Use thumbnail picture instead of original picture
  @Input() thumbnail = true;
  // used for image viewer page
  @Input() selected = false;
  // used for site pics only
  @Input() isSitePic = false;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public error = new EventEmitter<void>();

  public readonly zoomOptions = {
    zoom: {
      maxRatio: 2,
    },
  };

  private defaultThumbnail: string =
    "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgOTYgOTYwIDk2MCIgd2lkdGg9IjQ4Ij48cGF0aCBkPSJtODQzIDEwMjgtOTItOTJIMTgwcS0yNC43NSAwLTQyLjM3NS0xNy42MjVUMTIwIDg3NlYzMDVsLTgyLTgyIDQzLTQzIDgwNSA4MDUtNDMgNDNaTTE4MCA4NzZoNTExTDU3MSA3NTZIMjQwbDEyMC0xNDAgODAgMTA5IDQ2LTU1LTMwNi0zMDZ2NTEyWm02NjAtMjItNjAtNjBWMjc2SDI2MmwtNjAtNjBoNTc4cTI0Ljc1IDAgNDIuMzc1IDE3LjYyNVQ4NDAgMjc2djU3OFpNNTI0IDUzOFptLTg4IDgzWiIvPjwvc3ZnPg==')";
  private picture$: BehaviorSubject<Picture>;

  /** Stocking picture URLS, including: thumbnail and full picture */
  public url$: Observable<{ thumbnail: SafeUrl; fullPicture: SafeUrl }>;

  constructor(private picturesLoaderService: PicturesLoaderService, private sanitizer: DomSanitizer) {
    this.picture$ = new BehaviorSubject<Picture>(this.picture);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.picture) this.picture$.next(this.picture);
  }

  ngOnInit(): void {
    this.url$ = this.picture$.pipe(
      filter(picture => !!picture),
      distinctUntilChanged(),
      switchMap(picture => {
        return combineLatest([
          this.picturesLoaderService.getThumbnailPictureURL(picture, true),
          this.picturesLoaderService.getFullPictureURL(picture),
        ]).pipe(
          map(([thumbnail, fullPicture]) => {
            return {
              thumbnail: this.safeStyle(thumbnail),
              isDefaultThumbnail: this.isSitePic ? (!thumbnail ? true : false) : null,
              fullPicture: this.safeUrl(fullPicture),
            };
          })
        );
      })
    );
  }

  private safeUrl(url?: string): SafeUrl {
    if (!url) return;
    if (url.indexOf("file:///") === 0) url = (window as any).Ionic.WebView.convertFileSrc(url);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private safeStyle(url: string): SafeUrl {
    if (!url) return this.sanitizer.bypassSecurityTrustStyle(this.defaultThumbnail);
    if (url.indexOf("file:///") === 0) url = (window as any).Ionic.WebView.convertFileSrc(url);
    return this.sanitizer.bypassSecurityTrustStyle(`url("${url}")`);
  }

  public backupThumbnail({ el, fullPicture }) {
    const backupUrl = fullPicture?.changingThisBreaksApplicationSecurity;
    if (!backupUrl) return;
    if (!el.target || el.target.src === backupUrl) return;
    el.target.src = backupUrl;
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
// import { File } from "@ionic-native/file";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { FileOpener } from "@awesome-cordova-plugins/file-opener/ngx";
import { Platform } from "@ionic/angular";
import { Observable } from "rxjs";
import { map, delay } from "rxjs/operators";
import { Environment } from "../app.environment";
import { ErrorsService } from "./errors.service";
import { BrowserService } from "./browser.service";

@Injectable()
export class ExportsService {
  private inBrowser: boolean = this.browserService.inBrowser();

  constructor(
    private errorsService: ErrorsService,
    private file: File,
    private fileOpener: FileOpener,
    private http: HttpClient,
    private platform: Platform,
    private browserService: BrowserService
  ) {}

  public downloadAssetsExport(authString: string, params?: any): Observable<void> {
    return this.downloadExport("/assets/api/assets/export/", authString, params);
  }

  private downloadExport(endpoint: string, authString: string, params?: any): Observable<void> {
    const headers = new HttpHeaders({ Authorization: authString });
    const getObservable = this.http.get(`${Environment.getBackendHost()}${endpoint}`, {
      responseType: "blob",
      observe: "response",
      headers,
      params,
    });
    if (this.inBrowser) {
      return getObservable.pipe(
        map(response => {
          const contentDisposition = response.headers.get("Content-Disposition");
          const fileName = contentDisposition.split("filename=")[1].replace(/"/g, "");

          const blob = new Blob([response.body], {
            type: response.headers.get("content-type"),
          });

          const downloadURL = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadURL;
          a.download = fileName;
          a.click();

          return downloadURL;
        }),
        delay(100),
        map(downloadURL => window.URL.revokeObjectURL(downloadURL))
      );
    } else {
      return getObservable.pipe(
        map(response => {
          const contentDisposition = response.headers.get("Content-Disposition");
          const fileName = contentDisposition.split("filename=")[1].replace(/"/g, "");
          const blob = new Blob([response.body], {
            type: response.headers.get("content-type"),
          });

          let pathFile: string;

          if (this.platform.is("android")) {
            pathFile = this.file.externalDataDirectory;
          } else {
            pathFile = this.file.documentsDirectory;
          }

          this.file.writeFile(pathFile, fileName, blob).then(
            file => {
              this.fileOpener
                .open(file.nativeURL, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                .then(
                  () => {},
                  err => this.errorsService.signalError(err)
                );
            },
            err => this.errorsService.signalError(err)
          );
        })
      );
    }
  }
}

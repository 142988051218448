<ion-row class="ion-justify-content-center ion-margin-vertical ion-padding-vertical">
  <ion-col size="6" class="ion-text-center ion-margin-vertical">
    <i class="fa-light fa-wifi fa-3x"></i>
  </ion-col>
</ion-row>
<ion-row class="ion-justify-content-center">
  <ion-col size="11" class="ion-text-center">
    {{ "Wait for the network to come back before accessing this menu" | translate }}
  </ion-col>
</ion-row>

import { Injectable } from "@angular/core";
// import { File } from '@ionic-native/file';
import { File } from "@awesome-cordova-plugins/file/ngx";
import { Storage } from "@ionic/storage";
import { Observable, ReplaySubject, combineLatest, from, of } from "rxjs";
import { map } from "rxjs/operators";

import { VERSION } from "../app.version";
import { VersionsService } from "./versions.service";
import { BackendService } from "./backend.service";
import { Events } from "./events.service";

export interface Settings {
  version: string;
  lastRelease: string;
  online: boolean;
  offlineStorageSize: number;
  freeDiskSpace: number;
  backendHost: string;
  synchroEnabled: boolean;
  lastHelloDelay: number;
  storageType: string;
  dataDirectory: string;
}

@Injectable()
export class SettingsService {
  private offlineStorageSize: number = 0;
  public toggleSynchro$ = new ReplaySubject<boolean>(1);

  constructor(
    private file: File,
    private backend: BackendService,
    private versionsService: VersionsService,
    private storage: Storage,
    public events: Events
  ) {}

  public getAppSettings(): Observable<Settings> {
    return combineLatest(
      this.versionsService.getLastReleaseVersion(),
      this.getFreeDiskSpace(),
      this.initOfflineStorageSize()
    ).pipe(
      map(([release, freeDiskSpace]) => {
        return {
          version: VERSION,
          lastRelease: release ? release.version : "---",
          online: this.backend.getConnected(),
          offlineStorageSize: this.offlineStorageSize,
          freeDiskSpace,
          backendHost: this.backend.getBackendHost(),
          synchroEnabled: this.backend.getHelloTimeout() > 0,
          lastHelloDelay: this.backend.getLastHelloDelay(),
          storageType: this.storage.driver ? this.storage.driver : "---",
          dataDirectory: this.file.dataDirectory,
        };
      })
    );
  }

  public getLastHelloDelay() {
    return this.backend.getLastHelloDelay();
  }

  public toggleSynchro(synchroEnabled: boolean): void {
    this.backend.setHelloTimeout(synchroEnabled ? 500 : 0);
    this.events.publish("toggleSynchro", synchroEnabled);
    this.toggleSynchro$.next(synchroEnabled);
  }

  private initOfflineStorageSize(): Observable<void> {
    this.offlineStorageSize = 0;
    return from(
      this.storage.forEach((value: string) => {
        this.offlineStorageSize += value.length;
      })
    );
  }

  private getFreeDiskSpace(): Observable<number> {
    if (!this.isWeb) {
      return from(this.file.getFreeDiskSpace());
    }
    return of(0);
  }

  private isWeb(): boolean {
    let platform = this.versionsService.getPlatform();
    return platform != "ios" && platform != "android";
  }
}

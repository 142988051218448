import { AssetsService } from "@services";
// import { TasksService } from '../services/tasks.service';
import { TasksService } from "../services/tasks.service";
import { AssetType, Category, SubCategory, Perimeter, Zone, Asset, SuperCategory } from "./assets";
import { AuditState } from "./audit";
import {
  Investment,
  InvestmentCategory,
  InvestmentPriority,
  InvestmentReason,
  InvestmentStatus,
  InvestmentType,
} from "./investments";
import { MappedTask, TaskStateDisplay } from "./tasks";

interface ValueName<T> {
  value: T;
  name: string;
}

export abstract class SearchFilter<T> {
  /**
   * This field indicates that the inherited Filter class is (single/multiple) values filter
   */
  multiple = true;

  public selectedValues: T[] = [];
  public availableValuesLabels: string[];

  constructor(public label: string, public availableValues: T[]) {
    this.availableValuesLabels = this.getAvailableValuesLabels();
  }

  public abstract getPredicate();

  protected abstract getAvailableValuesLabels(): string[];

  public updateAvailableValues(availableValues: T[]): void {
    this.clearSelected();
    this.availableValues = availableValues;
    this.availableValuesLabels = this.getAvailableValuesLabels();
  }

  public hasSelected(): boolean {
    return this.selectedValues.length > 0;
  }

  public clearSelected(): void {
    this.selectedValues = [];
  }
}

export namespace AssetSearch {
  export abstract class AssetSearchFilter<T> extends SearchFilter<T> {
    public abstract getPredicate(): (element: Asset) => boolean;
  }

  export class PerimeterSearchFilter extends AssetSearchFilter<Perimeter> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value =>
            element.building.id === value.building_id ||
            element.children.some((child: Asset) => child.building.id === value.building_id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class SuperCategorySearchFilter extends AssetSearchFilter<SuperCategory> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value =>
            value == null ||
            value.id === element.category.parent ||
            element.children.some((child: Asset) => child.category.parent === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class CategorySearchFilter extends AssetSearchFilter<Category> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value =>
            value == null ||
            value.id === element.category.id ||
            element.children.some((child: Asset) => child.category.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class SubCategorySearchFilter extends AssetSearchFilter<SubCategory> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value =>
            value == null ||
            value.id === element.subCategory.id ||
            element.children.some((child: Asset) => child.subCategory.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class AssetTypeSearchFilter extends AssetSearchFilter<AssetType> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value =>
            value == null ||
            value.id === element.assetType.id ||
            element.children.some((child: Asset) => child.assetType.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class ZoneSearchFilter extends AssetSearchFilter<Zone> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value => value.id === element.zone.id || element.children.some((child: Asset) => child.zone.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class RemainingLifetimeSearchFilter extends AssetSearchFilter<ValueName<number>> {
    public getPredicate() {
      const currentYear = new Date().getFullYear();
      return (element: Asset): boolean => {
        return this.selectedValues.some(value => {
          const remainingLifetime = element.installationYear + element.expectedLifetime - currentYear;
          return (
            remainingLifetime === value.value ||
            element.children.some((child: Asset) => {
              const childRemainingLifetime = child.installationYear + child.expectedLifetime - currentYear;
              return childRemainingLifetime === value.value;
            })
          );
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class EndOfLifeYearSearchFilter extends AssetSearchFilter<number> {
    public getPredicate() {
      return (element: Asset) =>
        this.selectedValues.some(value => {
          const eol = AssetsService.getAssetEndOfLife(element);
          return (
            eol === value ||
            element.children.some((child: Asset) => {
              const childEndOfLifeYear = child.installationYear + child.expectedLifetime;
              return childEndOfLifeYear === value;
            })
          );
        });
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => `${value}`);
    }
  }

  export class TechnicalStateSearchFilter extends AssetSearchFilter<AuditState> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(
          value => element.level === value.level || element.children.some((child: Asset) => child.level === value.level)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class HasInvestmentsSearchFilter extends AssetSearchFilter<ValueName<boolean>> {
    public getPredicate() {
      return (element: Asset): boolean => {
        return this.selectedValues.some(value => {
          return (
            (value.value ? element.investments.length > 0 : element.investments.length === 0) ||
            element.children.some((child: Asset) => {
              return value.value ? child.investments.length > 0 : child.investments.length === 0;
            })
          );
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class DisplayChildrenFilter extends AssetSearchFilter<ValueName<boolean>> {
    /* Only filter yes/no */
    multiple = false;

    public getPredicate() {
      return (element: Asset): boolean => {
        return true;
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class AssetStepSearchFilter extends AssetSearchFilter<ValueName<number>> {
    public getPredicate() {
      return (element: Asset) =>
        this.selectedValues.some(value => {
          return element.step === value.value;
        });
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }
}

export namespace InvestmentSearch {
  export interface InvestmentElement extends Investment {
    element?: Asset;
  }

  export abstract class InvestmentSearchFilter<T> extends SearchFilter<T> {
    public abstract getPredicate(): (investment: InvestmentElement) => boolean;
  }

  export class PerimeterSearchFilter extends InvestmentSearchFilter<Perimeter> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => {
          return (
            (investment.buildingId && investment.buildingId === value.building_id) ||
            (investment.element && investment.element.building.id === value.building_id)
          );
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class SuperCategorySearchFilter extends InvestmentSearchFilter<SuperCategory> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(
          value =>
            value == null ||
            investment.category?.parent === value.id ||
            investment.element?.category?.parent === value.id
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class CategorySearchFilter extends InvestmentSearchFilter<Category> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(
          value =>
            (investment.category && investment.category.id === value.id) ||
            (investment.element && investment.element.category.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class SubCategorySearchFilter extends InvestmentSearchFilter<Category> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(
          value =>
            (investment.subCategory && investment.subCategory.id === value.id) ||
            (investment.element && investment.element.subCategory.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class InvestmentReasonSearchFilter extends InvestmentSearchFilter<InvestmentReason> {
    /** This recursive method looks for an InvestmentReason match between an investment and
     * a given reason and all its children.
     */
    protected getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }

    getPredicate(): (investment: InvestmentSearch.InvestmentElement) => boolean {
      return (investment: InvestmentSearch.InvestmentElement): boolean => {
        return this.selectedValues.some(value => investment.reasons.some(reason => reason.id === value.id));
      };
    }
  }

  export class InvestmentCategorySearchFilter extends InvestmentSearchFilter<InvestmentCategory> {
    /** This recursive method looks for an investmentCategory match between an investment and
     * a given category and all its children.
     */
    private childrenContain(investment: Investment, value: InvestmentCategory): boolean {
      let found: boolean;
      if (investment.investmentCategory && investment.investmentCategory.id === value.id) {
        found = true;
      } else if (value.children && value.children.length > 0) {
        value.children.forEach(child => {
          if (this.childrenContain(investment, child)) {
            found = true;
          }
        });
      } else {
        found = false;
      }
      return found;
    }

    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => this.childrenContain(investment, value));
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class YearSearchFilter extends InvestmentSearchFilter<number> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => {
          return investment.slices.some(slice => slice.year === value);
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => `${value}`);
    }
  }

  export class PrioritySearchFilter extends InvestmentSearchFilter<InvestmentPriority> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => investment.priority && investment.priority.id === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class StatusSearchFilter extends InvestmentSearchFilter<InvestmentStatus> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => investment.status.id === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class ZoneSearchFilter extends InvestmentSearchFilter<Zone> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => investment.element && investment.element.zone.id === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class IsGlobalSearchFilter extends InvestmentSearchFilter<ValueName<boolean>> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => {
          return value.value ? investment.assetId === null : investment.assetId !== null;
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class InvestmentTypeSearchFilter extends InvestmentSearchFilter<InvestmentType> {
    public getPredicate() {
      return (investment: InvestmentElement): boolean => {
        return this.selectedValues.some(value => investment.investmentType?.id === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }
}

export namespace TaskSearch {
  export abstract class TaskSearchFilter<T> extends SearchFilter<T> {
    public abstract getPredicate(): (task: MappedTask) => boolean;
  }

  export class PerimeterSearchFilter extends TaskSearchFilter<Perimeter> {
    public getPredicate() {
      return (task: MappedTask): boolean => {
        return this.selectedValues.some(value => TasksService.isRelatedToPerimeter(task, value));
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class CategorySearchFilter extends TaskSearchFilter<Category> {
    public getPredicate() {
      return (task: MappedTask): boolean => {
        const asset = task.asset_object;
        const investment = task.investment_object;
        const categoryInvestment = investment && investment.category;
        return this.selectedValues.some(
          value =>
            (asset && asset.category.id === value.id) || (categoryInvestment && categoryInvestment.id === value.id)
        );
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class ZoneSearchFilter extends TaskSearchFilter<Zone> {
    public getPredicate() {
      return (task: MappedTask): boolean => {
        return this.selectedValues.some(value => task.asset_object && task.asset_object.zone.id === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class StateSearchFilter extends TaskSearchFilter<TaskStateDisplay> {
    public getPredicate() {
      return (task: MappedTask): boolean => {
        return this.selectedValues.some(value => task.state === value.id);
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }

  export class HasInvestmentsSearchFilter extends TaskSearchFilter<ValueName<boolean>> {
    public getPredicate() {
      return (task: MappedTask): boolean => {
        return this.selectedValues.some(value => {
          return value.value ? task.investment !== null : task.investment === null;
        });
      };
    }

    public getAvailableValuesLabels(): string[] {
      return this.availableValues.map(value => value.name);
    }
  }
}

import { Component, NgZone, OnInit } from "@angular/core";
import { AuthService } from "@services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { ErrorsService } from "@services/errors.service";
import { from, Observable, of } from "rxjs";
import { BackendService } from "@services/backend.service";
import { OfflineService, SynchronizationService } from "@services";
import { SynchronizationStatus } from "@structs/synchronization";
import { SynthesisService } from "@services/synthesis.service";
import { AppRelease, AuditSynthesisChange } from "@structs";
import { Router } from "@angular/router";
import { ConfigService } from "@services/config.service";
import { ScopeService } from "@services/scope.service";
import { CompabilityResolve, VersionsService } from "@services/versions.service";
import { VERSION } from "./app.version";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import { Deeplinks } from "@awesome-cordova-plugins/deeplinks/ngx";
import { catchError, filter, switchMap, tap } from "rxjs/operators";
import { PerimeterBudgetPlansService } from "@services/perimeter-budget-plan.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  defaultTitle: string = "Capex Intelligence";
  title: string = this.defaultTitle;
  version = "0.0.0";

  private isSynchroVerbose: boolean;
  private synchronizationStartedMessage: string = "";
  private hasSynchroError: boolean;
  private synchronizationDoneMessage: string;
  private synchronizationPostponedMessage: string;
  private synchronizationElementDeletedtMessage: string;

  constructor(
    private authApi: AuthService,
    private translate: TranslateService,
    private errors: ErrorsService,
    private network: Network,
    private backend: BackendService,
    private syncApi: SynchronizationService,
    private synthesis: SynthesisService,
    private offlineApi: OfflineService,
    private toastCtrl: ToastController,
    private router: Router,
    private config: ConfigService,
    private scope: ScopeService,
    private versionsApi: VersionsService,
    private deeplinks: Deeplinks,
    private zone: NgZone,
    private platform: Platform,
    private sync: SynchronizationService,
    private perimeterBudgetPlan: PerimeterBudgetPlansService,
    private alertCtrl: AlertController
  ) {
    this.translate.setDefaultLang("en");
    this.translate.use(translate.getBrowserLang());

    this.platform.ready().then(() => {
      this.setupDeeplink();
    });
  }

  ngOnInit() {
    this.network.onConnect().subscribe(event => {
      console.log("APP_NETWORK_CONNECTED", event);
      this.backend.setConnected(true);
      this.backend.setNetworkStatus(this.network.type);
      this.displayNetworkStatus(event);
      this.syncApi.signalNetworkReadyForSynchronization(this.network.type);
    });

    this.network.onDisconnect().subscribe(event => {
      console.log("APP_NETWORK_DISCONNECTED", event);
      this.backend.setConnected(false);
      this.backend.setNetworkStatus(this.network.type);
      this.syncApi.signalNetworkReadyForSynchronization("");
      this.displayNetworkStatus(event);
    });

    this.syncApi.watchSynchronizationState()?.subscribe(
      synchronizationState => {
        console.log("APP_SYNCHRONIZATION_STATE", synchronizationState);

        if (synchronizationState.status === SynchronizationStatus.PUSH) {
          console.log(">sync push");
          this.syncApi.pushOfflineChanges().subscribe(
            () => {
              // console.log(">sync push done");
            },
            err => {
              // console.error(">sync push error", err);
            }
          );
        }

        if (synchronizationState.status === SynchronizationStatus.VERBOSE) {
          this.isSynchroVerbose = true;
        }

        if (synchronizationState.status === SynchronizationStatus.STARTED) {
          // console.debug(">sync push started");
          if (this.isSynchroVerbose) {
            this.displaySynchronizationMessage(this.synchronizationStartedMessage, false, true, false);
          }
        }

        if (synchronizationState.status === SynchronizationStatus.DONE) {
          // console.debug(">sync push done", synchronizationState);
          if (this.hasSynchroError || this.isSynchroVerbose) {
            this.isSynchroVerbose = false;
            this.displaySynchronizationMessage(this.synchronizationDoneMessage, false, true, false);
          }
          this.hasSynchroError = false;
        }

        if (synchronizationState.status === SynchronizationStatus.POSTPONED) {
          // console.debug("sync push posponed", synchronizationState);
          this.hasSynchroError = true;
          if (this.isSynchroVerbose) {
            this.isSynchroVerbose = false;
            this.displaySynchronizationMessage(this.synchronizationPostponedMessage, false, true, false);
          }
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_TO_DO_ADDED) {
          console.debug(">sync push added", synchronizationState);
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_DONE) {
          // console.log(">sync push: change done", synchronizationState);
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_DELETED) {
          console.debug(">sync push: deleted", synchronizationState);
          if (this.isSynchroVerbose) {
            this.displaySynchronizationMessage(this.synchronizationElementDeletedtMessage, false, true, true);
          }
        }
      },
      err => {
        console.error(err);
      },
      () => {}
    );
    this.synthesis.watchSynthesis().subscribe(
      (synthesisChange: AuditSynthesisChange) => {
        // store assets and investments for offline Mode
        if (synthesisChange) {
          this.offlineApi.storeAuditElements(synthesisChange).subscribe(
            () => {},
            err => this.errors.signalError(err),
            () => {}
          );
        }
      },
      err => this.errors.signalError(err)
    );

    this.errors.watchErrors().subscribe(async (err: any) => {
      console.error("APP_GENERAL_ERROR", { error: err });

      if (err) {
        if (err.status && err.status === 401) {
          this.router.navigate(["/"]);
          // this.navCtrl.popToRoot();
        } else if (err.message !== "Offline") {
          const toast = await this.toastCtrl.create({
            message: err.message,
            duration: 3000,
            cssClass: err.offline ? "offline-toast" : "successToast audit",
            position: "bottom",
          });
          await toast.present();
        }
      }
    });

    this.authApi.initCurrentUser();
    this.network.onConnect().subscribe(event => {
      console.log("APP_NETWORK_CONNECTED", event);
      this.backend.setConnected(true);
      this.backend.setNetworkStatus(this.network.type);
      this.displayNetworkStatus(event);
      this.syncApi.signalNetworkReadyForSynchronization(this.network.type);
    });

    this.network.onDisconnect().subscribe(event => {
      console.log("APP_NETWORK_DISCONNECTED", event);
      this.backend.setConnected(false);
      this.backend.setNetworkStatus(this.network.type);
      this.syncApi.signalNetworkReadyForSynchronization("");
      this.displayNetworkStatus(event);
    });

    this.syncApi.watchSynchronizationState().subscribe(
      synchronizationState => {
        console.log("APP_SYNCHRONIZATION_STATE", synchronizationState);

        if (synchronizationState.status === SynchronizationStatus.PUSH) {
          console.log(">sync push");
          this.syncApi.pushOfflineChanges().subscribe(
            () => {
              // console.log(">sync push done");
            },
            err => {
              // console.error(">sync push error", err);
            }
          );
        }

        if (synchronizationState.status === SynchronizationStatus.VERBOSE) {
          this.isSynchroVerbose = true;
        }

        if (synchronizationState.status === SynchronizationStatus.STARTED) {
          // console.debug(">sync push started");
          if (this.isSynchroVerbose) {
            this.displaySynchronizationMessage(this.synchronizationStartedMessage, false, true, false);
          }
        }

        if (synchronizationState.status === SynchronizationStatus.DONE) {
          // console.debug(">sync push done", synchronizationState);
          if (this.hasSynchroError || this.isSynchroVerbose) {
            this.isSynchroVerbose = false;
            this.displaySynchronizationMessage(this.synchronizationDoneMessage, false, true, false);
          }
          this.hasSynchroError = false;
        }

        if (synchronizationState.status === SynchronizationStatus.POSTPONED) {
          // console.debug("sync push posponed", synchronizationState);
          this.hasSynchroError = true;
          if (this.isSynchroVerbose) {
            this.isSynchroVerbose = false;
            this.displaySynchronizationMessage(this.synchronizationPostponedMessage, false, true, false);
          }
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_TO_DO_ADDED) {
          console.debug(">sync push added", synchronizationState);
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_DONE) {
          // console.log(">sync push: change done", synchronizationState);
        }

        if (synchronizationState.status === SynchronizationStatus.CHANGE_DELETED) {
          console.debug(">sync push: deleted", synchronizationState);
          if (this.isSynchroVerbose) {
            this.displaySynchronizationMessage(this.synchronizationElementDeletedtMessage, false, true, true);
          }
        }
      },
      err => {
        console.log(err);
      },
      () => {}
    );

    this.synthesis.watchSynthesis().subscribe(
      (synthesisChange: AuditSynthesisChange) => {
        // store assets and investments for offline Mode
        if (synthesisChange) {
          this.offlineApi.storeAuditElements(synthesisChange).subscribe(
            () => {},
            err => this.errors.signalError(err),
            () => {}
          );
        }
      },
      err => this.errors.signalError(err)
    );

    this.errors.watchErrors().subscribe(async err => {
      console.error("APP_GENERAL_ERROR", { error: err });

      if (err) {
        if (err.status && err.status === 401) {
          this.router.navigate(["/"]);
        } else if (err.message !== "Offline") {
          const toast = await this.toastCtrl.create({
            message: err.message,
            duration: 3000,
            cssClass: err.offline ? "offline-toast" : "successToast audit",
            position: "bottom",
          });
          await toast.present();
        }
      }
    });

    this.versionsApi.checkCompatibility().subscribe(async resolved => {
      if (resolved === CompabilityResolve.nonSatisfies) {
        const alert = await this.alertCtrl.create({
          header: this.translate.instant("Error"),
          message: this.translate.instant(
            "This audit version is deprecated. Please install the newer version to continue."
          ),
          buttons: [
            {
              text: this.translate.instant("Contact support"),
              handler: () => false,
            },
          ],
          backdropDismiss: false,
        });
        await alert.present();
      }
    });

    // If we think an app-component is a shell to include all child component
    // we need to make sure the shell loads its content
    // loading the config will include: load the currency, i18n,...
    this.authApi
      .onCurrentUserChanged()
      .pipe(
        filter(user => !!user?.apiKey),
        tap(() => this.scope.clearPerimetersCache()),
        switchMap(() => this.config.loadConfig()),
        switchMap(() => this.checkVersion())
      )
      .subscribe(newRelease => {
        const updateMessage = this.translate.instant("A new version is available") + ": " + newRelease.version;
        this.toastCtrl
          .create({
            message: updateMessage,
            duration: 3000,
            position: "bottom",
            translucent: true,
          })
          .then(toast => toast.present());
      });
  }

  displayNetworkStatus(event) {
    // CAP-120: Don't display the toast control.
    // let message = event.type;
    // if (event.type === 'online') {
    //   message += ': ' + this.network.type;
    // }
    //
    // let toast = this.toastCtrl.create({
    //   message: message,
    //   duration: 3000,
    //   position: 'bottom'
    // });
    // // code left here in case we change our minds!
    // toast.present();
  }

  displaySynchronizationMessage(message: string, permanent: boolean, hidePermanent: boolean, showCloseButton: boolean) {
    // For the moment, we decide to hide the synchronization messages
    console.log(message, permanent);

    // if (hidePermanent && this.synchronizationProgressToast) {
    //   this.synchronizationProgressToast.dismiss();
    //   this.synchronizationProgressToast = null;
    // }
    //
    // if (permanent && this.synchronizationProgressToast) {
    //   return;
    // }
    //
    // let options: any = {
    //   message: message,
    //   cssClass: 'offline-toast',
    //   position: 'bottom'
    // };
    // if (!permanent && !showCloseButton) {
    //   options.duration = 5000;
    // }
    // if (showCloseButton) {
    //   options.showCloseButton = true;
    // }
    //
    // let toast = this.toastCtrl.create(options);
    // toast.present();
    // if (permanent) {
    //   this.synchronizationProgressToast = toast;
    // }
  }
  private checkVersion(): Observable<AppRelease> {
    return this.versionsApi.getLastReleaseVersion().pipe(
      filter((release: AppRelease) => !!release),
      switchMap((release: AppRelease) =>
        this.versionsApi.checkRelease(VERSION, release).pipe(filter((newRelease: AppRelease) => !!newRelease))
      )
    );
  }

  private catchError(err: any): Observable<null> {
    console.warn("Deeplink error: " + err);
    return of(null);
  }

  private setupDeeplink() {
    const deeplinkRoute$ = this.deeplinks.route({
      // mapping between deepllink and angular route
      "/perimeters/:multiPerimeterId/asset-detail/:assetId": "AssetDetail",
      "/perimeters/:multiPerimeterId/investment-detail/:investId": "InvestmentDetail",
    });

    deeplinkRoute$
      .pipe(
        filter(match => !!match),
        switchMap(match => {
          const route = match.$route;
          if (route === "AssetDetail") {
            return from(this.router.navigate([match.$link.path]));
          }
          if (route === "InvestmentDetail") {
            return from(this.router.navigate([match.$link.path]));
          }
        }),
        catchError(err => this.catchError(err))
      )
      .subscribe();
  }
}

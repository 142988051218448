import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Duration } from "../components/notations/other-notations/other-notations.component";
// import { Duration } from "../pages/asset-duration/asset-duration.component";

@Injectable()
export class AssetNotationService {
  private theoreticalLifetime: string = "";
  private theoreticalLifetimeLabel: string = "";
  private yearsLabel: string = "";
  private yearsLabelPlural: string = "";
  private endOfLifeYearLabel: string = "";

  constructor(private translate: TranslateService) {
    this.translate.get("year").subscribe((yearsLabel: string) => {
      this.yearsLabel = yearsLabel;
    });
    this.translate.get("years").subscribe((yearsLabelPlural: string) => {
      this.yearsLabelPlural = yearsLabelPlural;
    });
    this.translate.get("Theoretical lifetime").subscribe((theoreticalLifetimeLabel: string) => {
      this.theoreticalLifetimeLabel = theoreticalLifetimeLabel + ": ";
    });
    this.translate.get("theoretical lifetime").subscribe((theoreticalLifetime: string) => {
      this.theoreticalLifetime = theoreticalLifetime;
    });
  }

  /**
   * Creates duration list using the assetType
   *
   * @param assetType
   */
  createDurationsListFromExpectedDuration(expectedDuration: number): Duration[] {
    const durations: Duration[] = [];

    for (let i = expectedDuration; i > 0; i--) {
      durations.push(new Duration(-i, this.createDurationLabel(expectedDuration, -i)));
    }

    let theoreticalDuration = new Duration(0, this.createDurationLabel(expectedDuration, 0));
    durations.push(theoreticalDuration);

    for (let i = 1; i <= expectedDuration; i++) {
      durations.push(new Duration(i, this.createDurationLabel(expectedDuration, i)));
    }

    return durations;
  }

  /**
   * Creates duration label using the expectedDuration and the duration
   *
   * @param expectedDuration
   * @param duration
   */
  createDurationLabel(expectedDuration: number, duration: number): string {
    const yearsLabel = Math.abs(duration) > 1 ? this.yearsLabelPlural : this.yearsLabel;
    const durationTotal = expectedDuration + duration;
    const yearsTotalLabel = durationTotal > 1 ? this.yearsLabelPlural : this.yearsLabel;
    const extension = duration == 0 ? "" : " " + (duration > 0 ? "+" : "") + String(duration) + " " + yearsLabel;

    return String(durationTotal) + " " + yearsTotalLabel + " (" + this.theoreticalLifetime + extension + ")";
  }

  /**
   *
   * @param endOfLifeYear end of life year estimated after the investment
   * @param durationTotal
   */
  createEndOfLifeLabel(endOfLifeYear: number, durationTotal: number) {
    let totalLifeTimeLabel: string;
    this.translate.get("Total lifetime").subscribe(totalLifeTime => {
      totalLifeTimeLabel = totalLifeTime;
    });
    const yearsTotalLabel = durationTotal > 1 ? this.yearsLabelPlural : this.yearsLabel;
    return endOfLifeYear + " (" + totalLifeTimeLabel + " = " + durationTotal + " " + yearsTotalLabel + ")";
  }
}

<ng-container *ngIf="!modalMode">
  <ng-container *ngTemplateOutlet="fields"></ng-container>
</ng-container>

<ng-container *ngIf="modalMode">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button>
          <ion-button icon-only (click)="backButtonClicked()">
            <i class="fa-solid fa-circle-chevron-left back-icon"></i>
          </ion-button>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ "Budget" | translate }}</ion-title>
      <ng-container *ngIf="priceSheets$ | async as priceSheets">
        <ion-buttons slot="end" *ngIf="priceSheets.length > 0">
          <ion-button icon-only (click)="openPriceSheetsList()">
            <i class="fa-light fa-book-open-reader price-sheet-icon icon-padding"></i>
            ({{ priceSheets.length }})
          </ion-button>
        </ion-buttons>
      </ng-container>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <ng-container *ngTemplateOutlet="fields"></ng-container>
  </ion-content>

  <ion-footer>
    <app-footer-next-button
      nextButton
      *ngIf="pendingChanges"
      (next)="validateChanges()"
      [label]="addMode ? 'add' : 'save'"
      [enabled]="isSliceValid()"
      color="investment"></app-footer-next-button>
  </ion-footer>
</ng-container>

<ng-template #fields>
  <ion-list lines="full">
    <input-item [legend]="'Price' | translate">
      <ion-input
        #input
        [value]="slice.formattedPrice"
        [disabled]="readOnly || isSliceDisabled(slice)"
        (ionFocus)="onPriceFocus(input, slice)"
        (ionBlur)="onPriceBlur(input, slice)"
        placeholder="-"></ion-input>
    </input-item>
    <input-item [legend]="'Year' | translate">
      <ion-select
        [(ngModel)]="slice.year"
        (ngModelChange)="sliceChanged()"
        [disabled]="readOnly || isSliceDisabled(slice)"
        [cancelText]="'Cancel' | translate">
        <ion-select-option *ngFor="let year of years" [value]="year">
          {{ year }}
        </ion-select-option>
      </ion-select>
    </input-item>
    <input-item [legend]="'Budget origin' | translate">
      <ion-select
        [(ngModel)]="selectedBudgetOriginId"
        (ngModelChange)="sliceChanged()"
        [disabled]="readOnly || isSliceDisabled(slice)"
        [cancelText]="'Cancel' | translate">
        <ion-select-option *ngFor="let origin of budgetOrigins" [value]="origin.id">
          {{ origin.name }}
        </ion-select-option>
      </ion-select>
    </input-item>
    <!-- Scope budget  -->
    <input-item [legend]="'Budget scope' | translate" class="text-wrap">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        multiple
        placeholder="-"
        [(ngModel)]="investment.budgetScopes"
        (ngModelChange)="changeBudgetScopes()"
        [disabled]="readOnly || isSliceDisabled(slice)">
        <ion-select-option *ngFor="let budgetScope of allBudgetScopes" [value]="budgetScope.id">
          {{ budgetScope.name }}
        </ion-select-option>
      </ion-select>
    </input-item>
    <!-- Investment Status -->
    <input-item *ngIf="!addMode" [legend]="'Status' | translate" [interfaceOptions]="{ header: 'Status' | translate }">
      <ion-select
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        placeholder="-"
        interface="action-sheet"
        [(ngModel)]="selectedInvestmentStatusId"
        (ngModelChange)="statusChanged()"
        data-test="investment-status">
        <ion-select-option *ngFor="let investmentStatus of investmentStatuses" [value]="investmentStatus.id">
          {{ investmentStatus.name }}
        </ion-select-option>
      </ion-select>
    </input-item>
  </ion-list>
</ng-template>

import { Injectable } from "@angular/core";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";

@Injectable()
export class BrowserService {
  // Flag for telling other components that the AppSettings page wants to open
  public settingsOpening: boolean = false;

  constructor(private inAppBrowser: InAppBrowser) {}

  openUrl(url: string) {
    this.inAppBrowser.create(url, "_system");
  }

  public inBrowser(): boolean {
    //@ts-ignore
    return !window.device || window?.device.platform === "browser";
  }
}

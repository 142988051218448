import { Component, OnInit, OnDestroy } from "@angular/core";

import { AlertController, NavController, NavParams } from "@ionic/angular";

import { TranslateService } from "@ngx-translate/core";

import { Asset } from "../structs/assets";
import { Investment } from "../structs/investments";

import { InvestmentsService } from "../services/investments.service";
import { ErrorsService } from "../services/errors.service";
import { OfflineService } from "../services/offline.service";
import { Subscription, Observer, Observable } from "rxjs";

@Component({
  selector: "app-investment-field-base",
  template: "",
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InvestmentFieldBase implements OnInit, OnDestroy {
  protected title: string = "";
  protected investment: Investment = null;
  protected asset: Asset = null;
  protected fromPPA: boolean = false;
  loading: boolean = false;
  protected updateObserver: Observer<boolean> = null;
  protected subscriptions: Subscription[] = [];

  constructor(
    protected navCtrl: NavController,
    protected params: NavParams,
    protected investmentsApi: InvestmentsService,
    protected translate: TranslateService,
    protected errors: ErrorsService,
    protected offlineApi: OfflineService,
    protected alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.asset = this.params.get("asset") || null;
    this.fromPPA = this.params.get("fromPPA") || false;
    this.updateObserver = this.params.get("updateObserver") || null;
    if (this.investment && this.investment.investmentType) {
      this.title = this.investment.investmentType.name;
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public fieldSaved() {
    this.loading = false;
    if (this.updateObserver) {
      this.updateObserver.next(true);
    }
  }

  saveField(data: any, popNav: boolean = true): void {
    this.loading = true;
    this.investmentsApi.patchInvestment(this.asset, this.investment, data).subscribe(
      // Go back to previous page
      investment => {
        this.fieldSaved();
        if (popNav) {
          this.backNav();
        }
      },
      err => {
        this.fieldSaved();
        this.errors.signalError(err);
      }
    );
  }

  saveField$(data: any, popNav: boolean = true): Observable<Investment> {
    this.loading = true;
    return this.investmentsApi.patchInvestment(this.asset, this.investment, data);
  }

  public backNav() {
    this.navCtrl.pop();
  }
}

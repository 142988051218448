<legend-item
  [legend]="itemLabel"
  [showArrow]="false"
  [itemOptions]="itemOptions"
  [index]="index"
  [chaptersGroup]="$any(chaptersGroup)"
  [padding]="padding"
  [labelClass]="'text-style5'"
  [picture]="
    showPicture
      ? $any(chaptersGroup.group).pictures.length > 0
        ? $any(chaptersGroup.group).pictures[0]
        : pictureEmpty
      : null
  "
  [hasChildren]="hasChildren"
  [isCollapsed]="isCollapsed"
  [showChevronButtons]="showChevronButtons"
  (toggleCollapse)="toggleMultiPerimeterChildren()"
  (itemClicked)="openChaptersGroup(chaptersGroup)"
  (pictureClicked)="onPictureClicked.emit()"
  [isChild]="isChild"
  [isRoadmap]="showPicture">
  <div class="control-points-status text-style1">
    <ion-text>
      <span *ngIf="!$any(chaptersGroup.group).level_parent">
        {{ "{acknowledged} acknowledged control points on {total}" | translate: chaptersGroup }}
      </span>

      <span *ngIf="$any(chaptersGroup.group).level_parent">
        {{ "{acknowledged} on {total}" | translate: chaptersGroup }}
      </span>
    </ion-text>
  </div>
</legend-item>

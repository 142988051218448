<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button icon-only (click)="close()">
        <i class="fa-solid fa-circle-chevron-left back-icon"></i>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!assetAddMode">{{ "Configure asset type" | translate }}</ion-title>
    <ion-title *ngIf="assetAddMode">{{ "What type of asset ?" | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <asset-type-selector [editMode]="true" [searchMode]="searchMode" (assetTypeSelected)="close()"></asset-type-selector>
</ion-content>

<ion-footer></ion-footer>

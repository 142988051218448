import { Injectable } from "@angular/core";
import { File, FileEntry } from "@awesome-cordova-plugins/file/ngx";
import { saveAs } from "file-saver";
import { BrowserService } from "@services/browser.service";

@Injectable()
export class SaveToLocalService {
  constructor(private file: File, private browserService: BrowserService) {}

  /**
   * Save file to Download directory. Support Android, iOS and use fallback `saveAs` method for browser
   * @param filename
   * @param content
   */
  saveToDownload(filename: string, content: string | Blob | ArrayBuffer): Promise<FileEntry | null> {
    const options = { replace: true, append: false };
    const isBrowser = this.browserService.inBrowser();
    if (isBrowser) return saveAs(content, filename);
    return this.file.writeFile(this.file.dataDirectory, filename, content, options);
    // saveAs(content, filename);
    // return Promise.resolve(null);
  }
}

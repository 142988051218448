import * as Sentry from "@sentry/browser";
import { Environment } from "./app.environment";
import { ErrorHandler } from "@angular/core";
import { LocalSettingsService } from "@services/local-settings";

export const replay = new Sentry.Replay({});

export function startSentryReplay() {
  console.debug("starting sentry replay");
  replay.start();
}

export async function stopSentryReplay() {
  console.debug("stop sentry replay");
  await replay.stop();
}

export function initializeSentryReplayProvider(settingService: LocalSettingsService): () => Promise<void> {
  return () => {
    const isEnabled = settingService.get("sentryReplay");
    if (isEnabled) {
      startSentryReplay();
      return;
    }
    console.debug("sentry replay is disabled, continue");
    return Promise.resolve();
  };
}

export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      if (Environment.getSentryDSN()) {
        Sentry.captureException(error.originalError || error);
      }
    } catch (e) {
      console.warn("Could not send to sentry");
      console.error(e);
    }
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";

import { SuggestedLifetime } from "../../../../services/consistency-test.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "asset-inconsistent-data-suggestion",
  templateUrl: "./asset-inconsistent-data-suggestion.component.html",
  styleUrls: ["./asset-inconsistent-data-suggestion.component.scss"],
})
export class AssetInconsistentDataSuggestionComponent implements OnInit {
  @Input() public suggestedLifetime: SuggestedLifetime;
  @Input() public date: number = null;

  private suggestedLifetimeTitle: string = "";
  private suggestedLifetimeOk: string = "";
  consistencyWarning: string = "";

  constructor(private alertCtrl: AlertController, private translate: TranslateService) {
    this.translate
      .get("Suggested lifetime")
      .subscribe(suggestedLifetimeTitle => (this.suggestedLifetimeTitle = suggestedLifetimeTitle));
    this.translate.get("Ok").subscribe(suggestedLifetimeOk => (this.suggestedLifetimeOk = suggestedLifetimeOk));
  }

  ngOnInit() {
    if (!this.date) {
      this.consistencyWarning = this.translate.instant(
        "Are the technical state, the lifetime and the installation year consistent?"
      );
    } else {
      const translationParams = {
        date: this.date,
      };
      this.consistencyWarning = this.translate.instant(
        "Warning consistency between installation date, remaining lifetime in {{date}} and technical state in {{date}}",
        translationParams
      );
    }
  }

  /**
   * Displayed alert containing suggested lifetimes
   */
  public async displaySuggestedLifetime(event): Promise<void> {
    event.stopPropagation();
    event.preventDefault();

    let message = "";

    if (this.suggestedLifetime.minSuggestedLifetime === this.suggestedLifetime.maxSuggestedLifetime) {
      message = this.translate.instant(
        "{{suggestedLifetime}} years with the current technical state and installation year. (source: model)",
        { suggestedLifetime: this.suggestedLifetime.minSuggestedLifetime }
      );
    } else {
      message = this.translate.instant(
        "Between {{minSuggestedLifetime}} and {{maxSuggestedLifetime}} years with the current technical state and installation year. (source: model)",
        this.suggestedLifetime
      );
    }

    const suggestedLifetime = await this.alertCtrl.create({
      header: this.suggestedLifetimeTitle,
      message,
      buttons: [
        {
          text: this.suggestedLifetimeOk,
          handler: () => {},
        },
      ],
    });
    await suggestedLifetime.present();
  }
}

import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AppUser } from "../structs/base";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";

@Injectable()
export class UsersService {
  constructor(private backend: BackendService, private offlineService: OfflineService) {}

  /**
   * Calls the api that generate a jwt for the logged user
   */
  public generateJwt(): Observable<string> {
    return this.backend.get("/users/api/jwt/");
  }
  public getUserFromId(userId): Observable<AppUser> {
    return new Observable(observer => {
      this.offlineService.getConfig("users").subscribe(
        users => {
          let usr = users.find(user => user.get_user_id === userId);
          observer.next(usr);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}

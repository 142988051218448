import { map, shareReplay } from "rxjs/operators";
import { Scenario } from "../structs/scenarii";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BackendService } from "./backend.service";

@Injectable()
export class ScenariiService {
  constructor(private backendService: BackendService) {}

  /**
   * Go to server and fetch the latest version of the Initiative for the given id
   * @param siteId: number
   */
  public get scenarii$(): Observable<Scenario[]> {
    const url = `/investments/api/investment-scenarii/`;
    return this.backendService.get(url).pipe(
      map(res => res.results as Scenario[]),
      shareReplay(1)
    );
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { IonItemSliding, AlertController } from "@ionic/angular";
import { from, Observable, Subscription } from "rxjs";

import { ErrorsService } from "../../services/errors.service";
// import { InvestmentAddActionSheetComponent } from '../investment-add-action-sheet/investment-add-action-sheet.component';
import { InvestmentPicturesService } from "../../services/investment-pictures.service";
import { InvestmentsService } from "../../services/investments.service";
import { OfflineService } from "../../services/offline.service";
import { Perimeter, Asset } from "../../structs/assets";
import { Investment, InvestmentPicture } from "../../structs/investments";
import { ScopeService } from "../../services/scope.service";
import { AttachInvestmentsService } from "../../services/attach-investment.service";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { RoadmapIndicator } from "@structs";
import { ComingSoonService } from "@services/coming-soon.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "investment-item",
  templateUrl: "./investment-item.component.html",
  styleUrls: ["./investment-item.component.scss"],
})
export class InvestmentItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public investment: Investment;
  @Input() public showPaperclip: boolean = false;
  @Input() public showAttachedAsset: boolean = true;
  @Input() public initiativeInfo: string = null;
  @Input() public roadmapIndicators: RoadmapIndicator[];
  @Input() public forInitiative: boolean = false;
  @Input() public showDeleteButton: boolean = true;
  @Output() public investmentClick = new EventEmitter<Investment>();
  @Output() public investmentDeleted = new EventEmitter<number>();
  @Output() public detachInitiativeEvent = new EventEmitter<number>();
  public perimeter: Perimeter;
  public asset: Asset;
  public picture: InvestmentPicture;
  public investImpact: string;

  private confirmTitle: string = "";
  private confirmMessage: string = "";
  private confirmYesButton: string = "";
  private confirmNoButton: string = "";
  private subscriptions: Subscription[] = [];
  private pictureReloaded: boolean = false;

  // @ViewChild('investmentAddActionSheet')
  // private investmentAddActionSheet: InvestmentAddActionSheetComponent;

  constructor(
    private alertCtrl: AlertController,
    private errors: ErrorsService,
    private offlineService: OfflineService,
    private investmentPicturesService: InvestmentPicturesService,
    private investmentsService: InvestmentsService,
    private translate: TranslateService,
    private scopeService: ScopeService,
    private attachInvestmentsService: AttachInvestmentsService,
    private router: Router,
    private comingSoonService: ComingSoonService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.translate.get("Confirmation").subscribe(title => {
        this.confirmTitle = title;
      }),
      this.translate.get("Do you want to delete the current investment?").subscribe(message => {
        this.confirmMessage = message;
      }),
      this.translate.get("Ok").subscribe(yesButton => {
        this.confirmYesButton = yesButton;
      }),
      this.translate.get("Cancel").subscribe(noButton => {
        this.confirmNoButton = noButton;
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.investment && this.investment.impactRoadmapIndicators.length && this.roadmapIndicators) {
      this.investImpact =
        this.translate.instant("Impact") +
        ": " +
        this.investment.impactRoadmapIndicators
          .map(id => this.roadmapIndicators.find(indicator => indicator.id === id)?.name)
          .join(" - ");
    }

    if (changes.investment && this.investment) {
      if (this.investment.pictures) {
        this.picture = this.investment.pictures[0];
        this.pictureReloaded = false;
      }

      // Get the perimeter of the investment
      if (this.investment.assetId) {
        // Asset investments
        this.offlineService.loadAsset(this.investment.assetId).subscribe(asset => {
          if (asset) {
            this.asset = asset;
            this.perimeter = asset.building.monosite_perimeter;
          }
        });
      } else {
        // Global investments
        if (this.investment.building) {
          this.perimeter = this.investment.building.monosite_perimeter;
        } else if (this.investment.buildingId) {
          // If the building is not loaded : we need to look for
          // which perimeter corresponds to the buildingId
          this.scopeService.getCurrentMultiPerimeter().subscribe((multiPerimeter: Perimeter) => {
            for (let perimeter of multiPerimeter.sub_perimeters) {
              const monoPerimeterLocalId = this.investment.monoPerimeterLocalId;
              if (
                (perimeter.building_id > 0 && perimeter.building_id === this.investment.buildingId) ||
                (monoPerimeterLocalId !== "" && perimeter.localId === monoPerimeterLocalId)
              ) {
                this.perimeter = perimeter;
                break;
              }
            }
          });
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onItemClick(event: Event): void {
    event.stopPropagation();
    this.investmentClick.emit(this.investment);
  }

  public add(item: IonItemSliding) {
    item.close();
    console.log("TODO: investmentAddActionSheet");
    // this.investmentAddActionSheet.open(this.investment, this.asset);
  }

  public sendByMail(item: IonItemSliding) {
    item.close();
    this.comingSoonService.showComingSoonAlert();
    // this.investmentsService.sendInvestmentByEmail(this.investment, this.asset);
  }

  /**
   * Open the sheet of the associated asset
   */
  public showAsset() {
    this.scopeService
      .getCurrentMultiPerimeter()
      .pipe(tap(site => from(this.router.navigate(["perimeters", site.id, "asset-detail", this.investment.assetId]))))
      .subscribe();
  }

  /**
   * Propose to the user to delete the investment
   * @param item
   */
  public async delete(item: IonItemSliding) {
    let confirm = await this.alertCtrl.create({
      header: this.confirmTitle,
      message: this.confirmMessage,
      backdropDismiss: false,
      buttons: [
        {
          text: this.confirmNoButton,
          handler: () => {},
        },
        {
          text: this.confirmYesButton,
          handler: () => {
            this.investment.deleting = true;
            this.investmentsService.deleteInvestment(this.asset, this.investment).subscribe(
              () => {
                item.close();
                this.investmentDeleted.emit(this.investment.id);
              },
              err => {
                item.close();
                this.errors.signalError(err);
              }
            );
          },
        },
      ],
    });
    await confirm.present();
  }

  /**
   * Refresh the asset picture url (30min token)
   */
  public reloadPicture(): void {
    // We only try to reloaded the picture a first time so we don't start an infinite loop
    if (!this.pictureReloaded) {
      this.investmentPicturesService.refreshInvestmentPictures([this.investment.id]).subscribe(updatedInv => {
        this.investment = updatedInv[0];
        this.picture = this.investment.pictures.length > 0 ? this.investment.pictures[0] : null;
        this.pictureReloaded = true;
      });
    }
  }

  public detachInvestment(item) {
    item.close();
    this.attachInvestmentsService.detachInvestment(this.investment, this.asset).subscribe(
      investment => {
        this.investmentDeleted.emit(this.investment.id);
        this.investment = investment;
        this.asset = null;
      },
      err => {
        this.errors.signalError(err);
      }
    );
  }

  public detachFromInitiative(item) {
    item.close();
    this.detachInitiativeEvent.emit(this.investment.id);
  }
}

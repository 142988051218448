<ion-grid>
  <div *ngIf="question">
    <div class="question-header">
      {{ question.text }}
    </div>
    <div class="color-unknown" text-wrap *ngIf="question.help_text_subject">
      {{ question.help_text_subject }}
    </div>
    <div class="color-unknown" text-wrap *ngIf="question.help_text">
      {{ question.help_text }}
    </div>

    <div>
      <ion-list no-lines>
        <ion-item>
          <ion-range
            padding-horizontal
            min="1"
            max="6"
            step="1"
            snaps="true"
            [(ngModel)]="rangeValue"
            (ionChange)="noteChanged()"
            debounce="200"
            [disabled]="readOnly"></ion-range>
        </ion-item>
      </ion-list>
    </div>

    <div class="value-labels">
      <!-- High value = Low note / Low value = High note -->
      <ion-row>
        <ion-col text-left>
          <div class="low-value-label">{{ question.low_value_label }}</div>
        </ion-col>
        <ion-col text-right>
          <div class="high-value-label">{{ question.high_value_label }}</div>
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-grid>

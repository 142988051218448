<ion-menu side="start" menuId="user" contentId="main" [swipeGesture]="!!user" [maxEdgeStart]="20" class="main-menu">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>
        <ion-text color="light" class="ion-text-center" style="display: block" *ngIf="user">
          {{ userDisplayName }}
        </ion-text>
      </ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list lines="full">
      <ion-item (click)="supportEmail()">
        <ion-label>
          {{ "Email support" | translate }}
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          {{ "Call support team" | translate }}
        </ion-label>
      </ion-item>
      <ion-item (click)="sendDiagnostics()">
        <ion-label>
          {{ "Send automatic diagnostic" | translate }}
        </ion-label>
      </ion-item>
      <ion-item (click)="showSyncChanges()" detail="{{ changesCount > 0 }}">
        <ion-label>
          {{ "Changes to synchronise" | translate }} :
          <span *ngIf="changesCount >= 0">{{ changesCount }}</span>
          <span *ngIf="changesCount < 0">...</span>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          {{ "Most recent Response Time" | translate }} :
          <span *ngIf="settings">{{ lastHelloDelay }} {{ "Ms" | translate }}</span>
        </ion-label>
      </ion-item>
      <ion-item *ngIf="forceSynchroEnabled">
        <ion-button
          expand="block"
          class="app-settings-button text-black block-button button-md-good"
          (click)="forceSynchro()">
          {{ "Synchronise Now" | translate }}
        </ion-button>
      </ion-item>
      <ion-item lines="none">
        <ion-button expand="block" color="primary" class="block-button app-settings-button" (click)="logout()">
          {{ "Logout" | translate }}
        </ion-button>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer class="menu-footer">
    <ion-grid>
      <ion-row>
        <ion-col>
          <ion-label class="underlined" (click)="legalInformation()">{{ "Legal information" | translate }}</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-label>
            {{ "Current version" | translate }}:
            <span *ngIf="settings" slot="end">
              {{ settings.version || "0.0.0" }}
            </span>
          </ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="b-top">
          <span class="underlined" (click)="showMoreSettings()">{{ "See more" | translate }}</span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</ion-menu>

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        <i class="far fa-arrow-left"></i>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ getTitle() }}</ion-title>
  </ion-toolbar>
  <ion-searchbar
    *ngIf="showInvestments"
    (ionInput)="searchInvestment($event)"
    placeholder="{{ 'Search' | translate }}"></ion-searchbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="filteredInvestments$ | async as investments">
    <ion-list lines="full">
      <loader *ngIf="loading"></loader>

      <investment-item
        *ngFor="let investment of investments"
        [investment]="investment"
        (investmentClick)="attachInvestment(investment)"></investment-item>

      <div class="empty-value" *ngIf="!loading && investments.length === 0">
        <div class="no-results-icon">
          <i class="fal fa-euro-sign fa-3x"></i>
        </div>
        <div>{{ "No investments" | translate }}</div>
      </div>
    </ion-list>

    <!-- Investment add button. -->
    <ion-fab
      *ngIf="showInvestments && !loading && (investments.length === 0 || showFAB)"
      vertical="center"
      horizontal="end">
      <ion-fab-button (click)="dismiss(true)" color="investment">
        <i class="fal fa-2x fa-plus"></i>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
</ion-content>

<ion-list lines="full">
  <ion-item *ngIf="showChildrenInvestmentsToggle">
    <ion-label>{{ "View investments of children asset" | translate }}</ion-label>
    <ion-toggle [(ngModel)]="showChildrenInvestments" (ngModelChange)="investmentDeleted()"></ion-toggle>
  </ion-item>

  <div class="empty-value" *ngIf="!loading && investments.length === 0">
    <div class="no-results-icon">
      <i class="fal fa-euro-sign fa-2x"></i>
    </div>
    <div>{{ "No investments" | translate }}</div>
  </div>

  <ng-container *ngIf="roadmapIndicators$">
    <investment-item
      *ngFor="let investment of investments"
      [investment]="investment"
      (investmentClick)="showInvestment(investment)"
      (investmentDeleted)="investmentDeleted()"
      [showAttachedAsset]="false"
      [roadmapIndicators]="roadmapIndicators$ | async"></investment-item>
  </ng-container>
  <div class="space"></div>
</ion-list>

import { Injectable } from "@angular/core";

const SettingKey = "LOCAL_SETTINGS";

type Settings = {
  sentryReplay: boolean;
};

@Injectable({
  providedIn: "root",
})
export class LocalSettingsService {
  defaultValue: Settings = {
    sentryReplay: false,
  };

  constructor() {}

  get<K extends keyof Settings, V extends Settings[K]>(key: K) {
    const allSettings = this.getAll();
    return allSettings[key];
  }

  getAll(): Settings {
    try {
      return JSON.parse(localStorage.getItem(SettingKey) || "{}");
    } catch (e) {
      return this.defaultValue;
    }
  }

  set<K extends keyof Settings, V extends Settings[K]>(key: K, value: V): void {
    const currentValue = localStorage.getItem(SettingKey) || "{}";
    try {
      const settings = JSON.parse(currentValue);
      settings[key] = value;
      localStorage.setItem(SettingKey, JSON.stringify(settings));
    } catch (err) {
      console.warn("can't set local setting values", err);
      return;
    }
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

/**
 * Service to manage loading state of the application.
 */
@Injectable()
export class LoadingStateService {
  public loadingStateWatcher = new BehaviorSubject<boolean>(false);

  private nbTasksPending: number = 0;
  private loadingState: boolean = false;

  constructor() {}

  public startTask(observable: Observable<any>): void {
    this.nbTasksPending++;
    observable.subscribe(
      () => this.stopTask(),
      () => this.stopTask()
    );
    this.checkLoadingState();
  }

  private stopTask(): void {
    this.nbTasksPending--;
    this.checkLoadingState();
  }

  private checkLoadingState(): void {
    const newloadingState = this.nbTasksPending > 0;

    if (newloadingState !== this.loadingState) {
      this.loadingStateWatcher.next(newloadingState);
      this.loadingState = newloadingState;
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Investment } from "@structs";

@Component({
  selector: "app-investment-tags-modal",
  templateUrl: "./investment-tags-modal.component.html",
  styleUrls: ["./investment-tags-modal.component.scss"],
})
export class InvestmentTagsModalComponent implements OnInit {
  @Input() investment: Investment;
  @Input() addMode: boolean = false;
  @Output() public changesHappened = new EventEmitter<any>();
  private pendingChanges: boolean = false;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  public changes() {
    this.pendingChanges = true;
  }

  public backButtonClicked() {
    this.modalCtrl.dismiss({
      changed: this.pendingChanges,
    });
  }
}

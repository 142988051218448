// The file contents for the current environment for production purpose

export const environment = {
  name: "auchan_lu",
  backendHost: "https://auchan-luxembourg-app.azurewebsites.net",
  capexIntelligenceHost: "https://auchan-luxembourg.capex-intelligence.com",
  logo: "logo_kpi_no_bg.png",
  production: true,
  test: false,
  hasGoogleLogin: true,
  hasPasswordLogin: false,
  sentryDSN: "https://a2627fcf3f344a73b1c12e1b7cb5eb63@sentry.io/1447058",
  showDocDefaultsInInvestmentWizard: false,
  supportEmail: null,
  supportPhone: null,
  roadmapConfiguration: 1,
};

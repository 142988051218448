import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { AuditQuestion, NoteEvent } from "../../../structs/audit";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "stars-note",
  templateUrl: "./stars-note.component.html",
})
export class StarsNoteComponent implements OnInit {
  choices: Array<number> = [1, 2, 3, 4];
  faIconEmpty: string = "far fa-star";
  faIconFull: string = "fas fa-star";
  progressiveMode = true; // If true : the previous value are also full

  @Input() note: number = null;
  @Input() question: AuditQuestion = null;
  @Input() icon: string = "";
  @Input() readOnly: boolean = false;

  @Output() clicked: EventEmitter<NoteEvent> = new EventEmitter<NoteEvent>();

  constructor() {}

  ngOnInit() {
    this.note = this.question.items[0].current_note;
    if (this.icon === "squares") {
      this.faIconEmpty = "far fa-square";
      this.faIconFull = "fas fa-square";
      this.progressiveMode = false; // Just check the box corresponding to the value
    }
  }

  selectNote(event, value): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.readOnly) {
      return;
    }

    this.note = value;

    this.clicked.emit({ itemId: this.question.items[0].id, note: this.note });
  }

  showHelpText(event): void {
    event.preventDefault();
    event.stopPropagation();
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

import { AuditQuestion, NoteEvent } from "../../../structs/audit";
import { ActionSheetController, IonItemSliding, ModalController, Platform } from "@ionic/angular";
import { AuditService } from "../../../services/audit.service";
import { Asset, NotationQuestionPicture } from "../../../structs/assets";
import { PictureDetailComponent } from "../../picture-detail/picture-detail.component";
import { map } from "rxjs/operators";
import { getLocalId } from "../../../structs/utils";
import { TranslateService } from "@ngx-translate/core";
import { PicturesService } from "../../../services/pictures.service";
import { AssetEditService } from "src/app/services/asset-edit.service";
import { BrowserService } from "@services/browser.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "yes-no-idk-note",
  templateUrl: "./yes-no-idk-note.component.html",
  styleUrls: ["./yes-no-idk-note.component.scss"],
})
export class YesNoIdkNoteComponent implements OnInit {
  inBrowser: boolean;
  pictures: NotationQuestionPicture[] = [];
  private sourceTypeTitle: string;
  private sourceTypeCameraButton: string;
  private sourceTypeGalleryButton: string;
  private cancelLabel: string;
  note: number = 0;
  comment: string = null;

  @Input() public question: AuditQuestion = null;
  private _asset: Asset = null;
  @Input() public readOnly: boolean = false;
  @Output() public clicked: EventEmitter<NoteEvent> = new EventEmitter<NoteEvent>();
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;
  @ViewChild("slidingItem") slidingItem: IonItemSliding;

  @Input()
  get asset() {
    return this._asset;
  }

  set asset(value: any) {
    this._asset = value;
    if (this.question && this.question.items && this.question.items.length && this.asset.notesPictures) {
      const kpiId = this.question.items[0].id;
      this.pictures = this.asset.notesPictures[kpiId] || [];
    } else {
      this.pictures = [];
    }
  }

  constructor(
    private platform: Platform,
    private auditService: AuditService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslateService,
    private picturesService: PicturesService,
    private assetEditService: AssetEditService,
    private browserService: BrowserService
  ) {}

  public ngOnInit(): void {
    this.inBrowser = this.browserService.inBrowser();
    const note = this.question.items.length ? this.question.items[0].current_note : null;
    this.note = note !== null ? note : 0;
    this.comment = this.question.items.length && this.question.items[0].current_comment;
    this.translate.get("Source type").subscribe(title => {
      this.sourceTypeTitle = title;
    });
    this.translate.get("Camera").subscribe(buttonText => {
      this.sourceTypeCameraButton = buttonText;
    });
    this.translate.get("Photo library").subscribe(buttonText => {
      this.sourceTypeGalleryButton = buttonText;
    });
    this.translate.get("Cancel").subscribe(text => {
      this.cancelLabel = text;
    });
  }

  public setNote(): void {
    if (this.note === 1 && !this.comment) {
      this.comment = this.question.help_text;
    }
    if (this.note !== 1) {
      this.comment = null;
    }
    this.clicked.emit({
      itemId: this.question.items[0].id,
      note: this.note,
      comment: this.comment,
    });
  }

  public setComment() {
    this.clicked.emit({
      itemId: this.question.items[0].id,
      note: this.note,
      comment: this.comment,
    });
  }

  async addPicture() {
    if (!this.inBrowser) {
      const actionSheet = await this.actionSheetCtrl.create({
        header: this.sourceTypeTitle,
        buttons: [
          {
            text: this.sourceTypeCameraButton,
            handler: () => {
              this.picturesService
                .captureFromCamera()
                .pipe(map(filePath => this.addPictureToQuestionItem({ filePath })))
                .subscribe();
            },
          },
          {
            text: this.sourceTypeGalleryButton,
            handler: () => {
              this.picturesService
                .captureFromLibrary()
                .pipe(map(filePath => this.addPictureToQuestionItem({ filePath })))
                .subscribe();
            },
          },
          {
            text: this.cancelLabel,
            role: "cancel",
          },
        ],
      });
      await actionSheet.present();
    } else {
      // On Web application, fallback to upload file
      this.browserFileUpload.nativeElement.click();
    }
  }

  /**
   * Simulate taking picture in browser using HTML upload feature
   * @param $event: HTMLInputElement including target as files
   */
  addBrowserPicture($event: Event) {
    const files: FileList = (<HTMLInputElement>$event.target).files;
    if (files.length !== 0) {
      const file = files[0];
      this.addPictureToQuestionItem({ browserFile: file });
    }
  }

  /**
   * Generate localId and upload NotationQuestionPicture using auditService
   * @param file
   * @private
   */
  private addPictureToQuestionItem(file: { browserFile?: File; filePath?: string }) {
    const { browserFile, filePath } = file,
      localId = getLocalId(),
      auditQuestionPicture = new NotationQuestionPicture(
        0,
        this.asset.id,
        this.question.items[0].id,
        null,
        null,
        0,
        null,
        localId,
        "",
        filePath,
        browserFile
      );

    if (this.slidingItem) {
      this.slidingItem.close();
    }

    this.auditService.addAuditQuestionPicture(auditQuestionPicture).subscribe(() => {
      this.pictures.push(auditQuestionPicture);
      this.pictures = [].concat(this.pictures); // force refresh
      // Update the asset with the list of pictures for this KPI
      // The photo is a local image (not yet on server)
      // We can't wait for synchronization to update the asset : coming back to the asset will not show the photo
      this.asset.notesPictures[this.question.items[0].id] = this.pictures;
      const questionId = this.question.items[0].id;
      this.assetEditService.setExpertPictures(questionId, this.pictures);
    });
  }

  /**
   * Show the QuestionItemPicture
   * @param picture: NotationQuestionPicture
   */
  async showPicture(picture: NotationQuestionPicture) {
    const pictureModal = await this.modalCtrl.create({
      component: PictureDetailComponent,
      componentProps: {
        picture: picture,
        asset: this.asset,
        origin: "AUDIT_EXPERT",
        question: this.question,
      },
    });
    await pictureModal.present();
  }
}

import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "defaultValue",
})
export class DefaultValuePipe implements PipeTransform {
  constructor() {}

  transform(value: any, args?: any): any {
    let defaultText = args || "";
    if (value) {
      return value;
    }
    return defaultText;
  }
}

import { AppUser } from "./base";
import { Perimeter, ReferenceDataValue } from "./assets";

/**
 * Type of a data.
 */
export enum DataType {
  DATA_TYPE_STRING,
  DATA_TYPE_NUMBER,
  DATA_TYPE_FK,
  DATA_TYPE_USER,
}

/**
 * Type of filtering .
 */
export enum FilterType {
  FILTER_VALUE,
  FILTER_RANGE,
}

/**
 * Represent a filter range value, for numerical data type.
 */
export class PerimeterFilterRangeValue {
  constructor(public lower: number, public upper: number, public label: string) {}

  static getRanges(
    min: number,
    max: number,
    numberOfRanges: number,
    hasEmptyValue?: boolean
  ): PerimeterFilterRangeValue[] {
    const ranges: PerimeterFilterRangeValue[] = [];

    const step = (max - min) / numberOfRanges;
    const stepDecimals = Math.floor(Math.log10(step));

    // We calculate the first range
    let rangeMin = PerimeterFilterRangeValue.roundToNiceNumber(min, stepDecimals, false);
    let rangeMax = PerimeterFilterRangeValue.roundToNiceNumber(rangeMin + step, stepDecimals);
    ranges.push(new PerimeterFilterRangeValue(rangeMin, rangeMax, `${rangeMin} → ${rangeMax}`));

    // For the other range we used for the min value the max value of the previous range
    for (let i = 1; i < numberOfRanges; i++) {
      rangeMin = rangeMax;
      rangeMax = PerimeterFilterRangeValue.roundToNiceNumber(rangeMin + step, stepDecimals);
      ranges.push(new PerimeterFilterRangeValue(rangeMin, rangeMax, `${rangeMin} → ${rangeMax}`));
    }

    // We add a "not defined" range if needed
    if (hasEmptyValue) {
      ranges.push(new PerimeterFilterRangeValue(null, null, ""));
    }

    return ranges;
  }

  /**
   * Rounds a number up to his closest "nice" number using the estiamte size to know the decimal rounder, e.g.,
   * * `233`  becomes `300`  with estimate size set at `2`
   * * `1234` becomes `1200` with estimate size set at `2`
   * * `1234` becomes `1000` with estimate size set at `3`
   * etc.
   *
   * @param number The number to round.
   * @param estimateSize The size of numbers that will be rounded
   * @param roundSuperior true if we round to superior integer, else round to inferior integer
   */
  static roundToNiceNumber(number: number, estimateSize: number, roundSuperior: boolean = true): number {
    let roundNumber: number;
    if (roundSuperior) {
      roundNumber = Math.ceil(number / Math.pow(10, estimateSize));
    } else {
      roundNumber = Math.floor(number / Math.pow(10, estimateSize));
    }
    return Math.round(roundNumber * Math.pow(10, estimateSize));
  }
}

export type PerimeterPredicate = (v: Perimeter) => boolean;

/**
 * Type of a perimeter filter value.
 */
export type PerimeterFilterValue = string | number | ReferenceDataValue | AppUser;

/**
 * Represent a perimeter filter.
 */
export abstract class PerimeterFilter {
  public label: string;
  public dataType: DataType;
  public filtering: FilterType;

  public availableValues: PerimeterFilterValue[] = [];
  public selectedValues: PerimeterFilterValue[] = [];

  public availableValuesRange: PerimeterFilterRangeValue[] = [];
  public selectedValueRange: PerimeterFilterRangeValue = null;

  constructor() {}

  public hasSelected(): boolean {
    return this.selectedValues.length > 0 || this.selectedValueRange !== null;
  }

  public clearSelected(): void {
    this.selectedValues = [];
    this.selectedValueRange = null;
  }

  public abstract getPredicate(): PerimeterPredicate;
}

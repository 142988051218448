import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-no-network",
  templateUrl: "./no-network.component.html",
  styleUrls: ["./no-network.component.scss"],
})
export class NoNetworkComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

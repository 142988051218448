import { NgModule } from "@angular/core";

import { UsernamePipe } from "./username.pipe";

@NgModule({
  imports: [],
  declarations: [UsernamePipe],
  exports: [UsernamePipe],
})
export class UsernamePipeModule {}

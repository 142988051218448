import { Injectable } from "@angular/core";
import { StorageService } from "@services/storage.service";
import { IChange } from "@structs/synchronization";
import { changesKey } from "../constants";

const DIAGNOSTIC_KEY_STORAGE = "DIAGNOSTIC_KEY_STORAGE";

/**
 * This service is used as an:
 *  - append-only storage service that holds all Change[] objects
 */
@Injectable({
  providedIn: "root",
})
export class DiagnosticService {
  constructor(private storage: StorageService) {}

  async appendChange(change: IChange): Promise<boolean> {
    const changes = JSON.parse((await this.getChanges()) || "[]");
    changes.push(change);
    const val = this.storage.set(DIAGNOSTIC_KEY_STORAGE, JSON.stringify(changes));
    return !!val;
  }

  async numberOfPendingChanges(): Promise<number> {
    return ((await this.storage.get(changesKey)) || []).length;
  }

  async totalChanges() {
    return ((await this.getChanges()) || []).length;
  }

  private async getChanges(): Promise<string> {
    return this.storage.get(DIAGNOSTIC_KEY_STORAGE);
  }
}

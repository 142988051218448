import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { PerimeterBudgetPlan, makePerimeterBudgetPlan } from "../structs/audit";

const PERIMETER_BUDGET_PLAN_KEY = "perimeterBudgetPlans:";

@Injectable()
export class PerimeterBudgetPlansService {
  constructor(private backend: BackendService, private offlineApi: OfflineService) {}

  /**
   * Returns all the perimeter budget plans link to a perimeter
   *
   * @param perimeterId
   * @returns Observable<PerimeterBudgetPlan[]>
   */
  getPerimeterBudgetPlans(perimeterId: number, refresh: boolean = false): Observable<PerimeterBudgetPlan[]> {
    return new Observable((observer: Observer<PerimeterBudgetPlan[]>) => {
      if (refresh) {
        let url = `/audit/api/perimeter-budget-plans/?perimeters=${perimeterId}&`;
        // Load from backend
        this.backend.get(url).subscribe(
          jsonData => {
            const budgetPlans: PerimeterBudgetPlan[] = [];
            jsonData.results.map(element => {
              budgetPlans.push(makePerimeterBudgetPlan(element));
            });
            this._storePerimeterBudgetPlans(perimeterId, budgetPlans, observer);
          },
          () => {
            // In case of error try to load from offline data
            this._loadPerimeterBudgetPlans(perimeterId, observer);
          }
        );
      } else {
        // Load if from offline data
        this._loadPerimeterBudgetPlans(perimeterId, observer);
      }
    });
  }

  private _loadPerimeterBudgetPlans(perimeterId: number, observer: Observer<PerimeterBudgetPlan[]>) {
    let fullKey = PERIMETER_BUDGET_PLAN_KEY + perimeterId;
    this.offlineApi.getItem(fullKey, true).subscribe(
      (budgetPlansData: any) => {
        // cast storeData to PerimeterBudgetPlan class
        const budgetPlans: PerimeterBudgetPlan[] = [];
        budgetPlansData.forEach(element => {
          budgetPlans.push(makePerimeterBudgetPlan(element));
        });
        observer.next(budgetPlans);
        observer.complete();
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  private _storePerimeterBudgetPlans(
    perimeterId: number,
    perimeterBudgetPlans: PerimeterBudgetPlan[],
    observer: Observer<PerimeterBudgetPlan[]>
  ) {
    let fullKey = PERIMETER_BUDGET_PLAN_KEY + perimeterId;
    return this.offlineApi.storeItem(fullKey, perimeterBudgetPlans).subscribe(
      () => {
        observer.next(perimeterBudgetPlans);
        observer.complete();
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }
}

import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { ConfigService } from "./config.service";
import {
  makePurchaseField,
  makePurchaseRequest,
  PurchaseField,
  PurchaseFieldChoice,
  PurchaseRequest,
  PurchaseUser,
} from "@structs/purchase";
import { Investment } from "@structs";

@Injectable()
export class PurchaseService {
  private purchasePlatform: string = "";
  public purchaseFieldChangedObservable: Observable<any> = null;
  private purchaseFieldChangedObserver: Observer<any> = null;

  constructor(
    private backendService: BackendService,
    private configService: ConfigService,
    private offlineService: OfflineService
  ) {
    this.purchaseFieldChangedObservable = new Observable(observer => {
      this.purchaseFieldChangedObserver = observer;
    });
  }

  getPurchasePlatform(): Observable<string> {
    return this.offlineService.getConfigNoRefresh("purchase_platform");
  }

  getPurchaseAllowedPricePercentage(): Observable<string> {
    return this.offlineService.getConfigNoRefresh("purchase_allowed_price_percentage");
  }

  getInvestmentPurchaseRequests(investmentId: number): Observable<PurchaseRequest[]> {
    return new Observable(observer => {
      this.backendService.get("/purchase/api/investment/" + investmentId + "/purchase-requests/").subscribe(
        jsonData => {
          observer.next(jsonData.map(elt => makePurchaseRequest(elt)));
          observer.complete();
        },
        err => {
          // rej(err)
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  addInvestmentPurchaseRequest(investmentId: number, pr: PurchaseRequest): Observable<PurchaseRequest> {
    return new Observable(observer => {
      const data = {
        title: pr.title,
        purchase_on: pr.purchaseOn,
        price: pr.price,
        quantity: pr.quantity,
        comments: pr.comments,
        unit: pr.unit,
        description: pr.description,
        field_values: pr.fieldValues.map(fv => {
          return {
            field: fv.field,
            string_value: fv.value,
          };
        }),
        auto_approve: false,
        auto_send: pr.autoSend,
      };
      this.backendService.post("/purchase/api/investment/" + investmentId + "/purchase-requests/", data).subscribe(
        jsonData => {
          observer.next(makePurchaseRequest(jsonData));
          observer.complete();
        },
        err => {
          // rej(err)
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  saveInvestmentPurchaseRequest(investmentId: number, pr: PurchaseRequest): Observable<PurchaseRequest> {
    return new Observable(observer => {
      const data = {
        title: pr.title,
        purchase_on: pr.purchaseOn,
        price: pr.price,
        quantity: pr.quantity,
        comments: pr.comments,
        unit: pr.unit,
        description: pr.description,
        field_values: pr.fieldValues.map(fv => {
          return {
            field: fv.field,
            string_value: fv.value,
          };
        }),
        auto_approve: false,
      };
      this.backendService
        .patch("/purchase/api/investment/" + investmentId + "/purchase-requests/" + pr.id + "/", data)
        .subscribe(
          jsonData => {
            observer.next(makePurchaseRequest(jsonData));
            observer.complete();
          },
          err => {
            // rej(err)
            observer.error(err);
            observer.complete();
          }
        );
    });
  }

  getPurchaseFields(): Observable<PurchaseField[]> {
    return new Observable(observer => {
      this.backendService.get("/purchase/api/purchase-fields/").subscribe(
        jsonData => {
          observer.next(jsonData.map(elt => makePurchaseField(elt)));
          observer.complete();
        },
        err => {
          // rej(err)
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  getPurchaseUser(investmentId: number): Observable<PurchaseUser> {
    return new Observable(observer => {
      this.backendService.get("/purchase/api/purchase-user/" + investmentId + "/").subscribe(
        jsonData => {
          observer.next(jsonData);
          observer.complete();
        },
        err => {
          // rej(err)
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  notifyChange(field: PurchaseField, choice: PurchaseFieldChoice) {
    this.purchaseFieldChangedObserver.next({
      field: field,
      detail: {
        value: choice,
      },
    });
  }

  public getFieldChoices(
    allFields: PurchaseField[],
    purchaseSiteId: number,
    investment: Investment,
    purchaseRequest: PurchaseRequest,
    field: PurchaseField,
    naiLevel: number = 0
  ): PurchaseFieldChoice[] {
    let choices: PurchaseFieldChoice[] = [];
    if (field.parent) {
      let parents = allFields.filter(elt => elt.id === field.parent);
      if (parents.length > 0) {
        const parent = parents[0];
        let parentChoice = null;
        if (purchaseRequest) {
          parentChoice = purchaseRequest.getFieldChoiceValue(parent);
        }
        if (parentChoice) {
          if (field.naiParent) {
            // get the ids of NAI codes for the current level
            let codeDigits = parentChoice.code.split("-");
            const keep = Math.max(1, codeDigits.length - naiLevel);
            const searchCode = codeDigits.slice(0, keep).join("-");
            const naiCodesIds = parent.choices
              .filter(naiChoice => naiChoice.code.indexOf(searchCode) === 0)
              .map(naiChoice => naiChoice.id);

            // filter the potential choices
            choices = field.choices.filter(choice => {
              const naiParentIds = choice.parents.length ? choice.parents : choice.parent ? [choice.parent] : [];
              if (naiParentIds.length === 0) {
                // If the choice has no naiCodes associated. Show it as a possible choice
                return true;
              } else {
                for (const naiParentId of naiParentIds) {
                  // If one of the parents belongs to the current level of NAI, then show it
                  if (naiCodesIds.indexOf(naiParentId) >= 0) {
                    return true;
                  }
                }
                // If none of the parents in the list, then hide it
                return false;
              }
            });
          } else {
            choices = field.choices.filter(
              choice =>
                choice.parent === parentChoice.id ||
                (!choice.parent && choice.parents.length === 0) ||
                choice.parents.indexOf(parentChoice.id) >= 0
            );
          }
        }
      }
    } else if (field.investmentParentField) {
      const subFields = field.investmentParentField.split(".");
      let investValue: any = investment;
      for (const subField of subFields) {
        if (investValue) {
          investValue = investValue[subField];
        }
      }
      if (investValue) {
        choices = field.choices.filter(elt => elt.investmentParent === investValue);
      } else {
        choices = [];
      }
    } else {
      choices = field.choices;
    }
    return choices.filter(elt => elt.onlyForSite === 0 || elt.onlyForSite === purchaseSiteId);
  }

  public deletePurchaseRequest(investmentId: number, purchaseRequestId) {
    let url = "/purchase/api/investment/" + investmentId + "/purchase-requests/" + purchaseRequestId;
    return this.backendService.postDelete(url);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { InvestmentsService } from "@services/investments.service";
import { Investment, InvestmentReason } from "@structs";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-investment-tags",
  templateUrl: "./investment-tags.component.html",
  styleUrls: ["./investment-tags.component.scss"],
})
export class InvestmentTagsComponent implements OnInit, OnDestroy {
  @Input() investment: Investment;
  @Input() addMode: boolean = false;
  public tagsToggle: boolean = false;
  public showWorkflowTags: boolean = true;
  public showOtherTags: boolean = true;
  private tags$: Observable<InvestmentReason[]>;
  public workflowTags$: Observable<InvestmentReason[]>;
  public otherTags$: Observable<InvestmentReason[]>;
  @Output() public changesHappened = new EventEmitter<any>();
  private subscriptions: Subscription[] = [];

  constructor(private investmentsService: InvestmentsService) {}

  ngOnInit() {
    this.tags$ = this.investmentsService.getInvestmentReasons().pipe(
      map(reasons => {
        return reasons.filter(
          reason =>
            reason.onlyForInvestmentTypes.length === 0 ||
            reason.onlyForInvestmentTypes.includes(this.investment.investmentType.id)
        );
      })
    );
    this.workflowTags$ = this.tags$.pipe(map(tags => tags.filter(tag => tag.displayInWorkflow)));
    this.otherTags$ = this.tags$.pipe(map(tags => tags.filter(tag => !tag.displayInWorkflow)));
    if (this.addMode) {
      if (!this.investment.reasons.length) {
        this.subscriptions.push(
          this.tags$.subscribe(reasons => {
            const defaultReason = reasons.find(reason => reason.isDefault);
            if (defaultReason) {
              this.tagsToggle = true;
              this.checkTag(defaultReason, true);
            }
          })
        );
      }
    }
  }

  public isChecked(tagId: number): boolean {
    return !!this.investment.reasons.find(reason => reason.id === tagId);
  }

  public checkTag(tag: InvestmentReason, init?: boolean) {
    const tagIndex = this.investment.reasons.findIndex(selectedTag => selectedTag.id === tag.id);
    if (tagIndex === -1) {
      this.investment.reasons.push(tag);
    } else {
      this.investment.reasons.splice(tagIndex, 1);
    }
    if (!init) {
      this.changes();
    }
  }

  public changes() {
    if (!this.tagsToggle) {
      this.investment.reasons = [];
    }
    this.changesHappened.emit({
      tagsToggle: this.tagsToggle,
      tags: this.investment.reasons,
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

<div *ngIf="status && status !== 'validated'">
  <i class="fal fa-check-circle fa-sm"></i>
</div>

<div *ngIf="statusCMMS === 'conflict'">
  <i class="fal fa-circle fa-xs sync-conflict"></i>
</div>
<div *ngIf="statusCMMS === 'synchronized'">
  <i class="fal fa-check-circle fa-sm"></i>
</div>

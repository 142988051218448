<ion-select
  okText="{{ 'OK' | translate }}"
  cancelText="{{ 'Cancel' | translate }}"
  *ngIf="!showSlider"
  #technicalStateSelect
  interface="action-sheet"
  mode="ios"
  [(ngModel)]="note"
  [disabled]="readOnly"
  (ngModelChange)="noteChanged()"
  placeholder="{{ 'Click here (obligatory info)' | translate }}">
  <ion-select-option [value]="0">{{ "Dead" | translate }}</ion-select-option>
  <ion-select-option [value]="14">{{ "Critical" | translate }}</ion-select-option>
  <ion-select-option [value]="38">{{ "Bad" | translate }}</ion-select-option>
  <ion-select-option [value]="62">{{ "Acceptable" | translate }}</ion-select-option>
  <ion-select-option [value]="94">{{ "Good" | translate }}</ion-select-option>
  <ion-select-option [value]="100">{{ "New" | translate }}</ion-select-option>
</ion-select>
<div *ngIf="showSlider" [ngClass]="{ 'read-only': null }" class="ion-padding-end">
  <div class="slider">
    <ngx-slider
      [(value)]="note"
      [options]="options"
      [manualRefresh]="sliderRefresh"
      (valueChange)="noteChanged()"></ngx-slider>
  </div>
  <div class="states">
    <div class="state state-critical" [ngClass]="{ 'state-selected': note <= 25 }" (click)="stateClick(14)">
      {{ "Critical" | translate }}
    </div>
    <div class="state state-bad" [ngClass]="{ 'state-selected': note > 25 && note <= 50 }" (click)="stateClick(38)">
      {{ "Bad" | translate }}
    </div>
    <div
      class="state state-acceptable"
      [ngClass]="{ 'state-selected': note > 50 && note <= 75 }"
      (click)="stateClick(62)">
      {{ "Acceptable" | translate }}
    </div>
    <div class="state state-good" [ngClass]="{ 'state-selected': note > 75 && note <= 100 }" (click)="stateClick(94)">
      {{ "Good" | translate }}
    </div>
  </div>
  <div *ngIf="showImpactWarning">
    <span class="impact-warning">{{ impactWarning }}</span>
  </div>
  <div *ngIf="showLastAuditSentence">
    <span class="last-audit-sentence">{{ lastAuditSentence }}</span>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavParams, AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { AssetsService } from "../../../services/assets.service";
import { ErrorsService } from "../../../services/errors.service";
import { Category, getAvailableCategories, Perimeter, SubCategory, SuperCategory } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { ScopeService } from "../../../services/scope.service";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-investment-category-subcategory-selector",
  templateUrl: "./investment-category-subcategory-selector.component.html",
  styleUrls: ["./investment-category-subcategory-selector.component.scss"],
})
export class InvestmentCategorySubcategorySelectorComponent implements OnInit {
  @Input() public investment: Investment = null;
  @Input() public childComponentMode: boolean = false;
  @Input() public needsFullNomenclature: boolean = false;
  @Input() public perimeter: Perimeter;
  @Input() public readOnly: boolean = false;

  @Output() public changesHappened = new EventEmitter<any>();

  public categories: Category[] = [];
  public subCategories: SubCategory[] = [];
  public superCategories$: Observable<SuperCategory[]> = this.assetsApi.getSuperCategories();

  public selectedCategoryId: number = null;
  public selectedSubCategoryId: number = null;
  public selectedSuperCategoryId: number = null;

  private selectedSuperCategory: SuperCategory = null;
  private selectedCategory: Category;
  private selectedSubCategory: SubCategory;

  private pendingChangesTitle: string = "";
  private pendingChangesConfirmation: string = "";
  public hasPendingChanges: boolean = false;
  private selectedCategoryOldValue: number = -1;
  private selectedSubCategoryOldValue: number = -1;
  private cluster: number;
  private availableCategoriesIds: number[] = [];
  public showOptionalFields: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private assetsApi: AssetsService,
    private errors: ErrorsService,
    private params: NavParams,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private scope: ScopeService
  ) {}

  public ngOnInit(): void {
    this.perimeter = this.params.get("perimeter");
    this.subscriptions.push(
      this.scope.getSelectedPerimeter().subscribe(selectedPerimeter => {
        this.cluster = this.perimeter ? this.perimeter.cluster : selectedPerimeter ? selectedPerimeter.cluster : null;

        this.assetsApi.getCategories().subscribe(
          categories => {
            this.categories = getAvailableCategories(categories, this.cluster, null);
            this.availableCategoriesIds = this.categories.map(category => category.id);

            if (this.categories.length === 0) {
              this.translate.get("Configuration missing. Refresh the list of perimeters").subscribe(text => {
                this.errors.signalError(text);
              });
            } else {
              let autoSelectSuperCategoryId: number = 0;
              let autoSelectSubCategoryId: number = 0;
              let autoSelectCategoryId: number = 0;

              if (this.investment.superCategory) {
                autoSelectSuperCategoryId = this.investment.superCategory.id;
              }
              if (this.investment.category) {
                autoSelectCategoryId = this.investment.category.id;
              }
              if (this.investment.subCategory) {
                autoSelectSubCategoryId = this.investment.subCategory.id;
              }

              if (autoSelectSubCategoryId) {
                this.autoSelectSubCategory(categories, autoSelectSubCategoryId);
                this.categoryChanged(false);
              } else if (autoSelectCategoryId) {
                this.autoSelectCategory(categories, autoSelectCategoryId);
                this.categoryChanged(true);
              } else if (autoSelectSuperCategoryId) {
                this.selectedSuperCategoryId = autoSelectSuperCategoryId;
              }

              this.selectedCategoryOldValue = this.selectedCategoryId;
              this.selectedSubCategoryOldValue = this.selectedSubCategoryId;
              this.hasPendingChanges = false;
            }
          },
          err => {
            this.errors.signalError(err);
          }
        );
      }),
      this.translate.get("Save changes?").subscribe(text => {
        this.pendingChangesConfirmation = text;
      })
    );

    this.pendingChangesTitle = this.translate.instant("Confirmation");
    this.hasPendingChanges = false;
    this.showOptionalFields = !!this.needsFullNomenclature;
  }

  public onConcernCategoryChanged(concernCategory: boolean) {
    if (!concernCategory) {
      this.selectedSuperCategoryId = null;
      this.selectedCategoryId = null;
      this.selectedSubCategoryId = null;
      this.categoryChanged(true);
    }

    if (this.selectedCategoryOldValue != -1 && concernCategory) {
      this.hasPendingChanges = true;
    }
    this.changes();
  }

  public superCategoryChanged(clearDown, superCategories) {
    if (!this.selectedSuperCategoryId) {
      this.selectedCategory = null;
      this.hasPendingChanges = false;
    } else {
      this.selectedSuperCategory = superCategories.find(superCat => superCat.id === this.selectedSuperCategoryId);
      if (this.selectedSuperCategory && clearDown) {
        this.selectedCategoryId = null;
        this.selectedCategory = null;
        this.categories = this.selectedSuperCategory.children.filter(category =>
          this.availableCategoriesIds.includes(category.id)
        );
        if (this.categories.length === 1) {
          this.selectedCategoryId = this.categories[0].id;
        }
        this.categoryChanged(true);
      }
    }

    this.changes();
  }

  public categoryChanged(clearDown: boolean): void {
    // If no category are selected we reinitialize the selected category
    if (!this.selectedCategoryId) {
      this.selectedCategory = null;
      this.hasPendingChanges = false;
    } else {
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i].id === this.selectedCategoryId) {
          this.selectedCategory = this.categories[i];
          if (clearDown) {
            this.subCategories = this.selectedCategory.children;
          }
          break;
        }
      }
    }

    if (clearDown) {
      if (this.subCategories.length === 1) {
        this.selectedSubCategoryId = this.subCategories[0].id;
      } else {
        this.selectedSubCategoryId = null;
      }
      this.subCategoryChanged();
    }

    if (this.selectedCategoryOldValue != this.selectedCategoryId) {
      this.hasPendingChanges = true;
    }
    this.changes();
  }

  public subCategoryChanged(): void {
    // If no subcategory are selected we reinitialize the selected subcategory
    if (!this.selectedCategoryId) {
      this.selectedSubCategory = null;
    } else {
      for (let i = 0; i < this.subCategories.length; i++) {
        if (this.subCategories[i].id === this.selectedSubCategoryId) {
          this.selectedSubCategory = this.subCategories[i];
          break;
        }
      }
    }

    if (this.selectedSubCategoryOldValue != this.selectedSubCategoryId) {
      this.hasPendingChanges = true;
    }
    this.changes();
  }

  public async dismiss(): Promise<void> {
    if (this.hasPendingChanges) {
      const pendingChangesConfirm = await this.alertCtrl.create({
        cssClass: "alert-three-buttons",
        header: this.pendingChangesTitle,
        message: this.pendingChangesConfirmation,
        buttons: [
          {
            text: this.translate.instant("Cancel"),
            handler: () => {},
          },
          {
            text: this.translate.instant("No"),
            handler: () => {
              let category = this.categories.find(c => c.id == this.selectedCategoryOldValue);
              if (category) {
                this.selectedCategoryId = this.selectedCategoryOldValue;
                this.selectedCategory = category;
              } else {
                this.selectedCategory = null;
                this.selectedCategoryId = null;
              }

              let subCategory = this.subCategories.find(c => c.id == this.selectedSubCategoryOldValue);
              if (subCategory) {
                this.selectedSubCategoryId = this.selectedSubCategoryOldValue;
                this.selectedSubCategory = subCategory;
              } else {
                this.selectedSubCategory = null;
                this.selectedSubCategoryId = null;
              }

              this.selectedCategoryOldValue = -1;
              this.selectedSubCategoryOldValue = -1;
              this.hasPendingChanges = false;

              this.modalCtrl.dismiss({
                selectedSuperCategory: this.selectedSuperCategory,
                selectedCategory: this.selectedCategory,
                selectedSubCategory: this.selectedSubCategory,
              });
            },
          },
          {
            text: this.translate.instant("Yes"),
            handler: () => {
              this.hasPendingChanges = false;
              this.selectedCategoryOldValue = -1;
              this.selectedSubCategoryOldValue = -1;
              this.modalCtrl.dismiss({
                selectedSuperCategory: this.selectedSuperCategory,
                selectedCategory: this.selectedCategory,
                selectedSubCategory: this.selectedSubCategory,
              });
            },
          },
        ],
      });

      await pendingChangesConfirm.present();
    } else {
      let category = this.categories.find(c => c.id == this.selectedCategoryId);
      let subCategory = this.subCategories.find(c => c.id == this.selectedSubCategoryId);
      this.modalCtrl.dismiss({
        selectedSuperCategory: this.selectedSuperCategory,
        selectedCategory: category,
        selectedSubCategory: subCategory,
      });
    }
  }

  private autoSelectSubCategory(categories: Category[], subCategoryId: number): void {
    for (let i = 0; i < categories.length; i++) {
      let category: Category = categories[i];
      for (let j = 0; j < category.children.length; j++) {
        let subCategory: SubCategory = category.children[j];
        if (subCategory.id === subCategoryId) {
          this.selectedCategoryId = category.id;
          this.subCategories = category.children;
          this.selectedSubCategoryId = subCategory.id;
          this.selectedSuperCategoryId = category.parent;
          this.changes();
          return; // Done
        }
      }
    }
  }

  private autoSelectCategory(categories: Category[], categoryId: number): void {
    for (let i = 0; i < categories.length; i++) {
      let category: Category = categories[i];
      if (category.id === categoryId) {
        this.selectedCategoryId = category.id;
        this.subCategories = category.children;
        this.selectedSuperCategoryId = category.parent;
        this.changes();
        return; // Done
      }
    }
  }

  public validateChanges(): void {
    this.hasPendingChanges = false;
    this.selectedCategoryOldValue = -1;
    this.selectedSubCategoryOldValue = -1;
    let category = this.categories.find(c => c.id == this.selectedCategoryId);
    let subCategory = this.subCategories.find(c => c.id == this.selectedSubCategoryId);
    this.modalCtrl.dismiss({
      selectedSuperCategory: this.selectedSuperCategory,
      selectedCategory: category,
      selectedSubCategory: subCategory,
    });
  }

  public changes() {
    let category = this.categories.find(c => c.id == this.selectedCategoryId);
    let subCategory = this.subCategories.find(c => c.id == this.selectedSubCategoryId);
    this.changesHappened.emit({
      superCategory: this.selectedSuperCategory,
      category: category,
      subCategory: subCategory,
    });
  }

  public isValid(): boolean {
    return (
      (this.selectedSuperCategoryId && !this.needsFullNomenclature) ||
      (!!this.selectedCategoryId && !!this.selectedSubCategoryId)
    );
  }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Events } from "src/app/services/events.service";
import { ChaptersGroup } from "src/app/services/roadmap-chapters.service";
import { Perimeter } from "src/app/structs/assets";
import { IonItemSliding } from "@ionic/angular";
import { Picture } from "@structs";
import { Router } from "@angular/router";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "legend-item",
  templateUrl: "./legend-item.component.html",
  styleUrls: ["./legend-item.component.scss"],
})
export class LegendItemComponent {
  @Input() public picture: Picture = null;
  @Input() public legend: string;
  @Input() public readOnly: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public showArrow: boolean = false;
  @Input() public lines: string = "full";
  @Input() public disabledLegend: boolean = false;
  @Input() public itemOptions: Array<object> = null;
  @Input() public index: number = 0;
  @Input() public color: string = "";
  @Input() public labelClass: string = "text-style2";
  @Input() public backgroundColor: string = "";
  @Input() public isRoadmap: boolean = false;
  // To add padding to this item, just send "start", "end", "top", or "bottom"
  @Input() public padding: string = "";
  @Input() public isRefData: boolean = false;

  @Input() public showChevronButtons: boolean = false;
  @Input() public hasChildren: boolean = false;
  @Input() public isChild: boolean = false;
  @Input() public isCollapsed: boolean = false;
  @Input() public clearButton: boolean = false;
  @Output() public clear = new EventEmitter();

  // Roadmap chapters group
  @Input() public chaptersGroup: ChaptersGroup<Perimeter> = null;
  @Output() public itemClicked = new EventEmitter<any>();
  @Output() public pictureClicked = new EventEmitter<any>();
  @Output() public toggleCollapse = new EventEmitter();

  constructor(private event: Events, private router: Router) {}

  public itemEdit(item) {
    item.close();
    this.event.publish("itemEdit", this.index);
  }

  public itemDelete(item) {
    item.close();
    this.event.publish("itemDelete", this.index);
  }

  public itemAdd(item) {
    item.close();
    let data = {};
    if (this.chaptersGroup && this.chaptersGroup.group) {
      data = this.chaptersGroup.group;
    }
    this.event.publish("itemAdd", data);
  }

  onItemClicked() {
    this.itemClicked.emit();
  }

  unlink(item: IonItemSliding) {
    console.warn("This function isn't working");
  }

  public toggleMultiPerimeterChildren(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toggleCollapse.emit();
  }

  public redirect(item: IonItemSliding) {
    item.close();
    this.router.navigate([this.itemOptions["redirect"].location]);
  }

  public clearClicked(item: IonItemSliding, event) {
    event.preventDefault();
    event.stopPropagation();
    item.close();
    this.clear.emit();
  }
}

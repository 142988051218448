import { Injectable } from "@angular/core";
import * as Sentry from "sentry-cordova";
import { Severity } from "sentry-cordova";

import { combineLatest, Observable, Observer, ReplaySubject } from "rxjs";

import { Asset, makeAsset, makeBuilding, NotationQuestionPicture, Perimeter } from "../structs/assets";
import { AuditResultItem } from "../structs/audit";
import { makeInvestment } from "../structs/investments";
import { Change, ChangeAction, SynchronizationState, SynchronizationStatus } from "@structs/synchronization";

import { AuthService } from "./auth.service";
import { BackendService } from "./backend.service";
import { ErrorsService } from "./errors.service";
import { OfflineService } from "./offline.service";
import { PicturesService } from "./pictures.service";
import { ScopeService } from "./scope.service";
import { SynthesisService } from "./synthesis.service";
import { DiagnosticService } from "@services/diagnostic.service";
import { Environment } from "../app.environment";
import { changesKey } from "../constants";

const DISABLE_SYNC_DELAY_ON_ERROR: number = 2 * 60; // 2 minutes

function getTimestampInSeconds(): number {
  let now = new Date();
  return now.getTime() / 1000;
}

@Injectable()
export class SynchronizationService {
  public forceRefresh: boolean = false; // indicate that perimeter-list should reload everything

  /** inform of progress of synchronization */
  // private synchronizationStateObserver: Observer<SynchronizationState> = null;
  private synchronizationStateObserver$: ReplaySubject<SynchronizationState> = new ReplaySubject<SynchronizationState>(
    1
  );
  private synchronizationStateObservable: Observable<SynchronizationState> = null;

  private networkOkForSynchronizationObserver: Observer<string> = null;
  private networkOkForSynchronizationObservable: Observable<string> = null;

  private synchronizationStarted: boolean = false;
  private lastSynchronizationError: number = 0;
  private _changesLock: boolean = false;

  constructor(
    private authService: AuthService,
    private backend: BackendService,
    private errors: ErrorsService,
    private offline: OfflineService,
    private picturesService: PicturesService,
    private scope: ScopeService,
    private synthesis: SynthesisService,
    private diagnostic: DiagnosticService
  ) {
    // this.synchronizationStateObservable = Observable.create(
    //   (observer: Observer<SynchronizationState>) => {
    //     this.synchronizationStateObserver = observer;
    //   }
    // ).publish().refCount();

    // this.synchronizationStateObservable = new Observable(
    //   (observer: Observer<SynchronizationState>) => {
    //     this.synchronizationStateObserver = observer;
    //   }
    // );

    this.networkOkForSynchronizationObservable = new Observable((observer: Observer<string>) => {
      this.networkOkForSynchronizationObserver = observer;
    });
  }

  /**
   * Be informed when something happen on synchronization : error, success, new element added ...
   * @returns {Observable<string>}
   */
  // public watchSynchronizationState(): Observable<SynchronizationState> {
  //   return this.synchronizationStateObservable;
  // }

  public watchSynchronizationState(): ReplaySubject<SynchronizationState> {
    if (!this.synchronizationStateObserver$) {
      this.synchronizationStateObserver$ = new ReplaySubject(1);
    }
    return this.synchronizationStateObserver$;
  }

  /**
   * Be informed when the network connection is Ok
   * @returns {Observable<string>}
   */
  public watchForNetworkReadyForSynchronization(): Observable<string> {
    return this.networkOkForSynchronizationObservable;
  }

  /**
   * inform others when the network connection is Ok
   * @returns {Observable<string>}
   */
  public signalNetworkReadyForSynchronization(networkType): void {
    if (this.networkOkForSynchronizationObserver) {
      this.networkOkForSynchronizationObserver.next(networkType);
    }
  }

  /**
   * add a new change to the list
   */
  public addChange(change: Change): Observable<any> {
    return new Observable(observer => {
      console.info("SYNCHRONIZATION_ADD_CHANGE", {
        change: Change.toLogObject(change),
      });
      this._tryAddChange(change, observer);
    });
  }

  /**
   * remove a change from a list
   * @param change
   */
  public removeChange(change: Change): Observable<Change[]> {
    return new Observable(observer => {
      console.info("SYNCHRONIZATION_REMOVE_CHANGE", {
        change: Change.toLogObject(change),
      });
      this._tryRemoveChange(change, observer);
    });
  }

  /**
   * get all changes
   */
  public getChanges(mustLock: boolean = true): Observable<Change[]> {
    return new Observable(observer => {
      this._tryGetChanges(mustLock, observer);
    });
  }

  /**
   * Store an asset after synchronization
   * @param {Asset} asset
   * @param {any} data
   * @returns {Observable<Asset>}
   */
  public forceAddAsset(asset: Asset, data: any): Observable<Asset> {
    return new Observable((observer: Observer<Asset>) => {
      this.backend.post("/audit/api/force-add-asset/", data).subscribe(
        jsonData => {
          asset.id = jsonData.id;
          // update investment ids
          for (let i = 0; i < jsonData.investments.length; i++) {
            for (let j = 0; j < asset.investments.length; j++) {
              let jsonElt = jsonData.investments[i];
              let investment = asset.investments[j];
              if (investment.localId === jsonElt.local_id) {
                investment.id = jsonElt.id;
              }
            }
          }
          this.offline.storeAsset(asset).subscribe(
            () => {
              let notes: Array<AuditResultItem> = jsonData.notes;
              this.synthesis.updateAssetNoteInSynthesis(asset, notes).subscribe(
                () => {
                  observer.next(asset);
                  observer.complete();
                },
                err => {
                  observer.next(asset);
                  observer.complete();
                }
              );
            },
            err => {
              observer.error(err);
              observer.complete();
            }
          );
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   *
   * @param {boolean} silent
   * @param {boolean} forced
   * @returns {Observable<boolean>} : true if all changes processed
   */
  public pushOfflineChanges(silent: boolean = false, forced: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      console.log("->synchronizationStarted", this.synchronizationStarted);
      if (this.synchronizationStarted) {
        observer.next(false);
        observer.complete();
      } else {
        // If an error occurred, wait for at least 2 minutes before a new try
        let currentTimeInSeconds: number = getTimestampInSeconds();
        let timeSinceLastError = currentTimeInSeconds - this.lastSynchronizationError;
        if (!forced && timeSinceLastError < DISABLE_SYNC_DELAY_ON_ERROR) {
          observer.next(false);
          observer.complete();
        } else {
          // Create an observable/observer to synchronize elements one by one
          let eltDoneObserver: Observer<boolean> = null;
          let eltDoneObservable: Observable<boolean> = new Observable(
            (obs: Observer<boolean>) => {
              eltDoneObserver = obs;
            }
            // (err) => {
            //   this.errors.signalError(err);
            // }
          );

          let allChangesProcessed = false;
          // Subscribe to the observable for handling every items
          eltDoneObservable.subscribe(
            (dummy: boolean) => {
              this.getChanges().subscribe(
                changesToProcess => {
                  if (changesToProcess.length === 0) {
                    allChangesProcessed = true;
                    this._signalSynchronizationDone();
                    eltDoneObserver.complete();
                  } else {
                    let change: Change = changesToProcess[0];
                    this._synchronizeChange(change).subscribe(
                      (jsonData: any) => {
                        // release to let UI refreshed
                        setTimeout(() => {
                          this._tryUpdatesChanges(change, jsonData, eltDoneObserver);
                        }, 100);
                      },
                      err => {
                        this._signalSynchronizationPostponed(change);
                      }
                    );
                  }
                },
                err => {
                  this.errors.signalError(err);
                }
              );
            },
            err => {},
            () => {
              observer.next(allChangesProcessed);
              observer.complete();
            }
          );

          this._signalStartSynchronization();
          eltDoneObserver.next(true);
        }
      }
    });
  }

  public signalOfflineChanges(silent: boolean = false): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!this.synchronizationStateObserver$) {
        this.synchronizationStateObserver$ = new ReplaySubject(1);
      }
      this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.PUSH, null));
      observer.next(true);
      observer.complete();
    });
  }

  public makeSynchronizationVerbose(): void {
    if (!this.synchronizationStateObserver$) {
      this.synchronizationStateObserver$ = new ReplaySubject(1);
    }
    this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.VERBOSE, null));
  }

  private _lockChanges(): void {
    this._changesLock = true;
  }

  private _unlockChanges(): void {
    this._changesLock = false;
  }

  private _areChangesLocked(): boolean {
    return this._changesLock;
  }

  private _doAddChange(change: Change, observer): void {
    this._lockChanges();
    this.offline.getItem(changesKey, false).subscribe(
      (changes: Array<Change>) => {
        if (changes) {
          // Update the list of change with the updated asset
          if (change.asset) {
            for (let i = 0, l = changes.length; i < l; i++) {
              let currentChange: Change = changes[i];
              if (currentChange.asset && change.asset.offlineId === currentChange.asset.offlineId) {
                currentChange.asset = change.asset;
              }
              // If the asset has a parent which correspond the asset offline id then
              // update the id
              if (
                currentChange.asset &&
                currentChange.asset.parent &&
                currentChange.asset.parent.id === 0 &&
                change.asset.offlineId === currentChange.asset.parent.offlineId
              ) {
                currentChange.asset.parent.id = change.asset.id;
              }
            }
          }
          changes.push(change);
        } else {
          changes = [change];
        }
        this.offline.storeItem(changesKey, changes).subscribe(
          () => {
            this.diagnostic.appendChange(change).then(() => console.debug("archived change object"));
            this._signalSynchronizationAddElementToDo(change);
            this._unlockChanges();
            observer.next(change);
            observer.complete();
          },
          err => {
            this._unlockChanges();
            observer.error(err);
            observer.complete();
          }
        );
      },
      err => {
        this._unlockChanges();
        observer.error(err);
        observer.complete();
      }
    );
  }

  private _tryAddChange(change: Change, observer): void {
    if (this._areChangesLocked()) {
      // not allowed to add a new change to the list. Wait for a short time and retry
      setTimeout(() => {
        this._tryAddChange(change, observer);
      }, 50);
    } else {
      this._doAddChange(change, observer);
    }
  }

  private _tryRemoveChange(change: Change, observer): void {
    if (this._areChangesLocked()) {
      // not allowed to add a new change to the list. Wait for a short time and retry
      setTimeout(() => {
        this._tryRemoveChange(change, observer);
      }, 50);
    } else {
      this._doRemoveChange(change, observer);
    }
  }

  private _doRemoveChange(change: Change, observer): void {
    this._lockChanges();
    this.offline.getItem(changesKey, false).subscribe(
      changes => {
        if (changes) {
          let changeIds = changes.map((elt: Change) => {
            return elt.timestamp;
          });
          let index = changeIds.indexOf(change.timestamp);
          if (index >= 0 && index < changes.length) {
            let foundChange = changes[index];
            changes.splice(index, 1);
            if (changes.length === 0) {
              this.synchronizationStarted = false;
            }
            this.offline.storeItem(changesKey, changes).subscribe(
              () => {
                this._signalSynchronizationRemoveElementDone(foundChange, SynchronizationStatus.CHANGE_DELETED);
                this._unlockChanges();
                observer.next(changes);
                observer.complete();
              },
              err => {
                this._unlockChanges();
                observer.error(err);
                observer.complete();
              }
            );
          }
        } else {
          this._unlockChanges();
          observer.error("Change Not found" + change.localId);
          observer.complete();
        }
      },
      err => {
        this._unlockChanges();
        observer.error(err);
        observer.complete();
      }
    );
  }

  /**
   * store full config
   */
  private _storeChanges(changes: Change[]): Observable<any> {
    return new Observable(observer => {
      this.offline.storeItem(changesKey, changes).subscribe(
        (changes: Change[]) => {
          observer.next(changes);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  private _tryGetChanges(mustLock: boolean, observer): void {
    if (mustLock && this._areChangesLocked()) {
      // not allowed to add a new change to the list. Wait for a short time and retry
      setTimeout(() => {
        this._tryGetChanges(mustLock, observer);
      }, 50);
    } else {
      this._doGetChanges(observer);
    }
  }

  private _doGetChanges(observer): void {
    this.offline.getItem(changesKey, false).subscribe(
      (changes: Change[]) => {
        let nextValue = changes ? changes : [];
        observer.next(nextValue);
        observer.complete();
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  // Start a synchronization
  private _signalStartSynchronization(): void {
    if (!this.synchronizationStarted) {
      this.synchronizationStarted = true;
      if (!this.synchronizationStateObserver$) {
        this.synchronizationStateObserver$ = new ReplaySubject(1);
      }
      this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.STARTED, null));
    }
  }

  // Called when the synchronization is finished
  private _signalSynchronizationDone(): void {
    if (this.synchronizationStarted) {
      this.synchronizationStarted = false;
      if (!this.synchronizationStateObserver$) {
        this.synchronizationStateObserver$ = new ReplaySubject(1);
      }
      this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.DONE, null));
    }
  }

  // Called when the synchronization can not be done for network error
  private _signalSynchronizationPostponed(change: Change): void {
    if (this.synchronizationStarted) {
      this.lastSynchronizationError = getTimestampInSeconds();
      this.synchronizationStarted = false;
      if (!this.synchronizationStateObserver$) {
        this.synchronizationStateObserver$ = new ReplaySubject(1);
      }
      this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.POSTPONED, change));
    }
  }

  // Called when a new change needs to be synchronized
  private _signalSynchronizationAddElementToDo(change: Change): void {
    if (!this.synchronizationStateObserver$) {
      this.synchronizationStateObserver$ = new ReplaySubject(1);
    }
    this.synchronizationStateObserver$.next(new SynchronizationState(SynchronizationStatus.CHANGE_TO_DO_ADDED, change));
  }

  // Called when a change has been synchronized
  private _signalSynchronizationRemoveElementDone(change: Change, status: SynchronizationStatus): void {
    if (!this.synchronizationStateObserver$) {
      this.synchronizationStateObserver$ = new ReplaySubject(1);
    }
    this.synchronizationStateObserver$.next(new SynchronizationState(status, change));
  }

  /**
   * Patch all incoming changes after an addAsset success
   * @param change : object to patch
   * @param offlineId : offlineId of the created asset
   * @param assetId : new id of the created asset
   * @returns {Change} : the patched object
   * @private
   */
  private _patchChangeAfterAddAssetSync(change: Change, offlineId: number, assetId: number): Change {
    if (change.assetOfflineId && change.assetOfflineId === offlineId) {
      if (change.type === ChangeAction.saveAssetAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
      } else if (change.type === ChangeAction.setAuditNoteAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
      } else if (change.type === ChangeAction.deleteAssetAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
      } else if (change.type === ChangeAction.addAssetPictureAction) {
        change.data.asset = assetId;
      } else if (change.type === ChangeAction.addInvestmentAction) {
        change.data.asset = assetId;
      } else if (change.type === ChangeAction.assetAccessAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
      } else if (change.type === ChangeAction.setAuditNoteAfterInvestmentAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
      } else if (change.type === ChangeAction.attachInvestmentAction) {
        change.data["asset_id"] = assetId;
      } else if (change.type === ChangeAction.setAuditExpertModeAction) {
        change.data["asset"] = assetId;
      } else if (change.type === ChangeAction.addAuditQuestionPictureAction) {
        change.url = change.url.replace("/0/", "/" + assetId + "/");
        change.data["asset"] = assetId;
      }

      // change.asset.offlineId = 0;
      change.asset.id = assetId;
      change.asset.offline = false;
      for (let investment of change.asset.investments) {
        investment.assetId = assetId;
        investment.assetOffline = false;
      }
    }

    // The newly created asset may have been used as parent of other offline assets
    // We need to patch the changes with the new if of the parent
    if (change.type === ChangeAction.addAssetAction && change.asset.parent !== null) {
      if (change.asset.parent.id === 0 && change.asset.parent.offlineId === offlineId) {
        change.asset.parent.id = assetId;
        change.asset.offline = false;
        change.data.parent = assetId;
      }
    }

    return change;
  }

  /**
   * Patch all incoming changes after an addInvestment success
   * @param change : object to patch
   * @param assetId : id of the asset of the investment
   * @param investmentId: new investment id
   * @param localId : id for offline investments
   * @returns {Change}
   * @private
   */
  private _patchChangeAfterAddInvestmentSync(
    change: Change,
    assetId: number,
    investmentId: number,
    localId: string,
    investment: any
  ): Change {
    Sentry.addBreadcrumb({
      category: "sync",
      level: Severity.Info,
      message: "PatchChangeAfterAddInvestmentSync",
      data: investment,
    });
    let changeAssetId = change.asset ? change.asset.id : 0;
    if (changeAssetId === assetId && change.localId === localId) {
      if (change.type === ChangeAction.deleteInvestmentAction) {
        change.url = change.url.replace("/0/", "/" + investmentId + "/");
      } else if (change.type === ChangeAction.saveInvestmentAction) {
        change.url = change.url.replace("/0/", "/" + investmentId + "/");
        change = this._patchChangeInvestmentSlices(change, assetId, localId, investment);
      }
    }

    if (change.type === ChangeAction.saveDocumentDefaultsAction) {
      change.url = change.url.replace("/0/", "/" + investmentId + "/");
    }

    if (change.type === ChangeAction.addTaskAction || change.type === ChangeAction.addInvestmentPictureAction) {
      if (change.data.investment === 0) {
        change.data.investment = investmentId;
      }
    }

    return change;
  }

  /**
   * Patch all incoming changes after an addAssetPicture success
   * @param change : object to patch
   * @param assetPictureId : id of the asset picture
   * @returns {Change}
   * @private
   */
  private _patchChangeAfterAddAssetPictureSync(change: Change, assetPictureId: number): Change {
    if (change.type === ChangeAction.deleteAssetPictureAction) {
      change.url = change.url.replace("/0/", "/" + assetPictureId + "/");
    }

    return change;
  }

  /**
   * Patch all incoming changes after an addInvestmentPicture success
   * @param change : object to patch
   * @param assetPictureId : id of the investment picture
   * @returns {Change}
   * @private
   */
  private _patchChangeAfterAddInvestmentPictureSync(change: Change, investmentPictureId: number): Change {
    if (change.type === ChangeAction.deleteInvestmentPictureAction) {
      change.url = change.url.replace("/0/", "/" + investmentPictureId + "/");
    }

    return change;
  }

  /**
   * Patch all incoming changes after an addPerimeterPicture success
   * @param change : object to patch
   * @param assetPictureId : id of the perimeter picture
   * @returns {Change}
   * @private
   */
  private _patchChangeAfterAddPerimeterPictureSync(change: Change, perimeterPictureId: number): Change {
    if (change.type === ChangeAction.deletePerimeterPictureAction) {
      change.url = change.url.replace("/0/", "/" + perimeterPictureId + "/");
    }

    return change;
  }

  /**
   * Patch all incoming changes after an addTask success
   * @param change object to patch
   * @param taskId real id of the task
   * @param localTaskId local id of the task
   *
   * @returns {Change}
   */
  private _patchChangeAfterAddTaskSync(change: Change, taskId: number, localTaskId: string): Change {
    if (
      (change.type === ChangeAction.saveTaskAction || change.type === ChangeAction.deleteTaskAction) &&
      change.data.local_id === localTaskId
    ) {
      change.url = change.url.replace("/0/", "/" + taskId + "/");
    }
    return change;
  }

  /**
   * Patch the investment slices/spending slices ids to handle subsequent changes on them.
   * @param change Change to patch
   * @param assetId : id of the asset of the investment
   * @param localId : id for offline investments
   * @param investment The investment data returned by the api
   */
  private _patchChangeInvestmentSlices(change: Change, assetId: number, localId: string, investment: any): Change {
    let changeAssetId = change.asset ? change.asset.id : 0;

    if (changeAssetId === assetId && change.localId === localId) {
      if (change.data.slices) {
        for (const investmentSlice of investment.slices) {
          const changeSliceIndex = change.data.slices.findIndex(slice => {
            return slice.status.id === investmentSlice.status.id && slice.year === investmentSlice.year;
          });
          if (changeSliceIndex > -1) {
            change.data.slices[changeSliceIndex].id = investmentSlice.id;
          }
        }
      }

      if (change.data.spending_slices) {
        for (const investmentSpendingSlice of investment.spending_slices) {
          const changeSpendingSliceIndex = change.data.spending_slices.findIndex(spendingSlice => {
            return spendingSlice.year === investmentSpendingSlice.year;
          });
          if (changeSpendingSliceIndex > -1) {
            change.data.spending_slices[changeSpendingSliceIndex].id = investmentSpendingSlice.id;
          }
        }
      }
    }
    return change;
  }

  /**
   * Patch all incoming changes after an addMonoPerimeter success
   * @param change object to patch
   * @param perimetersMap
   * @returns {Change}
   */
  private _patchChangeAfterAddMonoPerimeterSync(change: Change, perimetersMap: any): Change {
    if (change.type === ChangeAction.addAssetAction && change.data.perimeter === -1) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      let building = perimetersMap.buildings[change.perimeterLocalId] || null;
      if (perimeterId) {
        change.data.perimeter = perimeterId;
        change.asset.perimeters = [perimeterId];
      }
      if (building) {
        change.asset.building = building;
      }
    }

    if (change.type === ChangeAction.addInvestmentAction && change.data.building === -1) {
      let building = perimetersMap.buildings[change.perimeterLocalId] || null;
      if (building) {
        change.data.building = building.id;
      }
    }

    if (
      (change.type === ChangeAction.saveInvestmentAction || change.type === ChangeAction.saveAssetAction) &&
      change.data.building === -1
    ) {
      let building = perimetersMap.buildings[change.perimeterLocalId] || null;
      if (building) {
        change.data.building = building.id;
      }
    }

    if (change.type === ChangeAction.setRoadmapAnswersAction && change.url.indexOf("/-1/") > 0) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.url = change.url.replace("/-1/", "/" + perimeterId + "/");
    }

    if (change.type === ChangeAction.saveControlPointAction && change.data.perimeter === -1) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.data.perimeter = perimeterId;
    }

    if (change.type === ChangeAction.addPerimeterPictureAction && change.data.perimeter === -1) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.data.perimeter = perimeterId;
    }

    if (change.type === ChangeAction.saveMonoPerimeterAction && change.url.indexOf("/-1/") > 0) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.url = change.url.replace("/-1/", "/" + perimeterId + "/");
    }

    if (change.type === ChangeAction.saveReferenceDataAction && change.url.indexOf("/-1/") > 0) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.url = change.url.replace("/-1/", "/" + perimeterId + "/");
    }

    if (change.type === ChangeAction.addMonoPerimeterAction && change.perimeterLocalId) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.data.level_parent = perimeterId;
      if (perimeterId) {
        change.data.level_parent_local = change.perimeterLocalId;
      }
    }

    if (change.type === ChangeAction.deleteMonoPerimeterAction && change.perimeterLocalId) {
      let perimeterId = perimetersMap.perimeters[change.perimeterLocalId] || 0;
      change.url = change.url.replace("/-1/", "/" + perimeterId + "/");
    }

    return change;
  }

  /**
   * Store an asset after synchronization
   * @param {Asset} asset
   * @returns {Observable<boolean>}
   */
  private storeUpdatedAsset(asset: Asset): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      this.offline.loadAsset(asset.id, asset.offlineId).subscribe(
        existingAsset => {
          // A parent asset can have children which are not created yet (still offline)
          // The updated asset  (comes from backend) doesn't know these children.
          // We need to recreate the offline children on the parent to avoid
          // "asset not found" error in some situation (auto-create parent for example)
          const offlineChildren = existingAsset.children.filter(elt => elt.id === 0);
          for (let child of offlineChildren) {
            const currentChildrenIds = asset.children.map(elt => +elt.offlineId).filter(elt => elt > 0);
            if (child.offlineId && currentChildrenIds.indexOf(+child.offlineId) < 0) {
              // the child is not existing on the updated asset : add it
              asset.children.push(child);
            }
          }
          this.offline.storeAsset(asset).subscribe(
            () => {
              observer.next(true);
              observer.complete();
            },
            err => {
              observer.next(true);
              observer.complete();
            }
          );
        },
        err => {
          // asset not found : continue
          observer.next(true);
          observer.complete();
        }
      );
    });
  }

  /**
   *
   * @param {Change} nextChange
   * @returns {Observable<boolean>}
   * @private
   */
  private _synchronizeChange(nextChange: Change): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      console.info("SYNCHRONIZATION_SYNCHRONIZE_CHANGE", {
        change: Change.toLogObject(nextChange),
      });
      // Call backend
      combineLatest(
        this.offline.getAssetIdsMap(),
        this.offline.getInvestmentIdsMap(),
        this.offline.getPerimeterOfflineMap(),
        this.authService.getCurrentUser()
      ).subscribe(([assetIdsMap, investmentIdsMap, perimetersOfflineMap, currentUser]) => {
        Sentry.addBreadcrumb({
          category: "sync",
          level: Severity.Info,
          message: "PatchChangeAfterAddInvestmentSync",
          data: {
            assetIdsMap,
            investmentIdsMap,
          },
        });
        let assetOfflineId: number = nextChange.asset ? nextChange.asset.offlineId : 0;
        let assetId: number = 0;
        if (assetOfflineId && assetOfflineId in assetIdsMap) {
          assetId = assetIdsMap[assetOfflineId];
          nextChange = this._patchChangeAfterAddAssetSync(nextChange, assetOfflineId, assetId);
        }

        let invLocalId: string = nextChange.investment ? nextChange.investment.localId : null;
        if (invLocalId && invLocalId in investmentIdsMap) {
          let invId: number = investmentIdsMap[invLocalId];
          nextChange = this._patchChangeAfterAddInvestmentSync(
            nextChange,
            assetId,
            invId,
            invLocalId,
            nextChange.investment
          );
        }

        nextChange = this._patchChangeAfterAddMonoPerimeterSync(nextChange, perimetersOfflineMap);

        console.info("SYNCHRONIZATION_SYNCHRONIZE_CHANGE_CALL_API", {
          change: Change.toLogObject(nextChange),
        });
        const startTime = Date.now();

        let response: Observable<any>;
        if (SynchronizationService.isHandledByPictureUploader(nextChange)) {
          response = this.picturesService.upload(nextChange);
        } else {
          response = this.backend.callApi(nextChange.method, nextChange.url, nextChange.data);
        }

        response.subscribe(
          (jsonData: any) => {
            console.info("SYNCHRONIZATION_SYNCHRONIZE_CHANGE_CALL_API_SUCCESS", {
              change: Change.toLogObject(nextChange),
              time: Date.now() - startTime,
            });
            observer.next(jsonData);
            observer.complete();
          },
          err => {
            // network error?
            if (err.status === 0 || this.picturesService.isNetworkError(err)) {
              console.error("SYNCHRONIZATION_SYNCHRONIZE_CHANGE_CALL_API_NETWORK_ERROR", {
                change: Change.toLogObject(nextChange),
                error: err,
              });
              // Not possible to synchronize
              // we will retry next time
              observer.error(err);
              observer.complete();
            } else {
              console.error("SYNCHRONIZATION_SYNCHRONIZE_CHANGE_CALL_API_GENERAL_ERROR", {
                change: Change.toLogObject(nextChange),
                error: err,
              });
              if (Environment.getSentryDSN()) {
                this.diagnostic.numberOfPendingChanges().then(numberOfPendingChanges => {
                  Sentry.setContext("PENDING_CHANGE", {
                    CurrentChange: nextChange,
                    error: err,
                    NUMBER_OF_PENDING_CHANGE: numberOfPendingChanges,
                  });
                });
              }
              // This is not a Network error : so drop it in order to avoid everything gets blocked
              // remove the change which has cause the error
              observer.error(err);
              observer.next(null);
              observer.complete();
            }
          }
        );
      });
    });
  }

  /**
   * The ACTIONS with picture upload is handled differently so we need to switch the
   * execution based on the type of Change
   * @param nextChange
   * @private
   */
  private static isHandledByPictureUploader(nextChange: Change): boolean {
    return [
      ChangeAction.addAssetPictureAction,
      ChangeAction.addInvestmentPictureAction,
      ChangeAction.addPerimeterPictureAction,
      ChangeAction.addAuditQuestionPictureAction,
    ].includes(nextChange.type);
  }

  /**
   * Utility method, add an auditNotationPicture to an asset
   * @param asset: Asset
   * @param auditNotationPicture: NotationQuestionPicture
   * @private
   */
  private addAuditNotationPicture(asset: Asset, auditNotationPicture: NotationQuestionPicture): Asset {
    const { notesPictures } = asset;
    for (const [key, arrayOfPictures] of Object.entries(notesPictures)) {
      if (key === auditNotationPicture.questionItemId.toString()) {
        // The local picture can already be in the list: look for the it and update with remote picture
        const index = arrayOfPictures.map(elt => elt.localId).indexOf(auditNotationPicture.localId);
        const obj = Object.assign({}, auditNotationPicture);
        if (index >= 0) {
          arrayOfPictures[index] = obj;
        } else {
          arrayOfPictures.push(arrayOfPictures);
        }
      }
    }
    return { ...asset, notesPictures: notesPictures };
  }

  private _doUpdatesChanges(processedChange: Change, jsonData: any, eltDoneObserver): void {
    // Some changes need to be patched. For example a change of a field of an offline asset
    // needs to be patched with the id of the asset to be successful

    // reload the changes because some new things may have been added during synchronization
    this._lockChanges(); // all will have to wait before adding new things to the changes
    this.getChanges(false).subscribe(
      (changesToPatch: Array<Change>) => {
        let investmentIds: Array<any> = [];
        let assetIds: Array<any> = [];
        let patchedChanges: Array<Change> = [];
        let onlineAsset: Asset = processedChange.asset;
        let assetToDelete = null;

        if (onlineAsset) {
          if (jsonData !== null) {
            if (processedChange.type === ChangeAction.addAssetAction) {
              onlineAsset = makeAsset(jsonData);
              // copy the notes : the newly created asset doesn't have the value yet
              onlineAsset.notes = processedChange.asset.notes;
              onlineAsset.ratingReasons = processedChange.asset.ratingReasons;
              onlineAsset.technical_state_changed_by = processedChange.asset.technical_state_changed_by;
              onlineAsset.technical_state_changed_on = processedChange.asset.technical_state_changed_on;
              onlineAsset.offlineId = processedChange.assetOfflineId;
              assetIds.push({
                localId: processedChange.assetOfflineId,
                assetId: jsonData.id,
              });
            } else if (
              processedChange.type === ChangeAction.addAssetPictureAction ||
              processedChange.type === ChangeAction.deleteAssetPictureAction
            ) {
              // Warning: for picture jsonData is not really json data it's already deserialized data
              // In this case it's an AssetPicture
              onlineAsset.pictures = onlineAsset.pictures.reduce(
                (pictures, picture) => [
                  ...pictures,
                  ...(picture.localId === processedChange.localId
                    ? processedChange.type === ChangeAction.addAssetPictureAction
                      ? [jsonData]
                      : []
                    : [picture]),
                ],
                []
              );
            } else if (
              processedChange.type === ChangeAction.addInvestmentAction ||
              processedChange.type === ChangeAction.saveInvestmentAction
            ) {
              // Update asset investments
              const updatedInvestment = makeInvestment(jsonData);
              const existingInvestmentIndex = onlineAsset.investments.findIndex(investment => {
                return investment.localId === updatedInvestment.localId || investment.id === updatedInvestment.id;
              });
              if (existingInvestmentIndex > -1) {
                onlineAsset.investments[existingInvestmentIndex] = updatedInvestment;
              } else {
                onlineAsset.investments.push(updatedInvestment);
              }

              if (processedChange.type === ChangeAction.addInvestmentAction) {
                investmentIds.push({
                  localId: jsonData.local_id,
                  investmentId: jsonData.id,
                });
              }
            } else if (
              processedChange.type === ChangeAction.addInvestmentPictureAction ||
              processedChange.type === ChangeAction.deleteInvestmentPictureAction
            ) {
              // We update the picture of investment in the asset to keep offline data up to date
              // Warning: for picture jsonData is not really json data it's already deserialized data
              // In this case it's an InvestmentPicture
              const existingInvestmentIndex = onlineAsset.investments.findIndex(investment => {
                return investment.id === jsonData.investment;
              });
              if (existingInvestmentIndex > -1) {
                onlineAsset.investments[existingInvestmentIndex].pictures = onlineAsset.investments[
                  existingInvestmentIndex
                ].pictures.reduce(
                  (pictures, picture) => [
                    ...pictures,
                    ...(picture.localId === jsonData.localId
                      ? processedChange.type === ChangeAction.addInvestmentPictureAction
                        ? [jsonData]
                        : []
                      : [picture]),
                  ],
                  []
                );
              }
            }
          }
          if (processedChange.type === ChangeAction.deleteAssetAction) {
            assetToDelete = onlineAsset;
          }
        } else {
          if (processedChange.type === ChangeAction.addMonoPerimeterAction) {
            const perimeterId = jsonData.id;
            const localId = processedChange.data.local_id;
            const building = makeBuilding(jsonData.building);
            const parentId = jsonData.parentId;
            // add to local map
            this.offline.getPerimeterOfflineMap().subscribe((data: any) => {
              data.perimeters[localId] = perimeterId;
              data.buildings[localId] = building;
              this.offline.setPerimeterOfflineMap(data).subscribe();
            });
            // patch mainPerimeter
            this.scope.getCurrentMultiPerimeter().subscribe((mainPerimeter: Perimeter) => {
              if (mainPerimeter.id === parentId) {
                // look for the new perimeter in existing perimeters
                for (let monoPerimeter of mainPerimeter.sub_perimeters) {
                  if (monoPerimeter.localId === localId) {
                    monoPerimeter.id = perimeterId;
                    monoPerimeter.building = building;
                    monoPerimeter.building_id = building.id;
                    break;
                  }
                }
                // patch the perimeter
                this.scope.setSelectedPerimeter(mainPerimeter).subscribe(() => {
                  this.scope.setCurrentMultiPerimeter(mainPerimeter).subscribe();
                  this.offline.renameGlobalInvestmentsAfterSync(building.id, localId).subscribe();
                });
              }
            });
          }

          if (processedChange.type === ChangeAction.addInvestmentAction) {
            // Update investments Map. It allows to retrieve the id from local_id
            // For investments patched before investment creation synchronization
            // we just store the local id because we don't know the DB id yet
            // then we use the map to get the DB id before calling the API
            investmentIds.push({
              localId: jsonData.local_id,
              investmentId: jsonData.id,
            });
            const updatedInvestment = makeInvestment(jsonData);
            this.offline.storeInvestments([updatedInvestment]).subscribe();
          }

          // Before synchronization the photo is a local image.
          // It may be interesting to replace it by the remote image (on server)
          // But we must manage it properly and the remote picture should remove the correspondiong local photo
          // in order to avoid duplicates

          if (processedChange.type === ChangeAction.addAuditQuestionPictureAction) {
            // update auditquestionpicture in the local storage asset
            const { data } = processedChange;
            const { assetId, questionItemId } = <NotationQuestionPicture>data,
              { id, localId, picture, thumbnail } = jsonData;
            const newAuditQuestionPicture = new NotationQuestionPicture(
              id,
              assetId,
              questionItemId,
              picture,
              thumbnail,
              null,
              null,
              localId,
              null
            );
            this.offline.getAsset(assetId).subscribe(asset => {
              const updatedAsset = this.addAuditNotationPicture(asset, newAuditQuestionPicture);
              this.offline.storeAsset(updatedAsset).subscribe();
            });
          }
        }

        if (processedChange.type === ChangeAction.saveControlPointAction) {
          console.log("TODO: Roadmap");
          // TODO: Roadmap
          // // update the locally stored control point to have its actual id
          // const controlPoint : ControlPoint = processedChange.data;
          // const perimeterId: number = processedChange.data.roadmapPerimeterId;

          // if (perimeterId != null && controlPoint.local_id && jsonData != null) {
          //   let fullKey = 'roadmap:' + perimeterId;
          //   this.offline.getItem(fullKey, true).subscribe(
          //     (roadmapData: any) => {
          //       // cast storeData to RoadMap class
          //       let roadmap = Object.assign(new Roadmap(), roadmapData);
          //       const controlPointIndex = roadmap.controlPoints[controlPoint.perimeter].findIndex(
          //         (cp) => cp.local_id === controlPoint.local_id,
          //       );

          //       if (controlPointIndex !== -1) {
          //         roadmap.controlPoints[controlPoint.perimeter][controlPointIndex].id = jsonData.id;
          //         this.offline.storeItem(fullKey, roadmap).subscribe();
          //       }
          //     }
          //   );
          // }
        }

        if (processedChange.type === ChangeAction.addTaskAction) {
          console.log("TODO: patchTask()");
          // this.offline.patchTask(jsonData.local_id, jsonData.id).subscribe();
        }

        // Patch the list of changes. remove the processed change
        for (let i = 0, l = changesToPatch.length; i < l; i++) {
          let removeThisOne: boolean = false;
          let currentChange: Change = changesToPatch[i];
          if (currentChange.timestamp === processedChange.timestamp) {
            removeThisOne = true;
            this._signalSynchronizationRemoveElementDone(
              processedChange,
              jsonData === null ? SynchronizationStatus.CHANGE_DELETED : SynchronizationStatus.CHANGE_DONE
            );
          }

          if (!removeThisOne && jsonData) {
            let patchedChange: Change = currentChange;
            if (processedChange.type === ChangeAction.addAssetAction) {
              patchedChange = this._patchChangeAfterAddAssetSync(
                currentChange,
                processedChange.assetOfflineId,
                jsonData.id
              );
            } else if (processedChange.type === ChangeAction.addInvestmentAction) {
              patchedChange = this._patchChangeAfterAddInvestmentSync(
                currentChange,
                processedChange.asset ? processedChange.asset.id : 0,
                jsonData.id,
                jsonData.local_id,
                jsonData
              );
              investmentIds.push({
                localId: jsonData.local_id,
                investmentId: jsonData.id,
              });
            } else if (processedChange.type === ChangeAction.saveInvestmentAction) {
              patchedChange = this._patchChangeInvestmentSlices(
                currentChange,
                processedChange.asset ? processedChange.asset.id : 0,
                jsonData.local_id,
                jsonData
              );
            } else if (processedChange.type === ChangeAction.addAssetPictureAction) {
              patchedChange = this._patchChangeAfterAddAssetPictureSync(currentChange, jsonData.id);
            } else if (processedChange.type === ChangeAction.addInvestmentPictureAction) {
              patchedChange = this._patchChangeAfterAddInvestmentPictureSync(currentChange, jsonData.id);
            } else if (processedChange.type === ChangeAction.addPerimeterPictureAction) {
              patchedChange = this._patchChangeAfterAddPerimeterPictureSync(currentChange, jsonData.id);
            } else if (processedChange.type === ChangeAction.addTaskAction) {
              patchedChange = this._patchChangeAfterAddTaskSync(currentChange, jsonData.id, jsonData.local_id);
            }
            patchedChanges.push(patchedChange);
          }
        }

        // Update the list of changes
        this._storeChanges(patchedChanges).subscribe(
          () => {
            this.offline.addToAssetIdsMap(assetIds).subscribe(
              () => {
                this.offline.addToInvestmentIdsMap(investmentIds).subscribe(
                  () => {
                    if (assetToDelete) {
                      this._unlockChanges(); // release the lock
                      eltDoneObserver.next(true);
                    } else {
                      if (onlineAsset) {
                        this.storeUpdatedAsset(onlineAsset).subscribe(
                          () => {
                            this._unlockChanges(); // release the lock
                            eltDoneObserver.next(true);
                          },
                          err => {
                            this._unlockChanges(); // release the lock
                            eltDoneObserver.error(err);
                          }
                        );
                      } else {
                        this._unlockChanges(); // release the lock
                        eltDoneObserver.next(true);
                      }
                    }
                  },
                  err => {
                    this._unlockChanges(); // release the lock
                    eltDoneObserver.error(err);
                  }
                );
              },
              err => {
                this._unlockChanges(); // release the lock
                eltDoneObserver.error(err);
              }
            );
          },
          err => {
            this._unlockChanges(); // release the lock
            eltDoneObserver.error(err);
          }
        );
      },
      err => {
        this._unlockChanges(); // release the lock
        eltDoneObserver.error(err);
      }
    );
  }

  private _tryUpdatesChanges(processedChange: Change, jsonData: any, eltDoneObserver): void {
    if (this._areChangesLocked()) {
      // not allowed to add a new change to the list. Wait for a short time and retry
      setTimeout(() => {
        this._tryUpdatesChanges(processedChange, jsonData, eltDoneObserver);
      }, 50);
    } else {
      this._doUpdatesChanges(processedChange, jsonData, eltDoneObserver);
    }
  }
}

import { Perimeter } from "./assets";
import { Cluster } from "./cluster";
import { DataType, FilterType, PerimeterFilter, PerimeterPredicate } from "./perimeter-filter";

/**
 * Represent a perimeter filter on cluster.
 */
export class PerimeterClusterFilter extends PerimeterFilter {
  constructor(clusters: Cluster[], label: string) {
    super();

    this.label = label;
    this.filtering = FilterType.FILTER_VALUE;
    this.dataType = DataType.DATA_TYPE_FK;
    this.availableValues = clusters.map(cluster => {
      return {
        id: cluster.id,
        label: cluster.name,
      };
    });
  }

  public getPredicate(): PerimeterPredicate {
    return (perimeter: Perimeter) => {
      const perimCluster = this.selectedValues.find((value: Cluster) => value.id === perimeter.cluster);
      return perimCluster !== undefined;
    };
  }
}

import { Project } from "./projects";
import { Asset, Building, Perimeter, makeAsset, NotationQuestionPicture } from "./assets";
import { Investment, makeInvestment } from "./investments";

export const LEVELS_MAP = {
  1: 0,
  2: 6,
  3: 14,
  4: 22,
  5: 30,
  6: 38,
  7: 46,
  8: 54,
  9: 62,
  10: 70,
  11: 78,
  12: 86,
  13: 94,
  14: 100,
};

export const STATE_LIMITS = [0, 25, 50, 75, 99, 100];

export const STATE_LABELS = ["Dead", "Critical", "Bad", "Acceptable", "Good", "New"];

export interface NoteEvent {
  itemId: number;
  note: number | null;
  comment?: string | null;
}

export class AuditBuilding extends Building {
  constructor(
    public id: number,
    public name: string,
    public constructionYear: number,
    public siteName: string,
    public monosite_perimeter: Perimeter,
    public globalInvestments: Investment[] = []
  ) {
    super(id, name, constructionYear, siteName, monosite_perimeter);
  }
}

export class AuditLevel {
  constructor(public label: string, public name: string) {}
}

export class AuditState {
  constructor(public level: string, public name: string) {}
}

export class AuditSynthesis {
  constructor(
    public assets: Array<Asset>,
    public investments: Array<Investment>,
    public buildings: Array<AuditBuilding>,
    public levels: Array<AuditLevel>,
    public isBudgetClose: boolean,
    public isNextBudgetClose: boolean,
    public projects: Project[]
  ) {}
}

export function makeAuditBuildings(jsonBuildings: any): AuditBuilding[] {
  const auditBuildings: AuditBuilding[] = [];
  for (let jsonBuilding of jsonBuildings) {
    let building = new AuditBuilding(
      jsonBuilding.id,
      jsonBuilding.name,
      jsonBuilding.constructionYear,
      jsonBuilding.siteName,
      jsonBuilding.monosite_perimeter,
      jsonBuilding.flooring_area
    );
    for (let jsonInvestment of jsonBuilding.global_investments) {
      building.globalInvestments.push(makeInvestment(jsonInvestment));
    }
    auditBuildings.push(building);
  }
  return auditBuildings;
}

export function makeAuditSynthesis(jsonData: any): AuditSynthesis {
  let assets: Array<Asset> = [];
  let investments: Array<Investment> = [];

  jsonData.assets.forEach(assetData => {
    const asset = makeAsset(assetData);
    assets.push(asset);
    investments = investments.concat(asset.investments);
  });

  return new AuditSynthesis(
    assets,
    investments,
    makeAuditBuildings(jsonData.buildings),
    jsonData.levels,
    jsonData.is_budget_close,
    jsonData.is_next_budget_close,
    jsonData.projects || []
  );
}

/**
 * Partly corresponds to table: AuditQuestionItem
 */
export class AuditQuestionItem {
  constructor(
    public id: number,
    public year_number: number,
    public current_note: number, // TODO: This field is inconsistent with the backend
    public current_comment: string,
    public current_pictures?: NotationQuestionPicture[]
  ) {}
}

/**
 * Backend corresponding table: AuditQuestion
 */
export class AuditQuestion {
  constructor(
    public id: number,
    public text: string,
    public question_type: string,
    public help_text_subject: string,
    public help_text: string,
    public low_value_label: string,
    public high_value_label: string,
    public icon: string,
    public level: number,
    public mnemonic: string,
    public order: number,
    public mandatory: boolean,
    public items: Array<AuditQuestionItem>
  ) {}
}

export class AuditNotationItem {
  constructor(
    public question_item: number,
    public note: number,
    public mandatory: boolean,
    public rating_reasons: number[],
    public comment: string = null
  ) {}
}

export class AuditNotation {
  constructor(public notes: Array<AuditNotationItem>) {}
}

export class RatingReason {
  constructor(
    public id: number,
    public name: string,
    public only_for_states: number[],
    public default_for_states: number[],
    public ordering: number,
    public is_energivore: boolean
  ) {}
}

export class NotationState {
  constructor(public id: number, public low: number, public high: number) {}
}

export class AuditSynthesisChange {
  constructor(public perimeter: Perimeter, public year: number, public auditSynthesis: AuditSynthesis) {}
}

export class AuditResultItem {
  constructor(
    public year: number,
    public note: number,
    public level: string,
    public expectedLifetime: number,
    public levelHasChanged: boolean
  ) {}
}

export class AuditSearchCriteria {
  constructor(public name: string, public value: any) {}
}

export class PerimeterBudgetPlan {
  constructor(
    public id: number,
    public perimeter: number,
    public audit: number,
    public year: number,
    public isClosed: boolean,
    public closeDate: Date
  ) {}
}

export class AuditQuestionSection {
  constructor(
    public id: number,
    public name: string,
    public groupPermissions: number[],
    public auditQuestions: number[],
    public expertKpis: AuditQuestion[] = []
  ) {}

  public doesContain(auditQuestion: AuditQuestion) {
    return this.auditQuestions.indexOf(auditQuestion.id) >= 0;
  }

  public isAllowed(groupId: number) {
    return this.groupPermissions.indexOf(groupId) >= 0;
  }

  public addKpi(auditQuestion: AuditQuestion) {
    this.expertKpis.push(auditQuestion);
  }

  public isActive() {
    return this.expertKpis.length > 0;
  }
}

export function makePerimeterBudgetPlan(jsonData: any): PerimeterBudgetPlan {
  return new PerimeterBudgetPlan(
    jsonData.id,
    jsonData.perimeter,
    jsonData.audit,
    jsonData.year,
    jsonData.is_closed,
    jsonData.close_date ? new Date(jsonData.close_date) : null
  );
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function createNotationData(notes: any): any {
  let notationData = [];
  for (let key in notes) {
    if (notes.hasOwnProperty(key) && isNumeric(key)) {
      notationData.push({ question_item: key, note: notes[key] });
    }
  }
  return notationData;
}

export function makeNotation(jsonData): AuditNotation {
  return new AuditNotation(jsonData.notes);
}

export function makeRatingReason(jsonData: any): RatingReason {
  return new RatingReason(
    jsonData.id,
    jsonData.name,
    jsonData.only_for_states,
    jsonData.default_for_states,
    jsonData.ordering,
    jsonData.is_energivore
  );
}

export function makeNotationState(jsonData: any): NotationState {
  return new NotationState(jsonData.id, jsonData.low, jsonData.high);
}

export function makeAuditQuestionSection(jsonData: any): AuditQuestionSection {
  return new AuditQuestionSection(jsonData.id, jsonData.name, jsonData.group_permissions, jsonData.audit_questions);
}

import { Injectable } from "@angular/core";
import { AssetType, Perimeter } from "@structs";

/**
 * Providing context mapping for generate asset label.
 * Uses with KPI TemplateEngine
 */
export type AssetLabelGenerateContext = {
  asset_type: AssetType;
  perimeter: Perimeter;
};

@Injectable({
  providedIn: "root",
})
export class KPITemplateEngineService {
  constructor() {}

  generateAssetLabel(template: string, context: AssetLabelGenerateContext): string {
    let _template = template
      ? template
      : // friendly name can sometimes be a string with only blank spaces; if so, we force the regular name
      context.asset_type.friendly_name.trim() !== ""
      ? "${asset_type.friendly_name}"
      : "${asset_type.name}";

    return this.interpolate<AssetLabelGenerateContext>(_template, context);
  }

  /**
   * Thanks to Peter @ https://stackoverflow.com/questions/29182244/convert-a-string-to-a-template-string
   * @param input string
   * @param params an Object provides the context varialbes. See `.spec.ts` for more information
   * @returns
   */
  private interpolate<T>(input: string, params: T): string {
    const names = Object.keys(params);
    const values = Object.values(params);
    try {
      return new Function(...names, `return \`${input}\`;`)(...values) as string;
    } catch (error) {
      console.warn("could not interpolate string with corresponding parameters", input, params);
      return "";
    }
  }
}

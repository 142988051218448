<div
  class="picture-container {{ isSitePic ? null : 'ion-margin-end' }}"
  *ngIf="url$ | async as urls; else noPicture"
  [class.padding]="isSitePic">
  <ng-container *ngIf="picture && urls && picture.id !== -1; else noPicture">
    <ng-container *ngTemplateOutlet="thumbnail ? asThumbnail : asFullPicture; context: { urls: urls }"></ng-container>
  </ng-container>
</div>

<ng-template #noPicture>
  <div class="no-picture">
    <div class="content" *ngIf="picturesOn">
      {{ "No photo" | translate }}
    </div>
  </div>
</ng-template>

<ng-template #asFullPicture let-urls="urls">
  <ion-slides [options]="zoomOptions" zoom>
    <ion-slide>
      <img class="picture-default picture" [src]="urls.fullPicture" />
    </ion-slide>
  </ion-slides>
</ng-template>

<ng-template #asThumbnail let-urls="urls">
  <div
    *ngIf="!isSitePic"
    [style.background-image]="urls.thumbnail"
    class="thumbnail bg-picture"
    [attr.width]="thumbnail ? '50' : null"
    (error)="backupThumbnail({ el: $event, fullPicture: urls.fullPicture })"
    [style.background-size]="'cover'"></div>
  <div [ngClass]="selected && isSitePic ? 'selected-border' : ''">
    <div
      *ngIf="isSitePic"
      class="thumbnail bg-picture"
      [attr.width]="thumbnail ? '50' : null"
      [style.background-image]="urls.thumbnail"
      [style.background-color]="urls.isDefaultThumbnail ? '' : 'black'"
      [style.background-size]="'contain'"
      [style.background-position]="'center'"
      (error)="backupThumbnail({ el: $event, fullPicture: urls.fullPicture })"></div>
  </div>
</ng-template>

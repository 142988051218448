import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

export const ASSET_OBJECT = 1;
export const INVESTMENT_OBJECT = 2;
export const TASK_OBJECT = 3;
export const DOCUMENT_OBJECT = 4;
export const MONO_PERIMETER_OBJECT = 5;
export const AUDIT_OBJECT = 6;

@Injectable()
export class SuccessToastService {
  private successText: string = "";

  constructor(private translate: TranslateService, private toastCtrl: ToastController) {}

  private getObjectTypeLabel(objectType: number): string {
    if (objectType === ASSET_OBJECT) {
      return this.translate.instant("The asset element");
    }
    if (objectType === INVESTMENT_OBJECT) {
      return this.translate.instant("The investment element");
    }
    if (objectType === TASK_OBJECT) {
      return this.translate.instant("The task");
    }
    if (objectType === DOCUMENT_OBJECT) {
      return this.translate.instant("The document element");
    }
    if (objectType === MONO_PERIMETER_OBJECT) {
      return this.translate.instant("The perimeter");
    }
    return this.translate.instant("The element");
  }

  private getObjectCssClass(objectType: number): string {
    if (objectType === ASSET_OBJECT) {
      return "asset";
    }
    if (objectType === INVESTMENT_OBJECT) {
      return "investment";
    }
    if (objectType === TASK_OBJECT) {
      return "task";
    }
    if (objectType === DOCUMENT_OBJECT) {
      return "document";
    }
    if (objectType === MONO_PERIMETER_OBJECT) {
      return "mono-perimeter";
    }
    if (objectType === AUDIT_OBJECT) {
      return "audit";
    }
    return "";
  }

  public showObjectCreated(objectType: number): void {
    this.translate.get("has been added[f]").subscribe(text => {
      this.showSuccessToast(objectType, this.getObjectTypeLabel(objectType) + " " + text);
    });
  }

  public async showSuccessToast(objectType: number, successText, duration?: number): Promise<void> {
    let toast = await this.toastCtrl.create({
      message: successText,
      duration: duration || 2000,
      position: "bottom",
      cssClass: "successToast-" + this.getObjectCssClass(objectType),
    });
    await toast.present();
  }
}

import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChange,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  ActionSheetController,
  AlertController,
  IonInput,
  IonItemSliding,
  ModalController,
  NavController,
  NavParams,
} from "@ionic/angular";
import { forkJoin, Observer, Subscription } from "rxjs";

import { InvestmentFieldBase } from "../../../base-pages/investment-field-base.component";
import { CurrencyPipe } from "../../../pipes/currency/currency.pipe";
import { InvestmentsService } from "../../../services/investments.service";
import { ErrorsService } from "../../../services/errors.service";
import { OfflineService } from "../../../services/offline.service";
import { I18nService } from "../../../services/i18n.service";
import { InvestmentHistoryService, SlicesLastHistory } from "../../../services/investment-history.service";
import {
  getInvestmentSpendingSliceData,
  InvestmentSlice,
  InvestmentStatus,
  InvestmentSpendingSlice,
  Investment,
} from "../../../structs/investments";
import { ScopeService } from "../../../services/scope.service";
import { Events } from "src/app/services/events.service";
import { Asset } from "src/app/structs/assets";
import { Router } from "@angular/router";
import { SliceDetailComponent } from "./slice-detail/slice-detail.component";

export class FormattedPriceInvestmentSlice extends InvestmentSlice {
  public formattedPrice: string;
}

@Component({
  selector: "app-investment-budget-slices",
  templateUrl: "./investment-budget-slices.page.html",
  styleUrls: ["./investment-budget-slices.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InvestmentBudgetSlicesPage extends InvestmentFieldBase implements OnInit, OnChanges, OnDestroy {
  public currentSlices: FormattedPriceInvestmentSlice[] = [];
  public currentSpendingSlices: InvestmentSpendingSlice[] = [];
  public slicesLastHistory: SlicesLastHistory;
  public slicesHaveChanged: boolean = false;
  public spendingSlicesHaveChanged: boolean = false;
  public saveInProgress: boolean = false;
  public additionalBudgetEditingSlice: FormattedPriceInvestmentSlice;
  private pendingChangesTitle: string = "";
  private pendingChangesConfirmation: string = "";
  public pendingChanges: boolean = false;

  @Input() addMode: boolean = false;
  @Input() public investment: Investment;
  @Input() public asset: Asset = null;
  @Input() protected fromPPA: boolean = false;
  @Input() loading: boolean = false;
  @Input() protected updateObserver: Observer<boolean> = null;
  @Input() protected subscriptions: Subscription[] = [];
  @Input() childComponentMode: boolean = false;
  @ViewChildren(IonItemSliding) slidingItems: QueryList<IonItemSliding>;

  constructor(
    protected navCtrl: NavController,
    protected params: NavParams,
    protected investmentsApi: InvestmentsService,
    protected translate: TranslateService,
    protected errors: ErrorsService,
    protected offlineApi: OfflineService,
    protected alertCtrl: AlertController,
    protected i18n: I18nService,
    protected investmentHistoryService: InvestmentHistoryService,
    private currencyPipe: CurrencyPipe,
    private scope: ScopeService,
    public event: Events,
    private modalCtrl: ModalController,
    private actionSheetController: ActionSheetController,
    private router: Router
  ) {
    super(navCtrl, params, investmentsApi, translate, errors, offlineApi, alertCtrl);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.updateCurrentSlices(this.investment.status);
    this.updateCurrentSpendingSlices();

    if (this.investment.id) {
      this.subscriptions.push(
        this.investmentHistoryService.getSlicesLastHistory(this.investment.id).subscribe(slicesLastHistory => {
          this.slicesLastHistory = slicesLastHistory;
        })
      );
    }
    this.pendingChangesTitle = this.translate.instant("Confirmation");
    this.translate.get("Save changes?").subscribe(text => {
      this.pendingChangesConfirmation = text;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateCurrentSlices(this.investment.status);
    this.updateCurrentSpendingSlices();

    if (this.investment.id) {
      this.subscriptions.push(
        this.investmentHistoryService.getSlicesLastHistory(this.investment.id).subscribe(slicesLastHistory => {
          this.slicesLastHistory = slicesLastHistory;
        })
      );
    }
  }

  public async pendingChangesPrompt() {
    if (!this.pendingChanges) {
      this.dismiss();
    } else {
      // if we're adding an investment we assume we always save since the validate button is hidden
      if (this.addMode) {
        this.save();
        this.pendingChanges = false;
        this.dismiss();
      } else {
        const pendingChangesConfirm = await this.alertCtrl.create({
          cssClass: "alert-three-buttons",
          header: this.pendingChangesTitle,
          message: this.pendingChangesConfirmation,
          buttons: [
            {
              text: this.translate.instant("Cancel"),
              handler: () => {},
            },
            {
              text: this.translate.instant("No"),
              handler: () => {
                this.pendingChanges = false;
                this.dismiss();
              },
            },
            {
              text: this.translate.instant("Yes"),
              handler: () => {
                this.validateChanges();
              },
            },
          ],
        });
        await pendingChangesConfirm.present();
      }
    }
  }

  public validateChanges(): void {
    if (this.totalPriceValidation(this.currentSlices)) {
      if (this.slicesHaveChanged || this.spendingSlicesHaveChanged) {
        this.save();
        this.pendingChanges = false;
      }
    } else {
      this.alertCtrl
        .create({
          header: this.translate.instant("Invalid budget"),
          message: this.translate.instant("Budget error"),
          buttons: ["Ok"],
        })
        .then(alert => {
          alert.present();
        });
    }
  }

  public updateCurrentSlices(status: InvestmentStatus): void {
    let currentSlices = [];

    currentSlices = this.investment.slices.filter(slice => {
      if (slice.status.id === status.id) {
        return new FormattedPriceInvestmentSlice(
          slice && slice.id,
          slice.year,
          status,
          slice && slice.price,
          slice && slice.additionalPrice,
          slice && slice.addPriceReason,
          slice && slice.isExtraWork,
          slice && slice.comments
        );
      }
    });
    currentSlices.map(slice => (slice.formattedPrice = this.formatSlicePrice(slice)));

    this.currentSlices = currentSlices;
  }

  public updateCurrentSpendingSlices(): void {
    const startYear = this.getStartYear();
    // End at most at current year, because it doesn't make sense to show spending for the future
    const endYear = Math.min(new Date().getFullYear(), this.getEndYear());
    const currentSpendingSlices = [];

    for (let year = startYear; year <= endYear; year++) {
      const existingSpendingSlice = this.investment.spendingSlices.find(spendingSlice => spendingSlice.year === year);
      currentSpendingSlices.push(
        new InvestmentSpendingSlice(
          existingSpendingSlice && existingSpendingSlice.id,
          year,
          (existingSpendingSlice && existingSpendingSlice.atDate) || new Date().toISOString(),
          (existingSpendingSlice && existingSpendingSlice.spent) || null,
          (existingSpendingSlice && existingSpendingSlice.remaining) || null
        )
      );
    }
    this.currentSpendingSlices = currentSpendingSlices;
  }

  private getStartYear(): number {
    const currentYear = new Date().getFullYear();

    if (this.investment.finalSchedule) {
      return this.investment.finalSchedule;
    } else if (this.asset) {
      return Math.max(currentYear, this.asset.installationYear);
    }

    return currentYear;
  }

  private getEndYear(): number {
    return Math.max(this.getStartYear() + 20, this.investment.finalScheduleTo);
  }

  private formatSlicePrice(slice: InvestmentSlice): string {
    let formattedPrice: string;

    slice.price = this.i18n.setMaxValue(slice.price, 12);

    if (slice.additionalPrice) {
      const fullPrice = this.i18n.setMaxValue(slice.price + slice.additionalPrice, 12);
      formattedPrice = `${this.currencyPipe.transform(fullPrice)} (${this.currencyPipe.transform(
        slice.price
      )} + ${this.currencyPipe.transform(slice.additionalPrice)})`;
    } else {
      formattedPrice = this.currencyPipe.transform(slice.price);
    }

    return formattedPrice;
  }

  private displayExtraWorkAlert(year: number): void {
    forkJoin(
      this.translate.get("Extra work"),
      this.translate.get(
        "For {{year}} the budget at the site level is already validated. The new amount will be considered as extra work.",
        { year }
      ),
      this.translate.get("Ok")
    ).subscribe(async ([title, message, okLabel]) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message,
        backdropDismiss: false,
        buttons: [
          {
            text: okLabel,
          },
        ],
      });

      await alert.present();
    });
  }

  private askAdditionalPriceReason(slice: FormattedPriceInvestmentSlice): void {
    forkJoin(this.translate.get("What is the reason of this budget change?"), this.translate.get("Ok")).subscribe(
      async ([title, okLabel]) => {
        const alert = await this.alertCtrl.create({
          header: title,
          backdropDismiss: false,
          inputs: [
            {
              name: "addPriceReason",
              type: "text",
              value: slice.addPriceReason,
            },
          ],
          buttons: [
            {
              text: okLabel,
              handler: data => {
                slice.addPriceReason = data.addPriceReason;
              },
            },
          ],
        });

        await alert.present();
      }
    );
  }

  private save(): void {
    if (this.saveInProgress) {
      return;
    }
    this.saveInProgress = true;

    if (this.slicesHaveChanged) {
      // Keep the original slices
      const updatedSlices: InvestmentSlice[] = this.investment.slices;
      // Add/update with the current slices
      for (const slice of this.currentSlices) {
        const originalSliceIndex = updatedSlices.findIndex(updatedSlice => {
          return updatedSlice.year === slice.year && updatedSlice.status.id === slice.status.id;
        });
        if (originalSliceIndex > -1) {
          updatedSlices[originalSliceIndex] = slice;
        } else if (slice.price > 0) {
          updatedSlices.push(slice);
        }
      }

      this.investment.slices = updatedSlices.filter(slice => slice.price > 0);
      this.slicesHaveChanged = false;
    }

    // Make an API-compliant version
    const saveData = this.investmentsApi.getSlicesData(this.investment);
    if (this.spendingSlicesHaveChanged) {
      // Keep the original slices
      const updatedSpendingSlices: InvestmentSpendingSlice[] = this.investment.spendingSlices;

      // Add/update with the current slices
      for (const spendingSlice of this.currentSpendingSlices) {
        const originalSpendingSliceIndex = updatedSpendingSlices.findIndex(updatedSpendingSlice => {
          return updatedSpendingSlice.year === spendingSlice.year;
        });
        if (originalSpendingSliceIndex > -1) {
          updatedSpendingSlices[originalSpendingSliceIndex] = spendingSlice;
        } else if (spendingSlice.spent > 0 || spendingSlice.remaining > 0) {
          updatedSpendingSlices.push(spendingSlice);
        }
      }

      // Make an API-compliant version
      try {
        saveData.spending_slices = updatedSpendingSlices.map(getInvestmentSpendingSliceData);
        this.investment.spendingSlices = updatedSpendingSlices;
        this.spendingSlicesHaveChanged = false;
        this.event.publish("updateInvestment", this.investment);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }

    if (this.addMode) {
      this.saveInProgress = false;
    } else {
      this.subscriptions.push(
        this.saveField$(saveData, false).subscribe(
          () => {
            this.dismiss();
          },
          err => {
            this.errors.signalError(err);
          }
        )
      );
    }
  }

  public isSliceDisabled(slice: InvestmentSlice) {
    let disabled =
      (slice.status.isLocked && (!this.additionalBudgetEditingSlice || slice !== this.additionalBudgetEditingSlice)) ||
      this.investment.status.isValidated ||
      this.investment.status.isLocked;
    return disabled;
  }

  async openSliceYearChangeSheet(year: number) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        ...this.currentSlices
          .filter(slice => slice.year !== year)
          .map(slice => ({
            text: slice.year.toString(),
            handler: () => {
              const currentSlice = this.currentSlices.find(s => s.year === year);
              const nextSlice = this.currentSlices.find(s => s.year === slice.year);
              nextSlice.price += currentSlice.price;
              currentSlice.price = 0;
              nextSlice.formattedPrice = this.formatSlicePrice(nextSlice);
              currentSlice.formattedPrice = this.formatSlicePrice(currentSlice);
              this.slicesHaveChanged = true;
              this.pendingChanges = true;
            },
          })),
        {
          text: this.translate.instant("Cancel"),
          role: "cancel",
        },
      ],
    });
    await actionSheet.present();
    await actionSheet.onDidDismiss();
    this.slidingItems.forEach(item => item.close());
  }

  public dismiss() {
    this.modalCtrl.dismiss();
  }

  private totalPriceValidation(currentSlices: FormattedPriceInvestmentSlice[]): boolean {
    const totalPrice = currentSlices.map(slice => slice.price).reduce((total, price) => total + price, 0);
    return totalPrice > 0;
  }

  public async editSlice(sliceToEdit?: FormattedPriceInvestmentSlice) {
    let newSlice = null;
    if (!sliceToEdit) {
      // If no slice, we create a new slice and edit it in the slice-detail modal
      newSlice = new FormattedPriceInvestmentSlice(null, null, this.investment.status, null, 0, "", false, "");
    }
    const editSliceModal = await this.modalCtrl.create({
      component: SliceDetailComponent,
      componentProps: {
        slice: sliceToEdit ? sliceToEdit : newSlice,
        investment: this.investment,
        asset: this.asset,
      },
    });
    await editSliceModal.present();
    const { data } = await editSliceModal.onDidDismiss();
    if (data?.slice) {
      if (sliceToEdit) {
        this.updateCurrentSlices(this.investment.status);
        this.slicesHaveChanged = true;
        this.pendingChanges = true;
      } else {
        this.addNewSlice(data.slice);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  deleteSlice(item, sliceId: number) {
    item.close();
    const sliceIndex = this.investment.slices.findIndex(slice => slice.id === sliceId);
    if (sliceIndex !== -1) {
      this.investment.slices.splice(sliceIndex, 1);
    }
    this.updateCurrentSlices(this.investment.status);
    this.slicesHaveChanged = true;
    this.pendingChanges = true;
  }

  private addNewSlice(newSlice: FormattedPriceInvestmentSlice) {
    // If a slice already exists for the same year, we just add the price
    const sameYearIndex = this.investment.slices.findIndex(
      slice => slice.year === newSlice.year && slice.status.id === this.investment.status.id
    );
    if (sameYearIndex !== -1) {
      this.investment.slices[sameYearIndex].price += newSlice.price;
    } else {
      this.investment.slices.push(newSlice);
    }
    this.updateCurrentSlices(this.investment.status);
    this.slicesHaveChanged = true;
    this.pendingChanges = true;
  }
}

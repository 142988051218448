import { Pipe, PipeTransform } from "@angular/core";

import { AppUser } from "../../structs/base";

@Pipe({
  name: "username",
})
export class UsernamePipe implements PipeTransform {
  transform(user: AppUser, format?: boolean): string {
    let result = "";

    if (!user) {
      return result;
    }

    if (user.first_name) {
      format && user.last_name ? (result += `${user.first_name[0]}. `) : (result += `${user.first_name} `);
    }

    if (user.last_name) {
      result += `${user.last_name}`;
    }

    if (user.corporate_name) {
      if (user.first_name || user.last_name) {
        // if we have a user name, we put the corporate name in brackets
        result += ` (${user.corporate_name})`;
      } else {
        result += user.corporate_name;
      }
    }

    return result;
  }
}

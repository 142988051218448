import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-asset-type-selector",
  templateUrl: "./asset-type-selector.page.html",
  styleUrls: ["./asset-type-selector.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AssetTypeSelectorPage {
  @Input() public searchMode: boolean = false;
  assetAddMode: boolean;

  constructor(protected translate: TranslateService, private modalCtrl: ModalController) {}

  public close() {
    this.modalCtrl.dismiss();
  }
}

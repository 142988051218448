<ng-container *ngIf="!modalMode">
  <ion-list lines="full">
    <ion-item>
      <ion-label>{{ "Impact" | translate }}</ion-label>
      <ion-toggle [(ngModel)]="impactToggle" (ionChange)="changes()"></ion-toggle>
    </ion-item>
    <ng-container *ngIf="impactToggle">
      <ion-item *ngFor="let impact of impacts$ | async">
        <ion-checkbox
          slot="start"
          [checked]="isChecked(impact.id)"
          (ionChange)="checkImpact(impact.id)"
          mode="md"></ion-checkbox>
        <ion-label>{{ impact.name }}</ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ng-container>

<ng-container *ngIf="modalMode">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="backButtonClicked()">
          <i class="fa-solid fa-circle-chevron-left back-icon"></i>
        </ion-button>
      </ion-buttons>
      <ion-title>{{ "Investment impact" | translate }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list lines="full">
      <ion-item>
        <ion-label>{{ "Impact" | translate }}</ion-label>
        <ion-toggle [(ngModel)]="impactToggle" (ionChange)="changes()"></ion-toggle>
      </ion-item>
      <ng-container *ngIf="impactToggle">
        <ion-item *ngFor="let impact of impacts$ | async">
          <ion-checkbox
            slot="start"
            [checked]="isChecked(impact.id)"
            (ionChange)="checkImpact(impact.id)"
            mode="md"></ion-checkbox>
          <ion-label>{{ impact.name }}</ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  </ion-content>
</ng-container>

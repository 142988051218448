import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";

import { Perimeter } from "../structs/assets";
import { AnswerValue, ControlPoint, makeRoadmap, Roadmap } from "../structs/roadmap";
import { Change, ChangeAction, makeChange } from "@structs/synchronization";

import { BackendService } from "./backend.service";
import { OfflineService } from "./offline.service";
import { RoadmapSearchService } from "./roadmap-search.service";
import { SynchronizationService } from "./synchronization.service";

const roadmapKey: string = "roadmap:";

@Injectable()
export class RoadmapService {
  constructor(
    private backend: BackendService,
    private offlineApi: OfflineService,
    private roadmapSearchService: RoadmapSearchService,
    private syncApi: SynchronizationService
  ) {}

  private _loadRoadmap(perimeterId: number, observer: Observer<Roadmap>) {
    let fullKey = roadmapKey + perimeterId;
    this.offlineApi.getItem(fullKey, true).subscribe(
      (roadmapData: any) => {
        // cast storeData to RoadMap class
        let roadmap = Object.assign(new Roadmap(), roadmapData);
        this.roadmapSearchService.loadIndex(roadmap).subscribe();
        observer.next(roadmap);
        observer.complete();
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  private _storeRoadmap(perimeterId: number, roadmap: Roadmap, observer: Observer<Roadmap>) {
    let fullKey = roadmapKey + perimeterId;
    this.offlineApi.storeItem(fullKey, roadmap).subscribe(
      () => {
        observer.next(roadmap);
        observer.complete();
      },
      err => {
        observer.error(err);
        observer.complete();
      }
    );
  }

  /**
   * Get the roadmap for a given perimeter
   * @param perimeterId : multi-perimeter (site)
   * @param refresh: force to load from backend
   */
  getRoadmap(perimeterId: number, refresh: boolean = false): Observable<Roadmap> {
    return new Observable((observer: Observer<Roadmap>) => {
      if (refresh) {
        // Load from backend
        this.backend.get("/roadmap/api/roadmap/" + perimeterId + "/").subscribe(
          jsonData => {
            let roadmap: Roadmap = makeRoadmap(perimeterId, jsonData);
            this._storeRoadmap(perimeterId, roadmap, observer);
            this.roadmapSearchService.createIndex(roadmap).subscribe();
          },
          err => {
            // In case of error try to load from offline data
            this._loadRoadmap(perimeterId, observer);
          }
        );
      } else {
        // Load if from offline data
        this._loadRoadmap(perimeterId, observer);
      }
    });
  }

  /**
   * Store the roadmap for a given perimeter
   * @param perimeterId
   * @param roadmap: data to be stored
   */
  storeRoadmap(perimeterId: number, roadmap: Roadmap): Observable<Roadmap> {
    return new Observable((observer: Observer<Roadmap>) => {
      this._storeRoadmap(perimeterId, roadmap, observer);
    });
  }

  /**
   * Get the roadmap for a given perimeter
   * @param perimeter
   * @param answersData: array of answers
   */
  setAnswersValues(perimeter: Perimeter, answersData: Array<AnswerValue>): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let url = "/roadmap/api/set-answers/" + perimeter.id + "/";
      let change: Change = makeChange(
        ChangeAction.setRoadmapAnswersAction,
        url,
        "post",
        answersData,
        null,
        "",
        null,
        perimeter.localId
      );
      this.syncApi.addChange(change).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }

  /**
   * Save a control point
   *
   * @param controlPoint: ControlPoint
   */
  public saveControlPoint(controlPoint: ControlPoint, roadmapPerimeterId: number = null): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      // Get a local Id (offlineId)
      this.offlineApi.getNextOfflineId().subscribe((offlineNextId: number) => {
        if (controlPoint.id == null && !controlPoint.local_id) {
          controlPoint.local_id = offlineNextId;
        }

        let data = {
          ...controlPoint,
          roadmapPerimeterId: roadmapPerimeterId,
        };

        let url = "/roadmap/api/control-points/";
        let change: Change = makeChange(
          ChangeAction.saveControlPointAction,
          url,
          "post",
          data,
          null,
          "",
          null,
          controlPoint.perimeterLocalId
        );
        this.syncApi.addChange(change).subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          err => {
            observer.error(err);
            observer.complete();
          }
        );
      });
    });
  }

  public deleteControlPoint(controlPoint: ControlPoint): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      let url = "/roadmap/api/control-points/" + controlPoint.id + "/";
      this.syncApi.addChange(makeChange(ChangeAction.deleteControlPointAction, url, "delete", {})).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  public getDefaultPauseComment(): Observable<string> {
    return new Observable(observer => {
      this.offlineApi.getConfig("defaultPauseComment").subscribe(
        (jsonData: any) => {
          let data = jsonData ? jsonData : [];
          let defaultPauseComment = "";
          if (data && data.length) {
            defaultPauseComment = data[0].default_comment;
          }
          observer.next(defaultPauseComment);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
  }
}

import * as moment from "moment";
import { v4 as UUID } from "uuid";
import { Asset } from "./assets";
import { Investment } from "./investments";

export enum ChangeAction {
  addAssetAction = "addAsset",
  saveAssetAction = "saveAsset",
  deleteAssetAction = "deleteAsset",
  addAssetPictureAction = "addAssetPicture",
  deleteAssetPictureAction = "deleteAssetPicture",
  setAuditNoteAction = "setAuditNote",
  saveInvestmentAction = "saveInvestment",
  addInvestmentAction = "addInvestment",
  deleteInvestmentAction = "deleteInvestment",
  addInvestmentPictureAction = "addInvestmentPicture",
  deleteInvestmentPictureAction = "deleteInvestmentPicture",
  addPerimeterPictureAction = "addPerimeterPicture",
  deletePerimeterPictureAction = "deletePerimeterPicture",
  assetAccessAction = "assetAccess",
  setAuditNoteAfterInvestmentAction = "setAuditNoteAfterInvestment",
  setRoadmapAnswersAction = "setRoadmapAnswers",
  saveControlPointAction = "saveControlPoint",
  deleteControlPointAction = "deleteControlPointAction",
  addTaskAction = "addTask",
  saveTaskAction = "saveTask",
  deleteTaskAction = "deleteTask",
  saveDocumentDefaultsAction = "saveDocumentDefaults",
  addPostponementAction = "addPostponement",
  attachInvestmentAction = "attachInvestment",
  addMonoPerimeterAction = "addMonoPerimeter",
  saveMonoPerimeterAction = "saveMonoPerimeter",
  deleteMonoPerimeterAction = "deleteMonoPerimeter",
  saveMultiPerimeterAction = "saveMultiPerimeter",
  saveReferenceDataAction = "saveReferenceData",
  setAuditExpertModeAction = "setAuditExpertMode",
  addAuditQuestionPictureAction = "addAuditQuestionPictureAction",
  deleteAuditQuestionPictureAction = "deleteAuditQuestionPictureAction",
  updatePerimeterCoverImageAction = "updatePerimeterCoverImageAction",
  putAssetEnergyConsumption = "putAssetEnergyConsumption",
}

export interface IChange {
  timestamp: number;
  type: ChangeAction;
  url: string;
  method: "get" | "post" | "put" | "patch" | "delete";
  data: any;
  asset: Asset;
  assetOfflineId: number;
  localId: string;
  investment?: object; // basic object
  perimeterLocalId?: string;
  uuid: UUID;
}

export class Change implements IChange {
  constructor(
    public timestamp: number,
    public type: ChangeAction,
    public url: string,
    public method: "get" | "post" | "put" | "patch" | "delete",
    public data: any,
    public asset: Asset,
    public assetOfflineId: number,
    public localId: string,
    public investment: Investment = null,
    public perimeterLocalId: string = "",
    public uuid: UUID = null
  ) {}

  public static toLogObject(change: Change): object {
    if (!change.uuid) {
      throw TypeError("Change object without UUID");
    }
    return {
      timestamp: change.timestamp,
      type: change.type,
      url: change.url,
      method: change.method,
      data: change.data,
      assetOfflineId: change.assetOfflineId,
      localId: change.localId,
      uuid: change.uuid,
      ...(change.asset ? { asset: change.asset.id } : {}),
    };
  }
}

export function makeChange(
  type: ChangeAction,
  url: string,
  method: "get" | "post" | "put" | "patch" | "delete",
  data: any,
  asset: Asset | null = null,
  localId: string = "",
  investment: Investment = null,
  perimeterLocalId: string = ""
): Change {
  let timestamp: number = moment.now().valueOf(),
    uuid = UUID();
  return new Change(
    timestamp,
    type,
    url,
    method,
    data,
    asset,
    asset !== null ? asset.offlineId : 0,
    localId,
    investment,
    perimeterLocalId,
    uuid
  );
}

export enum SynchronizationStatus {
  STARTED = "STARTED", // synchronization in progress
  DONE = "DONE", // all changes have been synchronized
  POSTPONED = "POSTPONED", // Network error : synchronization is postponed
  CHANGE_TO_DO_ADDED = "CHANGE_TO_DO_ADDED", // synchronization added to the list
  CHANGE_DONE = "CHANGE_DONE", // Deleted after success
  CHANGE_DELETED = "CHANGE_DELETED", // Deleted after server error
  PUSH = "PUSH", // request a new push
  VERBOSE = "VERBOSE", // display sync event in toast
}

export class SynchronizationState {
  constructor(public status: SynchronizationStatus, public change: Change = null) {}
}

import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";
import CryptoJS from "crypto-js";

let __DEBUG_NUMBER_WRITE__ = 0;

// with each __DEBUG_WRITE_INFORM__ writes, we put a message into the console
const __DEBUG_WRITE_INFORM__ = 100;

@Injectable()
export class StorageService {
  private inMemoryStorage = new Map();
  private secret: string = "kpi$";

  constructor(private storage: Storage) {
    this.storage.create().then(() => console.warn("Using storage driver: ", this.storage.driver));
  }

  public set(key: string, value: any): Promise<any> {
    this.inMemoryStorage.set(key, value);
    let encryptedValue: string = this._encrypt(key, value);

    __DEBUG_NUMBER_WRITE__ += 1;
    if (__DEBUG_NUMBER_WRITE__ % __DEBUG_WRITE_INFORM__ === 0) {
      console.debug(`Database insertion checkpoint ${__DEBUG_NUMBER_WRITE__}`);
    }
    return this.storage.set(key, encryptedValue);
  }

  public ready() {
    // return this.storage.ready();
    console.log("TODO: storage.ready() deprecated ?");
  }

  public remove(key: string): Promise<any> {
    this.inMemoryStorage.delete(key);
    return this.storage.remove(key);
  }

  public get(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.inMemoryStorage.has(key)) {
        resolve(this.inMemoryStorage.get(key));
      } else {
        this.storage.get(key).then(
          value => {
            if (value) {
              try {
                let decryptedValue = this._decrypt(key, value);
                this.inMemoryStorage.set(key, decryptedValue);
                resolve(decryptedValue);
              } catch (exc) {
                reject(exc);
              }
            } else {
              resolve(value);
            }
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  public clear(): Promise<void> {
    this.inMemoryStorage.clear();
    return this.storage.clear();
  }

  public getAllDatabaseKeys(): Promise<string[]> {
    return this.storage.keys();
  }

  private _encrypt(key: string, value: string): string {
    return value;
    // return CryptoJS.AES.encrypt(value, this.secret + key).toString();
  }

  private _decrypt(key: string, value: string): string {
    return value;
    // let bytes = CryptoJS.AES.decrypt(value, this.secret + key);
    // return bytes.toString(CryptoJS.enc.Utf8);
  }
}

export class Cluster {
  constructor(public id: number, public name: string, public order: number) {}
}

/**
 * makeCluster : Cluster constructor
 * @param jsonData
 * @returns {Cluster}: created cluster
 */
export function makeCluster(jsonData: any): Cluster {
  return new Cluster(jsonData.id, jsonData.name, jsonData.order);
}

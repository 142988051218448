<ion-list lines="full">
  <div *ngIf="showMapping">
    <ion-searchbar
      (ionInput)="onSearchChanged($event)"
      (ionCancel)="onSearchCancelled()"
      placeholder="{{ 'Indicate a keyword' | translate }}"
      debounce="500"
      style="background: '#f7f7f7'; z-index: 1"></ion-searchbar>
    <ion-item
      *ngFor="let category of searchResults"
      (click)="selectMappedCategory(category)"
      [color]="category.id === investment.investmentCategory?.id ? 'investment' : null">
      <ion-label>{{ category.code }} - {{ category.name }}</ion-label>
    </ion-item>
  </div>

  <div *ngIf="!showMapping">
    <input-item
      *ngFor="let section of sections; index as i"
      [legend]="getSectionLabel(i)"
      [labelClass]="section.enabled ? 'color-primary' : 'color-unknown'">
      <ion-select
        (ionChange)="selectCategory($event, i)"
        okText="{{ 'OK' | translate }}"
        cancelText="{{ 'Cancel' | translate }}"
        interface="action-sheet"
        disabled="{{ !section.enabled }}"
        [value]="section.selectedCategory?.id">
        <ion-select-option *ngFor="let category of section.categories" [value]="category.id">
          <span *ngIf="category.code">{{ category.code }} -</span>
          {{ category.name }}
        </ion-select-option>
      </ion-select>
    </input-item>
  </div>
</ion-list>
